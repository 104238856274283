<ng-container *ngIf="!noData">
  <div class="flex" [ngClass]="{ reversed: reversed }" *ngFor="let stat of statsObsGroups.statistics">
    <div>{{ stat.keyName | translate }}</div>
    <div *ngIf="stat.values?.length" class="value">
      {{ stat.values[0].valueQuantity.value | number : "1.0-2" }}
      {{ stat.values[0].valueQuantity.unit ? stat.values[0].valueQuantity.unit : "" }}
    </div>
    <p *ngIf="!stat.values?.length">-</p>
  </div>
</ng-container>

<p *ngIf="noData">
  {{ "common.noData" | translate }}
</p>
