import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Contained, INPUT_TYPE, QuestionQuestionnaire, SPECIFIC_USE } from "../../../../models/questionnaire.interface";
import { EnableWhenBehavior, IEnableWhen } from "../../../../models/sharedInterfaces";
import { ChoiceInputComponent } from "./choice-input/choice-input.component";
import { DateInputComponent } from "./date-input/date-input.component";

@Component({
  selector: "app-input-properties",
  templateUrl: "./input-properties.component.html",
  styleUrls: ["./input-properties.component.scss"],
})
export class InputPropertiesComponent {
  @ViewChild(DateInputComponent)
  private dateInputComponent: DateInputComponent;
  @ViewChild(ChoiceInputComponent)
  private choiceInputComponent: ChoiceInputComponent;

  @Input() totalPages: number;
  @Input() lang: string;
  @Input() specificUse: SPECIFIC_USE;

  @Input() questions: QuestionQuestionnaire[];
  @Output() questionsChange = new EventEmitter<QuestionQuestionnaire[]>();

  @Input() pageNumber: number;
  @Output() pageNumberChange = new EventEmitter<number>();

  @Input() set currentQuestion(value: number) {
    this.questionIdx = value;
    this.setup();
  }

  @Input() set contained(value: Contained[]) {
    this.questionsOptions = JSON.parse(JSON.stringify(value));
    this.setup();
  }
  @Input() visualization = false;

  @Output() containedChange = new EventEmitter<Contained[]>();

  private questionIdx: number;
  question: QuestionQuestionnaire;
  questionsOptions: Contained[];

  label = "";
  page = 0;
  required = true;
  TYPES = INPUT_TYPE;

  // Conditions :
  enableWhenArray: IEnableWhen[] = [];
  operatorOptions = ["=", ">", "<", ">=", "<="];
  enableWhenBehavior: EnableWhenBehavior = EnableWhenBehavior.AND;
  enableWhenBehaviorOptions = [
    {
      display: this.translateService.instant("page.questionnaireEditor.properties.and"),
      value: EnableWhenBehavior.AND,
    },
    {
      display: this.translateService.instant("page.questionnaireEditor.properties.or"),
      value: EnableWhenBehavior.OR,
    },
  ];

  constructor(private translateService: TranslateService) {}

  /**
   * Setup all the variables according to the inputs
   */
  private setup() {
    if (this.questions && this.questions.length && this.questions.length > this.questionIdx) {
      this.question = JSON.parse(JSON.stringify(this.questions[this.questionIdx]));
    }
    if (this.pageNumber) {
      this.page = this.pageNumber;
    }
    if (!this.question) {
      return;
    }
    if (!this.question.answerDisplay) {
      this.createAnswerDisplayForOldQuestionnaire();
    }
    this.initEnableWhenArray();
    this.label = this.question.text;
    this.required = this.question.required;
  }

  /**
   * Old questionnaires do not have answerDisplay. So
   * we need to give them one.
   */
  private createAnswerDisplayForOldQuestionnaire() {
    this.question.answerDisplay = {
      type: this.question.type === INPUT_TYPE.CHOICE ? INPUT_TYPE.RADIO : INPUT_TYPE.TEXT,
      showMethod: 0,
      scaleReverseValue: false,
      displayIsShown: false,
    };
  }

  /**
   * Used to do a ngFor with range
   * @param i the range
   * @returns an empty array
   */
  public pagesNumbers(): unknown[] {
    return new Array(this.totalPages);
  }

  /**
   * Adds a new empty imageUrl object to the question
   */
  public addImage(): void {
    if (!this.question.imageUrls) {
      this.question.imageUrls = [];
    }
    this.question.imageUrls.push({ path: "", caption: "" });
  }

  /**
   * Removes the last imageUrl object of the question
   * @returns
   */
  public removeImage(): void {
    if (!this.question.imageUrls || this.question.imageUrls.length < 1) {
      return;
    }
    const last = this.question.imageUrls.length - 1;
    this.question.imageUrls.splice(last, 1);
  }

  // --------------------------------------------------------------------------
  // -----------------------  ENABLE WHEN -------------------------------------

  /**
   * Initialize the enableWhenArray with current conditions of the question
   * If there is no condition add an empty condition
   */
  private initEnableWhenArray() {
    this.enableWhenArray = [];
    if (this.question.enableWhen && this.question.enableWhen.length) {
      this.question.enableWhen.forEach((enableWhen) => {
        this.enableWhenArray.push({
          question: enableWhen.question,
          operator: enableWhen.operator,
          answer: enableWhen.answer,
          type: enableWhen.type,
        });
      });
      this.enableWhenBehavior = this.question.enableWhenBehavior ? this.question.enableWhenBehavior : EnableWhenBehavior.AND;
    } else {
      this.addEmptyEnableWhen();
    }
  }

  public addEmptyEnableWhen(): void {
    this.enableWhenArray.push({
      question: null,
      operator: null,
      answer: null,
      type: null,
    });
  }

  public deleteEnableWhen(i: number): void {
    this.enableWhenArray.splice(i, 1);
  }

  /**
   * Format the enableWhenArray by :
   *  Deleting empty conditions
   *  Adding operator with default value ('=') if the user didn't select it
   *  Adding the type of the condition
   */
  private formatEnableWhenArray() {
    const indexToDel = [];
    this.enableWhenArray.forEach((enableWhen, i) => {
      if (!enableWhen.question || !enableWhen.answer) {
        indexToDel.push(i);
      } else {
        if (!enableWhen.operator) {
          enableWhen.operator = "=";
        }
        if (isNaN(Number(enableWhen.answer))) {
          enableWhen.type = "string";
        } else {
          enableWhen.answer = Number(enableWhen.answer);
          enableWhen.type = "number";
        }
      }
    });
    indexToDel.sort((a, b) => b - a); // DESC sorting
    indexToDel.forEach((i) => {
      this.deleteEnableWhen(i);
    });
  }

  // --------------------------------------------------------------------------
  // -------------------------  SAVE CHANGES ----------------------------------

  /**
   * Apply the change to the question and send
   * them to the parent component.
   */
  public applyChanges(): void {
    this.formatEnableWhenArray();
    if (this.enableWhenArray?.length) {
      this.question.enableWhen = this.enableWhenArray;
      if (this.enableWhenArray.length > 1) {
        this.question.enableWhenBehavior = this.enableWhenBehavior;
      } else {
        this.question.enableWhenBehavior = EnableWhenBehavior.AND;
      }
    } else {
      this.question.enableWhen = undefined;
      this.question.enableWhenBehavior = undefined;
    }
    if (this.questionsOptions) {
      this.containedChange.emit(this.questionsOptions);
    }
    if (this.question && this.question.text) {
      this.question.text = this.label;
      this.question.required = this.required;
      // Sending question's changes to parent component:
      this.questions[this.questionIdx] = this.question;
      this.questionsChange.emit(this.questions);
    }
    if (this.pageNumber !== this.page) {
      // Sending page number's changes to parent component:
      this.pageNumberChange.emit(this.page);
    }
  }

  public isValid(): boolean {
    if (this.question.answerDisplay.type === this.TYPES.DATE && this.dateInputComponent) {
      return this.dateInputComponent.isValid();
    }
    if (
      this.question.answerDisplay.type === this.TYPES.RADIO &&
      (this.specificUse === SPECIFIC_USE.CONSENT || this.specificUse === SPECIFIC_USE.QUIZ)
    ) {
      return this.choiceInputComponent.isValid();
    }
    return true;
  }
}
