import { INPUT_TYPE } from "src/app/models/questionnaire.interface";

export interface IDragItem {
  type: INPUT_TYPE | string;
  logo: string;
  haveTooltip: boolean;
  tooltipPosition: string;
  tooltipContent: string;
  inTransfer?: boolean;
}

export const mobileItems: IDragItem[] = [
  {
    type: INPUT_TYPE.CHECKBOXES,
    logo: "check_box",
    haveTooltip: true,
    tooltipPosition: "",
    tooltipContent: "page.questionnaireEditor.multipleAnswers",
  },
  {
    type: INPUT_TYPE.RADIO,
    logo: "radio_button_checked",
    haveTooltip: true,
    tooltipPosition: "",
    tooltipContent: "page.questionnaireEditor.simpleAnswer",
  },
  {
    type: INPUT_TYPE.STRING,
    logo: "short_text",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.TEXT,
    logo: "subject",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.NUMBER,
    logo: "pin",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.DECIMAL,
    logo: "",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.RANGE,
    logo: "linear_scale",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.HAPPY_RANGE,
    logo: "mood",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  /*
  {
    type: INPUT_TYPE.DROPDOWN,
    logo: "arrow_drop_down",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  {
    type: INPUT_TYPE.RATING,
    logo: "star_outline",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.TIME,
    logo: "schedule",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },*/
  {
    type: INPUT_TYPE.DATE,
    logo: "calendar_today",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
];

export const dashboardOnlyItems: IDragItem[] = [
  {
    type: INPUT_TYPE.CHECKBOXES,
    logo: "check_box",
    haveTooltip: true,
    tooltipPosition: "",
    tooltipContent: "page.questionnaireEditor.multipleAnswers",
  },
  {
    type: INPUT_TYPE.RADIO,
    logo: "radio_button_checked",
    haveTooltip: true,
    tooltipPosition: "",
    tooltipContent: "page.questionnaireEditor.simpleAnswer",
  },
  {
    type: INPUT_TYPE.STRING,
    logo: "short_text",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.TEXT,
    logo: "subject",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.NUMBER,
    logo: "pin",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.DECIMAL,
    logo: "",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
  {
    type: INPUT_TYPE.TEXTFIELD_HTML,
    logo: "text_fields",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
];

export const quizItems: IDragItem[] = [
  {
    type: INPUT_TYPE.RADIO,
    logo: "radio_button_checked",
    haveTooltip: true,
    tooltipPosition: "",
    tooltipContent: "page.questionnaireEditor.simpleAnswer",
  },
  {
    type: INPUT_TYPE.STRING,
    logo: "short_text",
    haveTooltip: false,
    tooltipPosition: "",
    tooltipContent: "",
  },
];

export const consentItems: IDragItem[] = [
  {
    type: INPUT_TYPE.RADIO,
    logo: "radio_button_checked",
    haveTooltip: true,
    tooltipPosition: "",
    tooltipContent: "page.questionnaireEditor.simpleAnswer",
  },
];
