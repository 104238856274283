import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { RuleAlert } from "src/app/models/rule-alert.model";

export class AlertListDataSource extends MatTableDataSourceExtended<RuleAlert> {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  sortingDataAccessor = (data: RuleAlert, sortHeaderId: string) => {
    switch (sortHeaderId) {
      case "name":
        return data.subject?.reference?.toLowerCase();
      case "date":
        return moment(data.date()).unix();
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(activeAlert: RuleAlert[]) {
    this.data = activeAlert;
  }
}
