import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { ExportToCsv } from "export-to-csv";
import * as moment from "moment";
import { Subject, forkJoin } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { OBSERVATION_LOINC_MAP_CONVERTOR } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { HelpData } from "src/app/helpers/helpData";
import { ObservationHelper } from "src/app/helpers/observationHelper";
import { Tools } from "src/app/helpers/tools";
import { ChartSettings } from "src/app/models/chart.interface";
import { IPDFOptions } from "src/app/models/export.interface";
import {
  CsvRow,
  IObservationDefinition,
  OBSERVATION_VIEW,
  OComponent,
  ObservationDefinitionComponent,
  ObservationResume,
} from "src/app/models/observations.interface";
import { Observation } from "src/app/models/observations.model";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { ITranslation } from "src/app/models/translation.interface";
import { FindObsDefByLoincPipe } from "src/app/pipes/find-obs-def-by-loinc.pipe";
import { GetEffectiveTimingPipe } from "src/app/pipes/get-effective-timing.pipe";
import { LoincAndValueMeaningPipe } from "src/app/pipes/loinc-to-itranslation.pipe";
import { ObservationsService } from "src/app/providers/observations.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { ToolsService } from "src/app/providers/tools.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { Item } from "../item-selector/item-selector.component";
import { ObservationChartComponent } from "../observation-chart/observation-chart.component";
import { MomentObservationTableComponent } from "./moment-observation-table/moment-observation-table.component";
import { ObservationDefinitionListComponent } from "./observation-definition-list/observation-definition-list.component";
import { TranslateComponentPipe } from "./patient-observations-pipes/translate-component.pipe";

@Component({
  selector: "app-patient-observations",
  templateUrl: "./patient-observations.component.html",
  styleUrls: ["./patient-observations.component.scss"],
})
export class PatientObservationsComponent implements OnInit, OnDestroy {
  @ViewChild("momentObsTableCmpt") momentObsTableCmpt: MomentObservationTableComponent;

  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier && pu.user.caremateIdentifier !== this.pu?.user.caremateIdentifier) {
      this.availableObservations = [];
      this.pu = pu;
      this.initiatedData = false;
      this.initDataAndAutoRefresh(pu.user.caremateIdentifier, this.preferenceService.getAutoRefreshWidget());
    }
  }
  @ViewChild(ObservationChartComponent) observationChart: ObservationChartComponent;
  @ViewChild("focus") target: ElementRef;
  @ViewChild("statContainer") statContainer: ElementRef;

  public scrollAfterDataInit = false;
  public minToDate: moment.Moment = null;
  public maxFromDate: moment.Moment = null;
  public pu: PatientUser;
  public allObs: Observation[] = [];
  public isBig = false;
  public aggregateByDay = true;
  public activateNorms = false;
  public showMomentView = false;
  public availableObservations: ObservationResume[] = [];
  public sliderData: Item[] = [];
  public optData: Item[] = [
    {
      value: "axeYchangePosition",
      display: this.translateService.instant("graph.axeYchangePosition"),
      checked: false,
    },
    /* SEE CMATE-1301
        {
            value: 'bloodPressureShownMethod',
            display: this.translateService.instant('graph.bloodPressureShownMethod'),
            checked: false
        }*/
  ];
  public allDefinitions: IObservationDefinition[] = [];
  public currentDefinitions: IObservationDefinition[] = [];
  public headers: OComponent[] = [];
  public mergedObservations: Observation[];
  public chartAndExportObs: Observation[]; // Fixed version of the original Observations to make sure we can use it in charts, export and in alert history.
  public chartSettings: ChartSettings = {
    axeYchangePosition: false,
    // bloodPressureShownMethod: false
  };
  public filterFormGraph = this.fb.group({
    fromDate: ["", [Validators.required]],
    toDate: ["", [Validators.required]],
  });
  public filterFormTable = this.fb.group({
    fromDate: ["", [Validators.required]],
    toDate: ["", [Validators.required]],
  });

  public fromDate: moment.Moment;
  public toDate: moment.Moment;
  public filteredMergedObs: Observation[];
  public isNoneObservations = true;

  public options = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    headers: [],
    showTitle: false,
    title: "",
    useBom: true,
    removeNewLines: true,
    useKeysAsHeaders: false,
  };
  public loading = true;
  private initiatedData = false;
  private refreshInterval;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();
  public hasImages = false;

  OBSERVATION_VIEW = OBSERVATION_VIEW;
  activeView: OBSERVATION_VIEW = OBSERVATION_VIEW.HOURLY;

  constructor(
    private observationsService: ObservationsService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    private patientService: PatientService,
    private statService: UserStatisticsService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private sessionService: SessionService,
    public helpData: HelpData,
    private toolsService: ToolsService,
    private snackBar: MatSnackBar,
    private tileManager: TileManager,
    private loincAndValueMeaningPipe: LoincAndValueMeaningPipe,
    private getEffectiveTimingPipe: GetEffectiveTimingPipe,
    private datePipe: DatePipe,
    private findObsDefByLoincPipe: FindObsDefByLoincPipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fromDate = moment().subtract(3, "month");
    this.toDate = moment().endOf("day");
    this.maxFromDate = this.toDate;
    this.minToDate = this.fromDate;
    this.updateDateForm();
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // the idea is to make the pipes realize there's something that changed without
      // restarting everything. For that we just need to 'change' one of the
      // pipe variables.
      if (this.allDefinitions) {
        // Cloning will make it appear as if this is a new object
        this.allDefinitions = Tools.clone(this.allDefinitions);
      }
    });
    this.sessionService.refreshObservationsList.subscribe(() => {
      if (this.pu) {
        this.initData(this.pu.user.caremateIdentifier);
      }
    });
  }

  ngOnDestroy(): void {
    this.clearAutoRefresh();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private refreshObs(userId: string, fromDate?: string, toDate?: string): void {
    this.observationsService
      .list(userId, fromDate, toDate)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((obs) => {
        this.allObs = obs;
        this.updateDateForm();
        this.updateDefinitionDate();
      });
  }

  private initDataAndAutoRefresh(userId: string, _autoRefreshIntervalMs = 60000): void {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.initData(userId); }, autoRefreshIntervalMs);
    this.initData(userId);
  }

  private clearAutoRefresh(): void {
    clearInterval(this.refreshInterval);
  }

  private initData(userId: string): void {
    this.loading = true;
    this.initiatedData = true;
    const obsObservations = this.observationsService.list(
      userId,
      this.fromDate?.toISOString() ?? undefined,
      this.toDate?.toISOString() ?? undefined
    );
    const obsDef = this.observationsService.listDef(userId);

    forkJoin([obsObservations, obsDef])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([allObs, allDef]) => {
          this.allObs = allObs;
          this.allDefinitions = allDef;
          this.currentDefinitions = this.computeDefinitions(allDef);
          this.sliderData = this.allDefinitions.map((def) => ({
            value: def?.loinc,
            display: Tools.getTranslation(def?.nameTranslation, this.sessionService.userLang, def?.loinc),
            checked: true,
          }));
          // some items are in double
          this.sliderData = this.sliderData.filter((obj, index) => {
            return (
              index ===
              this.sliderData.findIndex((objSecond) => {
                return Tools.isEqual(obj, objSecond);
              })
            );
          });
          this.availableObservations = [];
          this.loadPreferences();
        },
        (err) => {
          FileLogger.error("PatientObservationsComponent", "Error while downloading observations: ", err);
          this.loading = false;
        }
      );
  }

  private loadData(): void {
    if (!this.availableObservations || this.availableObservations.length === 0) {
      this.computeAllObservations();
    }
    if (this.isBig) {
      if (this.activateNorms) {
        this.loadObsWithNorms();
      } else {
        this.computeMergedObservations();
        this.computeHeaders();
      }
    }
  }

  private computeAllObservations(): void {
    this.availableObservations = [];
    this.allObs.forEach((obs) =>
      obs.component.forEach((obsComponent) => {
        if (obsComponent?.code?.coding && obsComponent.code.coding.length) {
          // prepare all availableObservations
          this.availableObservations.push({
            // problem
            reference: obsComponent.code.coding[0].code,
            // we will need the parent's code to find the right definition:
            parentReference: obs.code.coding[0].code,
            display: obsComponent.code.coding[0].display,
            date: obs.issued,
            value: obsComponent.valueQuantity.value,
            unit: obsComponent.valueQuantity.unit,
            pictures: obsComponent.valuePictures,
            device: obs.device,
          });
        }
      })
    );
  }

  public changeFilter(event: Item[]): void {
    // copy as not reference and map to create observation object
    this.filteredMergedObs = Tools.clone(this.mergedObservations).map((o) => new Observation(o));
    this.sliderData = event;
    this.computeHeaders();
    this.currentDefinitions = this.computeDefinitions(this.allDefinitions).filter((def) => {
      return this.sliderData.find((sd) => ObservationHelper.ignoreSuffix(def?.loinc) === sd.value)?.checked;
    });
    // first filter (delete obs component not checked in checkbox)
    this.filteredMergedObs.forEach((obs) => {
      obs.component = obs.component.filter(
        (comp) =>
          this.sliderData.find(
            (sd) =>
              ObservationHelper.ignoreSuffix(comp.code.coding[0].code) === sd.value ||
              comp.parentObservation?.code.coding[0].code === sd.value
          )?.checked
      );
    });

    // second filter (clean obs where no component are present)
    this.filteredMergedObs = this.filteredMergedObs.filter((obs) => obs.component.length);
    this.refreshObs(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString());
  }

  private computeDefinitions(obsDef: IObservationDefinition[]) {
    const definitions: IObservationDefinition[] = [];
    const noDuplicatedObsObservations = this.allObs.reduce((listObs, observation) => {
      const exists = listObs.findIndex((o) => o.code?.coding[0].code === observation.code?.coding[0].code);
      if (exists < 0) {
        listObs.push(observation);
      }
      return listObs;
    }, [] as Observation[]);
    if (noDuplicatedObsObservations) {
      noDuplicatedObsObservations.forEach((observation: Observation) => {
        const definition = obsDef.find((def: IObservationDefinition) => observation.code?.coding[0]?.code === def.loinc);
        if (definition) {
          definitions.push(definition);
        }
      });
    }
    return definitions;
  }

  public computeHeaders(): void {
    if (!this.mergedObservations) {
      this.headers = [];
    }
    const noDuplicatedComponent = this.mergedObservations.reduce((headers, observation) => {
      observation.component.forEach((component) => {
        const exists = headers.findIndex(
          (c) =>
            c.code?.coding[0].code === component.code?.coding[0].code &&
            c.parentObservation?.code?.coding[0].code === component.parentObservation?.code?.coding[0].code
        );
        if (exists < 0) {
          headers.push(component);
        }
      });
      return headers;
    }, [] as OComponent[]);
    this.headers = noDuplicatedComponent.filter((o) => {
      if (this.sliderData.length <= 0) {
        return true;
      } else {
        // we need to compare to the parent loinc code (and not the one of the component) to find the right definition
        const definition = this.allDefinitions.find((def) => o.parentObservation?.code?.coding[0]?.code === def.loinc);

        if (definition) {
          return this.sliderData.find((sd) => sd.value === definition.loinc)?.checked;
        } else {
          return false;
        }
      }
    });
  }

  public computeMergedObservations(): void {
    this.mergedObservations = [];
    let charObs = [];

    // clone object to avoid working on a shallow copy
    this.allObs.forEach((obs) => {
      this.mergedObservations.push(new Observation(Tools.clone(obs)));
      charObs.push(new Observation(Tools.clone(obs)));
    });
    // Make sure to fix the array of obs to show in the exported csv and the chart.
    charObs = charObs
      .sort((a, b) => (moment(a.issued).isAfter(moment(b.issued)) ? -1 : 1))
      .filter((o) => moment(o.issued).isBetween(moment(this.fromDate), moment(this.toDate), "day", "[]"));
    this.chartAndExportObs = charObs;

    // copy effectiveTiming from parent observation to children components
    this.mergedObservations.forEach((observation: Observation) => {
      observation.component.forEach((component) => {
        component.effectiveTiming = observation.effectiveTiming;
      });
    });

    if (this.aggregateByDay && this.activeView === OBSERVATION_VIEW.DEFAULT) {
      this.mergedObservations = this.mergedObservations.reduce((mergedObs, observation, _i) => {
        const index = mergedObs.map((o) => moment(o.issued).isSame(moment(observation.issued), "day")).lastIndexOf(true);
        const existing = mergedObs[index];
        if (!existing) {
          // if the day of the observation is not yet in the table, create a new row (by pushing the observation)
          mergedObs.push(observation); // add row
        } else if (observation.component) {
          if (existing.component) {
            for (const comp of observation.component) {
              // Check if the specific observation has already been made and create a new row in the table if it has.
              // We need the parent code to properly identify a component!
              const sameComponent = existing.component.find(
                (c) =>
                  c.code.coding[0].code === comp.code.coding[0].code &&
                  c.parentObservation?.code.coding[0].code === comp.parentObservation?.code.coding[0].code
              );
              if (sameComponent) {
                mergedObs.push(observation); // add row
                break;
                // if not, just add the component to the existing row
              } else {
                existing.component.push(comp); // add column
              }
            }
          } else {
            existing.component = observation.component;
          }
        }
        return mergedObs;
      }, [] as Observation[]);
    }

    this.filteredMergedObs = this.mergedObservations
      .sort((a, b) => (moment(a.issued).isAfter(moment(b.issued)) ? -1 : 1))
      .filter((o) => moment(o.issued).isBetween(moment(this.fromDate), moment(this.toDate), "day", "[]"));
    if (this.sliderData && this.sliderData.length > 0) {
      // first filter (delete obs component not checked in checkbox)
      this.filteredMergedObs.forEach((obs) => {
        obs.component = obs.component.filter(
          (comp) =>
            this.sliderData.find(
              (sd) =>
                ObservationHelper.ignoreSuffix(comp.code.coding[0].code) === sd.value ||
                comp.parentObservation?.code.coding[0].code === sd.value
            )?.checked
        );
      });
      // second filter (clean obs where no component are present)
      this.filteredMergedObs = this.filteredMergedObs.filter((obs) => obs.component.length);
    }

    this.isNoneObservations = this.mergedObservations?.length === 0;
    this.updateHasImages();
  }

  /**
   * Preferences
   */
  public updatePreference(updateFocus: boolean): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_OBSERVATIONS_LIST,
        parameters: {
          isBig: this.isBig,
          settings: this.chartSettings,
          aggregateByDay: this.aggregateByDay,
          preferredView: this.activeView,
          activateNorms: this.activateNorms,
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isBig && updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.PATIENT_OBSERVATIONS_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (parameters: WidgetPatientParameter) => {
          if (parameters) {
            this.isBig = this.sessionService.globalPref?.keepLayoutFromPatientPage
              ? parameters.isBig
              : TileManager.isBig(PatientWidgetName.OBSERVATIONS);
            this.tileManager.updateList(PatientWidgetName.OBSERVATIONS, this.isBig);
            if (this.isBig) {
              this.statService.createStatEvent("Look at observations in big mode");
            }

            if (parameters.settings) {
              this.chartSettings = {
                axeYchangePosition: parameters.settings.axeYchangePosition,
                // bloodPressureShownMethod: parameters.settings.bloodPressureShownMethod SEE CMATE-1301
              };
            }
            this.aggregateByDay = parameters.aggregateByDay;
            this.activateNorms = parameters.activateNorms;

            if (parameters.preferredView) {
              this.activeView = parameters.preferredView;
            }

            this.loadData();
            this.loading = false;
          }
          this.loading = false;
          if (this.scrollAfterDataInit && this.isBig && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
            this.scroll();
          }
        },
        (err) => {
          FileLogger.error("PatientObservationsComponent", "Error while downloading observations preferences: ", err);
          this.loading = false;
        }
      );
  }

  public onExport(): void {
    if (this.showMomentView) {
      this.momentObsTableCmpt.onExport();
    } else {
      // build headers
      const csvHeaders: string[] = [];
      csvHeaders.push(this.translateService.instant("table.name"));
      csvHeaders.push(this.translateService.instant("table.birthdate"));
      csvHeaders.push(this.translateService.instant("table.internalId"));
      csvHeaders.push(this.translateService.instant("table.date"));
      for (const compo of this.headers) {
        if (compo?.code?.coding && compo.code.coding.length) {
          // needed to find the right definition (and meaning)
          csvHeaders.push(
            `${this.getTranslatedComponent(
              compo.code.coding[0].code,
              compo.parentObservation?.code.coding[0].code
            )} ${this.translateService.instant("table.value")}`
          );
          csvHeaders.push(`${this.translateService.instant("table.meaning")}`);
        }
      }
      this.options.title = `${this.patientService.getFullname(this.pu.patient)} - ${this.patientService.getBirthdate(
        this.pu.patient
      )} - ${this.patientService.getInternalId(this.pu.patient)}`;
      this.options.headers = csvHeaders;

      const obsToExport = this.chartAndExportObs;
      // We need to keep track of the parent loinc code
      // If we don't, we won't be able to find the correct definition later
      obsToExport.forEach((obs) => {
        obs.component.map((comp) => {
          comp.effectiveTiming = obs.effectiveTiming;
        });
      });

      // build content
      const csvData: CsvRow[] = [];
      const id = this.patientService.getInternalId(this.pu.patient);
      const birthDate = moment(this.patientService.getBirthdate(this.pu.patient), "DD/MM/YYYY").format("DD/MM/YYYY");
      for (const observation of obsToExport) {
        const csvRow: CsvRow = {
          name: this.patientService.getFullname(this.pu.patient),
          patientBirthDate: birthDate,
          internalId: id ? id : "-",
          date: moment(observation.issued).format("DD/MM/YYYY"),
        };
        let i = 0;
        let previousMeaning: string;
        for (let y = 0; y < this.headers.length * 3; y++) {
          const headerCompo = this.headers[i];
          if (!previousMeaning && headerCompo?.code?.coding && headerCompo.code.coding.length) {
            let value: number | string = observation.getValue(headerCompo.code.coding[0].code);
            value = value !== undefined && value !== null ? value : "-";
            // needed to find the right definition (and meaning)
            previousMeaning = this.loincAndValueMeaningPipe.transform(
              headerCompo.code.coding[0].code,
              headerCompo.parentObservation?.code.coding[0].code,
              value,
              this.allDefinitions,
              false,
              true
            );
            const timing: ITranslation = this.getEffectiveTimingPipe.transform(
              observation.effectiveTimingCode,
              this.findObsDefByLoincPipe.transform(this.allDefinitions, observation.code)
            );
            const previousTiming = Tools.getTranslation(timing, this.sessionService.userLang, "");
            if (previousTiming) {
              previousMeaning += " " + previousTiming;
            }
            csvRow["cell" + y] = value;
            i++;
          } else if (previousMeaning) {
            csvRow["cell" + y] = previousMeaning;
            previousMeaning = undefined;
          }
        }
        csvData.push(csvRow);
      }
      new ExportToCsv(this.options).generateCsv(csvData);
    }
    this.statService.createStatEvent("Export observations to CSV");
  }

  private getTranslatedComponent(code: string, parentCode: string): string {
    const cDef = this.getComponent(code, parentCode);
    if (!cDef) {
      return code;
    } // not exists

    if (cDef.shortnameTranslation) {
      return Tools.getTranslation(cDef.shortnameTranslation, this.sessionService.userLang, cDef.loinc);
    }

    return Tools.getTranslation(cDef.nameTranslation, this.sessionService.userLang, cDef.loinc);
  }

  /**
   * return Observation Definition Component based on a loinc code
   */
  private getComponent(loinc: string | number, parentCode: string): ObservationDefinitionComponent {
    const loincStr: string = OBSERVATION_LOINC_MAP_CONVERTOR[loinc] || loinc;
    const obsDef = this.allDefinitions.find((d) => d.loinc === parentCode);
    if (obsDef) {
      return obsDef.components.find((comp) => comp.loinc === loincStr);
    }
    return null;
  }

  public onPlus(): void {
    this.isBig = !this.isBig;
    // if no data and go small retore fromDate
    if (!this.isBig && this.isNoneObservations) {
      this.dateChangeFrom(moment().subtract(3, "month"));
    }
    if (this.isBig) {
      this.statService.createStatEvent("Look at observations in big mode");
    }
    this.tileManager.updateList(PatientWidgetName.OBSERVATIONS, this.isBig);
    this.updatePreference(true);
    this.loadData();
    this.scroll();
  }

  public updateLastFocus(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.OBSERVATIONS,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  /**
   * TODO : when we move mouse after click, scrollIntoView seems to not work
   */
  private scroll(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }

  public dateChangeFrom(value: moment.Moment): void {
    // only request api if new from date is before previous cause we already have data
    this.minToDate = this.filterFormGraph.get("fromDate").value;
    if (value.isBefore(this.fromDate)) {
      this.fromDate = value;
      this.refreshObs(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString());
    } else {
      this.fromDate = value;
      this.updateDateForm();
      this.updateDefinitionDate();
    }
    this.cdr.detectChanges();
  }

  public dateChangeTo(value: moment.Moment): void {
    this.maxFromDate = this.filterFormGraph.get("toDate").value;
    this.toDate = value;
    this.updateDateForm();
    this.updateDefinitionDate();
    this.cdr.detectChanges();
  }

  private updateDefinitionDate(): void {
    this.loadData();
    if (this.sliderData && this.sliderData.length > 0) {
      this.currentDefinitions = this.computeDefinitions(this.allDefinitions).filter((def) => {
        return this.sliderData.find((sd) => def?.loinc === sd.value)?.checked;
      });
    } else {
      this.currentDefinitions = this.computeDefinitions(this.allDefinitions);
    }
    this.updateHasImages();
  }

  private updateDateForm(): void {
    this.filterFormGraph.get("fromDate").setValue(this.fromDate);
    this.filterFormGraph.get("toDate").setValue(this.toDate);
    this.filterFormTable.get("fromDate").setValue(this.fromDate);
    this.filterFormTable.get("toDate").setValue(this.toDate);
  }

  public changeGraphSettings($event: Item[]): void {
    this.chartSettings = {
      axeYchangePosition: $event.find((i) => i.value === "axeYchangePosition")?.checked,
      // bloodPressureShownMethod: $event.find(i => i.value === 'bloodPressureShownMethod')?.checked
    };
    this.updatePreference(false);
    this.updateHasImages();
  }

  public openObservationHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientObservationsHelp },
      disableClose: true,
    });
  }

  public onExportPDF(): void {
    if (this.observationChart) {
      const title = this.translateService.instant("export.observation");
      const content = '<img style="max-width: 98%;" src="' + this.observationChart.getImage() + '"/>';

      this.toolsService.createPFDAndOpenInBrowser(this.pu, title, content);
      this.snackBar.open(this.translateService.instant("export.message"), "ok", { duration: 3000 });
      this.statService.createStatEvent("Export observations to PDF");
    }
  }
  public exportStats(): void {
    const pdfOptions: IPDFOptions = { margin: { top: 28, right: 28, bottom: 28, left: 28 } };
    const style = `
    mat-card * {
      font-size: 12px !important;
      color : black !important;
    }
    .mat-row, mat-header-row{
      min-height : 20px;
      border-color: lightgray;
    }
    `;

    this.toolsService.convertHtmlToPFDAndOpenInBrowser(
      this.pu,
      `${this.translateService.instant("stat.exportTitle")} : ${this.datePipe.transform(
        this.fromDate.toISOString(),
        "shortDate"
      )} - ${this.datePipe.transform(this.toDate.toISOString(), "shortDate")}`,
      this.statContainer.nativeElement.innerHTML,
      style,
      pdfOptions
    );
  }

  public toggleAgregateByDay(): void {
    this.aggregateByDay = !this.aggregateByDay;
    this.computeMergedObservations();
    this.updatePreference(false);
  }

  public toggleActivateNorms(): void {
    this.activateNorms = !this.activateNorms;
    this.loadObsWithNorms();
    this.updatePreference(false);
  }

  private loadObsWithNorms() {
    this.observationsService
      .list(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString(), this.activateNorms)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((obs) => {
        this.allObs = obs;
        this.computeMergedObservations();
        this.computeHeaders();
      });
  }

  private getObsName(component: OComponent): string {
    const translateComponent = new TranslateComponentPipe(this.sessionService);
    return translateComponent.transform(component, this.allDefinitions);
  }

  private getFormatedPicturesTitle(obsIndex: number, compIndex: number) {
    return (
      this.getObsName(this.filteredMergedObs[obsIndex].component[compIndex]) +
      " - " +
      moment(this.filteredMergedObs[obsIndex].issued).format("DD/MM/YYYY") +
      " - " +
      this.filteredMergedObs[obsIndex].component[compIndex].valueQuantity.value +
      " " +
      (this.filteredMergedObs[obsIndex].component[compIndex].valueQuantity.unit
        ? this.filteredMergedObs[obsIndex].component[compIndex].valueQuantity.unit
        : "")
    );
  }

  private updateHasImages() {
    this.hasImages = false;
    this.filteredMergedObs?.forEach((obs) => {
      obs.component?.forEach((comp) => {
        if (comp.valuePictures?.length > 0) {
          this.hasImages = true;
        }
      });
    });
  }

  public onExportImage(): void {
    const title = this.translateService.instant("export.observationImage");
    const obsToShow: {
      pictures: string[];
      title: string;
    }[] = [];
    this.filteredMergedObs?.forEach((obs, iObs) => {
      obs.component?.forEach((comp, iComp) => {
        if (comp.valuePictures?.length > 0) {
          const pictures = [];
          comp.valuePictures.forEach((img) => {
            pictures.push(img);
          });
          obsToShow.push({
            pictures,
            title: this.getFormatedPicturesTitle(iObs, iComp),
          });
        }
      });
    });
    let content = "<div>";
    obsToShow.forEach((obs, i) => {
      // We use the css properties page-break-before / page-break-after to show one observation per page
      // we don't need it for the first page and the last page
      // we also want a break when there are more than 2 images in the first page otherwise it doesn't fit with the header of the pdf
      if ((i === 0 && obs.pictures.length < 3) || i === obsToShow.length - 1) {
        content += '<div style="width: 100%; text-align: center"><h3>' + obs.title + "</h3>";
      } else {
        content +=
          '<div style="page-break-before: always; page-break-after: always; width: 100%; text-align: center"><h3>' + obs.title + "</h3>";
      }
      obs.pictures.forEach((img) => {
        content += '<img style="max-width: 45%; max-height: 45%; margin: 1%; "src="' + img + '" />';
      });
      content += "</div>";
    });
    content += "</div>";

    this.toolsService.createPFDAndOpenInBrowser(this.pu, title, content);
    this.snackBar.open(this.translateService.instant("export.message"), "ok", { duration: 3000 });
  }

  public onAddObservations(): void {
    this.dialog.open(ObservationDefinitionListComponent, {
      data: {
        patientId: this.pu.user.caremateIdentifier,
        language: this.sessionService.userLang,
      },
      disableClose: true,
    });
  }
}
