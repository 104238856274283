<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modaleDrugAdd">
  <div class="loading" *ngIf="isLoading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
    {{ "common.loading" | translate }}
  </div>
  <div class="drugAdd">
    <div [ngStyle]="{ visibility: !isLoading ? 'visible' : 'hidden' }">
      <h2 *ngIf="isCreation">{{ "mydrugs.add" | translate }}</h2>
      <h2 *ngIf="!isCreation">{{ "mydrugs.update" | translate }}</h2>
      <form [formGroup]="drugForm">
        <div class="editToggle">
          <mat-slide-toggle formControlName="allowUserEdit">
            <span
              [ngStyle]="{
                color: drugForm.get('allowUserEdit').value ? 'red' : 'gray'
              }"
            >
              {{ "mydrugs.allowUserEdit" | translate }}
            </span>
          </mat-slide-toggle>
        </div>
        <app-drug-server-side-search
          [isCreation]="isCreation"
          [formCtrl]="drugForm.get('name')"
          (drugChanged)="drugChanged($event)"
          class="full-w"
          *ngIf="drug.parentType !== PARENT_TYPE.ACTIVITY_DRUG"
        >
        </app-drug-server-side-search>
        <div *ngIf="!isCreation" class="mt-5">
          <mat-label>{{ "mydrugs.name" | translate }} :</mat-label>
          <p>{{ drugName }}</p>
        </div>
        <mat-form-field class="full-w mt-10">
          <mat-label>{{ "mydrugs.performer" | translate }}</mat-label>
          <mat-select formControlName="prescriptor">
            <mat-option *ngFor="let p of availablePrescriptors" [value]="p">
              {{ p.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-timing-editor
          [mode]="data.mode"
          [(frequency)]="drug.entityData.frequency"
          [isMomentRequired]="hasStepwise ? false : true"
          [largeDisplay]="false"
          [hasCycle]="hasCycle"
          [hasStepwise]="hasStepwise"
          [isFrequencyDisplayed]="true"
        >
        </app-timing-editor>
        <app-drug-cycle
          [mode]="data.mode"
          [(drugCycle)]="drug.entityData.cycle"
          [(stepwiseDrug)]="drug.entityData.stepwiseSchema"
          (drugCycleChange)="cycleChanged($event)"
          (stepwiseDrugChange)="stepwiseDrugChanged($event)"
          [(drugName)]="drug.entityData.name"
          *ngIf="isCreation || drug.entityData.cycle || drug.entityData.stepwiseSchema"
        >
        </app-drug-cycle>
        <app-drug-comment [mode]="data.mode" [(drugComment)]="drug.entityData.comment"> </app-drug-comment>
      </form>
    </div>
    <div class="action-container">
      <button mat-raised-button color="primary" *ngIf="isCreation" (click)="save(false)">
        {{ "btn.saveAndNew" | translate }}
      </button>
      <button mat-raised-button color="primary" *ngIf="isCreation" (click)="save(true)">
        {{ "common.saveAndQuit" | translate }}
      </button>
      <button mat-raised-button color="primary" *ngIf="!isCreation" (click)="save(true)">
        {{ "btn.update" | translate }}
      </button>
    </div>
  </div>
</div>
