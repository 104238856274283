<div class="questionnaireDroppedInput" cdkDrag *ngIf="question">
  <div class="editBtnGroup" *ngIf="!visualization">
    <button mat-stroked-button class="editButton" matTooltip="{{ 'page.questionnaireEditor.edit' | translate }}" (click)="editQuestion()">
      <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.editIcon' | translate }}"> edit </mat-icon>
    </button>
    <button
      mat-stroked-button
      matTooltip="{{ 'page.questionnaireEditor.copy' | translate }}"
      class="editButton"
      (click)="duplicateQuestion()"
    >
      <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.copyIcon' | translate }}"> file_copy </mat-icon>
    </button>
    <button
      mat-stroked-button
      [matTooltip]="
        ((question | getCanDeleteQuestion : scorings) ? 'common.delete' : 'page.questionnaireEditor.deleteDisabledScoring') | translate
      "
      class="editButton rightMostBtn"
      (click)="deleteQuestion()"
      [disabled]="!(question | getCanDeleteQuestion : scorings)"
    >
      <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.deleteIcon' | translate }}"> delete </mat-icon>
    </button>
  </div>
  <div class="editBtnGroup" *ngIf="visualization">
    <button mat-stroked-button class="editButton" (click)="editQuestion()">
      <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.showIcon' | translate }}"> remove_red_eye </mat-icon>
    </button>
  </div>
  <h4>{{ question.text }}</h4>
  <div [ngSwitch]="question.answerDisplay?.type || question.type || 'radio'">
    <div *ngSwitchCase="'range'" class="scale">
      <div *ngIf="question.answerDisplay?.displayIsShown">
        <h5 *ngIf="question.answerDisplay?.displayIsShown && sliderValue > -1 && sliderValue < questionOptions.length">
          {{ questionOptions[sliderValue].display }}
        </h5>
      </div>

      <div>
        <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod < 0"> sentiment_very_satisfied </mat-icon>
        <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod > 0"> mood_bad </mat-icon>
        <span *ngIf="question.answerDisplay?.showMethod === 0 && !question?.answerDisplay.scaleReverseValue">
          {{ question?.answerDisplay.min }}
        </span>
        <span *ngIf="question.answerDisplay?.showMethod === 0 && question?.answerDisplay.scaleReverseValue">
          {{ question?.answerDisplay.max }}
        </span>
        <mat-slider
          min="{{ question?.answerDisplay?.min }}"
          max="{{ question?.answerDisplay?.max }}"
          step="{{ question?.answerDisplay?.step }}"
          value="{{ question?.answerDisplay?.default }}"
          [(ngModel)]="sliderValue"
          invert="{{ question?.answerDisplay.scaleReverseValue }}"
          thumbLabel
          tickInterval="{{ question?.answerDisplay?.step }}"
        >
        </mat-slider>
        <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod < 0"> mood_bad </mat-icon>
        <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod > 0"> sentiment_very_satisfied </mat-icon>
        <span *ngIf="question.answerDisplay?.showMethod === 0 && question?.answerDisplay.scaleReverseValue">
          {{ question?.answerDisplay.min }}
        </span>
        <span *ngIf="question.answerDisplay?.showMethod === 0 && !question?.answerDisplay.scaleReverseValue">
          {{ question?.answerDisplay.max }}
        </span>
        <div *ngIf="question.answerDisplay?.displayIsShown" class="other-options-container">
          <h5 class="other-options-title">{{ "page.questionnaireEditor.choiceOptionsTitle" | translate }} :</h5>
          <h5 class="other-option" *ngFor="let choice of questionOptions">'{{ choice.display }}'</h5>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'dropdown'">
      <mat-form-field *ngIf="questionOptions.length > 0">
        <select matNativeControl>
          <option *ngFor="let qOption of questionOptions">
            {{ qOption.display }}
          </option>
        </select>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'checkboxes'">
      <div *ngIf="questionOptions.length > 0">
        <div *ngFor="let qOption of questionOptions">
          <mat-checkbox>
            {{ qOption.display }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'radio'">
      <mat-radio-group *ngIf="questionOptions.length > 0" class="radio">
        <mat-radio-button
          *ngFor="let qOption of questionOptions"
          value="{{ qOption.code }}"
          [ngClass]="{
            '': !specificUse,
            red:
              (specificUse === SPECIFIC_USE.CONSENT || specificUse === SPECIFIC_USE.QUIZ) &&
              !(qOption.code | isGoodAnswer : question.answerDisplay.goodAnswers),
            green:
              (specificUse === SPECIFIC_USE.CONSENT || specificUse === SPECIFIC_USE.QUIZ) &&
              (qOption.code | isGoodAnswer : question.answerDisplay.goodAnswers)
          }"
        >
          {{ qOption.display }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngSwitchCase="'choice'">
      <mat-radio-group *ngIf="questionOptions.length > 0" class="radio">
        <mat-radio-button *ngFor="let qOption of questionOptions" value="{{ qOption.code }}">
          {{ qOption.display }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngSwitchCase="'string'">
      <mat-form-field class="text">
        <input matInput [placeholder]="question.answerDisplay?.placeholder" [attr.maxLength]="question.answerDisplay?.max" />
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'text'">
      <mat-form-field class="text">
        <textarea matInput [placeholder]="question.answerDisplay?.placeholder" [attr.maxLength]="question.answerDisplay?.max"></textarea>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'textfield'">
      <mat-form-field class="text">
        <textarea matInput [placeholder]="question.answerDisplay?.placeholder" [attr.maxLength]="question.answerDisplay?.max"></textarea>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'number'">
      <div *ngIf="question.answerDisplay?.multiInputs">
        <div *ngFor="let qOption of questionOptions; let i = index" class="multiInputs">
          <mat-form-field>
            <input
              matInput
              type="number"
              [placeholder]="question.answerDisplay?.placeholder"
              [(ngModel)]="value[i]"
              [attr.min]="question.answerDisplay?.min"
              [attr.max]="question.answerDisplay?.max"
              step="1"
            />
          </mat-form-field>
          {{ qOption.display }}
        </div>
      </div>
      <div *ngIf="!question.answerDisplay?.multiInputs">
        <mat-form-field>
          <input
            matInput
            type="number"
            [placeholder]="question.answerDisplay?.placeholder"
            [(ngModel)]="value[0]"
            [attr.min]="question.answerDisplay?.min"
            [attr.max]="question.answerDisplay?.max"
            step="1"
          />
        </mat-form-field>
        <!-- Units: -->
        <mat-form-field *ngIf="question.options?.reference">
          <select matNativeControl>
            <option *ngFor="let qOption of questionOptions">
              {{ qOption.display }}
            </option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="'decimal'">
      <div *ngIf="question.answerDisplay?.multiInputs">
        <div *ngFor="let qOption of questionOptions; let i = index" class="multiInputs">
          <mat-form-field>
            <input
              matInput
              type="number"
              [placeholder]="question.answerDisplay?.placeholder"
              [(ngModel)]="value[i]"
              [attr.min]="question.answerDisplay?.min"
              [attr.max]="question.answerDisplay?.max"
              step="0.01"
            />
          </mat-form-field>
          {{ qOption.display }}
        </div>
      </div>
      <div *ngIf="!question.answerDisplay?.multiInputs">
        <mat-form-field>
          <input
            matInput
            type="number"
            [placeholder]="question.answerDisplay?.placeholder"
            [(ngModel)]="value[0]"
            [attr.min]="question.answerDisplay?.min"
            [attr.max]="question.answerDisplay?.max"
            step="0.01"
          />
        </mat-form-field>
        <!-- Units: -->
        <mat-form-field *ngIf="question.options?.reference">
          <select matNativeControl>
            <option *ngFor="let qOption of questionOptions">
              {{ qOption.display }}
            </option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="'rating'">
      <button mat-icon-button *ngFor="let star of starCount">
        <mat-icon>{{ "star_border" }}</mat-icon>
      </button>
    </div>
    <div *ngSwitchCase="'date'">
      <mat-form-field>
        <mat-label>
          {{
            question.answerDisplay.placeholder
              ? question.answerDisplay.placeholder
              : ("page.questionnaireEditor.inputsTypes.date" | translate)
          }}
        </mat-label>
        <input matInput [(ngModel)]="initialDate" [matDatepicker]="dateFromPicker" [min]="minDate" [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'time'">
      <mat-form-field>
        <input matInput class="pointer" [ngxTimepicker]="timePickerRange" [format]="24" [min]="minTime" [max]="maxTime" readonly />
        <ngx-material-timepicker #timePickerRange></ngx-material-timepicker>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'textfield-html'">
      <jodit-editor [config]="joditConfig"></jodit-editor>
    </div>
    <div *ngSwitchDefault>Component vide.</div>
  </div>
  <mat-icon class="material-icons-outlined drag-icon" cdkDragHandle>drag_indicator</mat-icon>
</div>
