<form [formGroup]="schemaForm">
  <!-- Drug schema - cycle -->
  <div class="cycleContainer">
    <div class="container">
      <mat-label>
        {{ "page.drugSchema.title" | translate }} :
        <mat-button-toggle-group
          formControlName="isCycleSelected"
          (ngModelChange)="resetForm()"
          [disabled]="!isCreation"
          style="height: 25px; align-items: center; justify-content: center"
        >
          <mat-button-toggle [value]="null" style="display: flex">{{ "drugSchema.none" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="true" style="display: flex">{{ "drugSchema.title" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="false" style="display: flex">{{ "drugSchema.stepwise.title" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-label>
      <div style="display: flex; justify-content: center; align-items: center" *ngIf="schemaForm.get('isCycleSelected').value === true">
        <mat-form-field *ngIf="isCreation || (!isCreation && !drugCycle)">
          <mat-select formControlName="cycle" (selectionChange)="findSchema($event)">
            <mat-option value="none">- {{ "drugSchema.none" | translate }} -</mat-option>
            <mat-option *ngFor="let cycle of cycles" [value]="cycle.name">
              {{ cycle.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p *ngIf="!isCreation && drugCycle">{{ cycle.name }}</p>
        <button
          mat-raised-button
          matTooltip="{{ 'drugSchema.btnCreation' | translate }}"
          *ngIf="(isCreation && schemaForm.get('cycle').value === 'none') || (!isCreation && schemaForm.get('cycle').value === 'none')"
          (click)="addSchema()"
        >
          <mat-icon color="primary">add</mat-icon>
        </button>
        <button
          mat-raised-button
          matTooltip="{{ 'drugSchema.btnEdition' | translate }}"
          *ngIf="
            (schemaForm.get('cycle').value !== 'none' && isCreation) ||
            (!isCreation && schemaForm.get('cycle').value !== 'none' && !drugCycle)
          "
          (click)="updateSchema()"
        >
          <mat-icon color="primary">edit</mat-icon>
        </button>
        <div>
          <button
            mat-raised-button
            matTooltip="{{ 'drugSchema.pause' | translate }}"
            *ngIf="!isCreation && schemaForm.get('cycle').value && !isPaused && drugCycle"
            (click)="OnPauseCycle()"
          >
            <mat-icon color="warn">pause</mat-icon>
          </button>
          <button mat-raised-button *ngIf="!isCreation && schemaForm.get('cycle').value && isPaused" [disabled]="true">
            <mat-icon color="warn">play_arrow</mat-icon>
          </button>
          <span class="pauseInfo" *ngIf="isPaused">
            {{ "drugSchema.pauseInfo" | translate }}
            <br />
            {{ pauseDate.getDate() + "-" + (pauseDate.getMonth() + 1) + "-" + pauseDate.getFullYear() }}
          </span>
        </div>
      </div>
      <div style="display: flex; justify-content: center; align-items: center" *ngIf="schemaForm.get('isCycleSelected').value === false">
        <mat-form-field *ngIf="isCreation || (!isCreation && !stepwiseDrug)">
          <mat-select formControlName="stepwise" (selectionChange)="findSchema($event)">
            <mat-option value="none">- {{ "drugSchema.none" | translate }} -</mat-option>
            <mat-option *ngFor="let stepwise of stepwises" [value]="stepwise.name">
              {{ stepwise.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p *ngIf="!isCreation && stepwiseDrug">{{ stepwise.name }}</p>
        <button
          mat-raised-button
          [matTooltip]="
            schemaForm.get('isCycleSelected').value === true
              ? ('drugSchema.btnCreation' | translate)
              : ('drugSchema.stepwise.btnCreation' | translate)
          "
          *ngIf="
            (isCreation && schemaForm.get('stepwise').value === 'none') || (!isCreation && schemaForm.get('stepwise').value === 'none')
          "
          (click)="addSchema()"
        >
          <mat-icon color="primary">add</mat-icon>
        </button>
        <button
          mat-raised-button
          [matTooltip]="
            schemaForm.get('isCycleSelected').value === true
              ? ('drugSchema.btnEdition' | translate)
              : ('drugSchema.stepwise.btnEdition' | translate)
          "
          *ngIf="
            (schemaForm.get('stepwise').value !== 'none' && isCreation) ||
            (!isCreation && schemaForm.get('stepwise').value !== 'none' && !stepwiseDrug)
          "
          (click)="updateSchema()"
        >
          <mat-icon color="primary">edit</mat-icon>
        </button>
      </div>
    </div>
    <div class="full-w" *ngIf="(schemaForm.get('cycle').value && cycle) || (schemaForm.get('stepwise').value && stepwise)">
      <h4 class="full-w">{{ "drugSchema.overview" | translate }} :</h4>
      <div class="schemaTable">
        <div *ngFor="let eachRow of rowOfDisplayedColumns">
          <table mat-table class="full-w" [dataSource]="[cycle.cycle]" *ngIf="schemaForm.get('isCycleSelected').value === true">
            <ng-container *ngFor="let i of eachRow" [matColumnDef]="i">
              <th mat-header-cell *matHeaderCellDef>{{ +i + 1 }}</th>
              <td mat-cell *matCellDef="let element" [ngClass]="element[i] === true ? 'intakeTrue' : 'intakeFalse'"></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="eachRow"></tr>
            <tr mat-row *matRowDef="let row; columns: eachRow"></tr>
          </table>
          <table mat-table class="full-w" [dataSource]="[stepwise.stepwises]" *ngIf="schemaForm.get('isCycleSelected').value === false">
            <ng-container *ngFor="let i of eachRow" [matColumnDef]="i">
              <th mat-header-cell *matHeaderCellDef>{{ +i + 1 }}</th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="(i | isStepwiseSelected : stepwise) ? 'intakeTrue' : 'intakeFalse'"
                [mdePopoverTriggerFor]="appPopover"
                mdePopoverTriggerOn="hover"
                #popoverTrigger="mdePopoverTrigger"
              ></td>
              <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" mdePopoverPositionY="above" mdePopoverArrowWidth="0">
                <div *ngIf="i | isStepwiseSelected : stepwise" class="popoverContainer">
                  <div *ngFor="let moment of (i | getStepwise : stepwise)?.days[0]?.moment" class="moment">
                    {{ "careplan.timingCodes." + moment.timingCode | translate }}
                    <div *ngFor="let drug of moment.drugs" class="drugs">
                      <div class="drugName">
                        {{ drug.name }}
                      </div>
                      <div class="drugQuantity">
                        {{ drug.quantity }}
                      </div>
                    </div>
                  </div>
                </div>
              </mde-popover>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="eachRow"></tr>
            <tr mat-row *matRowDef="let row; columns: eachRow"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</form>
