<div class="inputProperties" *ngIf="question && question.answerDisplay">
  <section class="properties-group">
    <mat-form-field class="label-field">
      <mat-label>
        {{ "page.questionnaireEditor.properties.questionText" | translate }}
      </mat-label>
      <input matInput type="text" [(ngModel)]="label" [disabled]="visualization" />
    </mat-form-field>
    <mat-form-field class="page-number-field" *ngIf="!specificUse">
      <mat-label>
        {{ "page.questionnaireEditor.page" | translate }}
      </mat-label>
      <mat-select [(ngModel)]="page" name="page" [disabled]="visualization">
        <mat-option *ngFor="let in of pagesNumbers(); let i = index" [value]="i">
          {{ "page.questionnaireEditor.page" | translate }} {{ i + 1 }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <section class="properties-group">
    <div *ngFor="let img of question.imageUrls">
      <mat-form-field class="label-field">
        <mat-label>
          {{ "page.questionnaireEditor.properties.imagePath" | translate }}
        </mat-label>
        <input matInput type="text" [(ngModel)]="img.path" [disabled]="visualization" />
      </mat-form-field>
      <mat-form-field class="label-field">
        <mat-label>
          {{ "page.questionnaireEditor.properties.imageCaption" | translate }}
        </mat-label>
        <input matInput type="text" [(ngModel)]="img.caption" [disabled]="visualization" />
      </mat-form-field>
    </div>
    <button mat-button (click)="addImage()" color="primary" *ngIf="!visualization">
      <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
      {{ "page.questionnaireEditor.properties.addImage" | translate }}
    </button>
    <button *ngIf="question.imageUrls?.length > 0 && !visualization" mat-button (click)="removeImage()" color="warn">
      <mat-icon aria-hidden="false" aria-label="Remove icon">remove</mat-icon>
      {{ "page.questionnaireEditor.properties.removeImage" | translate }}
    </button>
  </section>

  <div [ngSwitch]="question.answerDisplay.type">
    <!-- ========================= CHOICES ================================== -->
    <app-choice-input
      *ngSwitchCase="TYPES.RANGE"
      [(question)]="question"
      [lang]="lang"
      [(contained)]="questionsOptions"
      [specificUseSetter]="specificUse"
      [disabled]="visualization"
    >
    </app-choice-input>
    <app-choice-input
      *ngSwitchCase="TYPES.CHECKBOXES"
      [(question)]="question"
      [lang]="lang"
      [(contained)]="questionsOptions"
      [specificUseSetter]="specificUse"
      [disabled]="visualization"
    >
    </app-choice-input>
    <app-choice-input
      *ngSwitchCase="TYPES.RADIO"
      [(question)]="question"
      [lang]="lang"
      [(contained)]="questionsOptions"
      [specificUseSetter]="specificUse"
      [disabled]="visualization"
    >
    </app-choice-input>
    <app-choice-input
      *ngSwitchCase="TYPES.DROPDOWN"
      [(question)]="question"
      [lang]="lang"
      [(contained)]="questionsOptions"
      [specificUseSetter]="specificUse"
      [disabled]="visualization"
    >
    </app-choice-input>
    <!-- ========================== TEXT ==================================== -->
    <app-text-input *ngSwitchCase="TYPES.TEXT" [(question)]="question" [disabled]="visualization"></app-text-input>
    <app-text-input *ngSwitchCase="TYPES.STRING" [(question)]="question" [disabled]="visualization"></app-text-input>
    <app-text-input *ngSwitchCase="TYPES.TEXTFIELD" [(question)]="question" [disabled]="visualization"></app-text-input>
    <!-- ========================= NUMBER =================================== -->
    <app-number-input
      *ngSwitchCase="TYPES.NUMBER"
      [(question)]="question"
      [lang]="lang"
      [(contained)]="questionsOptions"
      [disabled]="visualization"
    ></app-number-input>
    <app-number-input
      *ngSwitchCase="TYPES.DECIMAL"
      [(question)]="question"
      [lang]="lang"
      [(contained)]="questionsOptions"
      [disabled]="visualization"
    ></app-number-input>
    <!-- ========================== DATE ==================================== -->
    <app-date-input *ngSwitchCase="TYPES.DATE" [(question)]="question" [disabled]="visualization"></app-date-input>
    <!-- ========================== TIME ==================================== -->
    <app-time-input *ngSwitchCase="TYPES.TIME" [(question)]="question" [disabled]="visualization"></app-time-input>
    <!-- ========================= RATING =================================== -->
    <app-rating-input *ngSwitchCase="TYPES.RATING" [(question)]="question" [disabled]="visualization"></app-rating-input>
  </div>
  <!-- ======================== Required ================================== -->
  <section class="properties-group">
    <mat-slide-toggle class="required-toggle" [(ngModel)]="required" [disabled]="visualization">
      {{ "page.questionnaireEditor.properties.required" | translate }}
    </mat-slide-toggle>
  </section>
  <!-- ========================= Enable when ============================== -->
  <section class="properties-group">
    <h3>{{ "page.questionnaireEditor.properties.conditions" | translate }} :</h3>
    <h4>{{ "page.questionnaireEditor.properties.showIf" | translate }}</h4>
    <!-- ========== first condition (we need to separate this first elem for the "enableWhenBehavior"
         => AND, OR when we have multiple conditions) ============-->
    <div class="enableWhenForm" *ngIf="enableWhenArray && enableWhenArray.length">
      <mat-form-field style="width: 70%" [floatLabel]="'never'">
        <mat-select
          [disabled]="visualization"
          [(ngModel)]="enableWhenArray[0].question"
          placeholder="{{ 'page.questionnaireEditor.properties.question-x' | translate }}"
        >
          <mat-option *ngFor="let q of questions; let i = index" [value]="q.linkId">
            {{ "page.questionnaireEditor.properties.question" | translate }}
            {{ i + 1 }} ({{ q.text }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 70%" [floatLabel]="'never'">
        <mat-select [(ngModel)]="enableWhenArray[0].operator" placeholder="=" [disabled]="visualization">
          <mat-option *ngFor="let operator of operatorOptions" [value]="operator">
            {{ operator }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 70%" [floatLabel]="'never'">
        <input
          [disabled]="visualization"
          matInput
          type="text"
          [(ngModel)]="enableWhenArray[0].answer"
          placeholder="{{ 'page.questionnaireEditor.properties.value-x' | translate }}"
        />
      </mat-form-field>
      <mat-icon
        button
        class="deleteButton"
        aria-hidden="false"
        aria-label="Delete icon"
        *ngIf="!visualization"
        (click)="deleteEnableWhen(0)"
      >
        cancel</mat-icon
      >
    </div>
    <mat-form-field style="width: 50%; text-align: left" *ngIf="enableWhenArray.length > 1">
      <mat-select [(ngModel)]="enableWhenBehavior" [disabled]="visualization">
        <mat-option *ngFor="let behavior of enableWhenBehaviorOptions" [value]="behavior.value">
          {{ behavior.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- ========= others conditions =========-->
    <div *ngFor="let enableWhen of enableWhenArray; let index = index; let isFirst = first">
      <div *ngIf="!isFirst" class="enableWhenForm">
        <mat-form-field style="width: 70%" [floatLabel]="'never'">
          <mat-select [(ngModel)]="enableWhen.question" placeholder="question x" [disabled]="visualization">
            <mat-option *ngFor="let q of questions; let i = index" [value]="q.linkId">
              {{ "page.questionnaireEditor.properties.question" | translate }}
              {{ i + 1 }} ({{ q.text }})
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 70%" [floatLabel]="'never'">
          <mat-select [(ngModel)]="enableWhen.operator" placeholder="=" [disabled]="visualization">
            <mat-option *ngFor="let operator of operatorOptions" [value]="operator">
              {{ operator }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 70%" [floatLabel]="'never'">
          <input matInput type="text" [(ngModel)]="enableWhen.answer" placeholder="value x" [disabled]="visualization" />
        </mat-form-field>
        <mat-icon
          button
          class="deleteButton"
          aria-hidden="false"
          aria-label="Delete icon"
          *ngIf="!visualization"
          (click)="deleteEnableWhen(index)"
        >
          cancel</mat-icon
        >
      </div>
    </div>
    <button mat-stroked-button class="saveButton" *ngIf="!visualization" (click)="addEmptyEnableWhen()">
      <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
      {{ "page.questionnaireEditor.properties.addCondition" | translate }}
    </button>
  </section>
</div>
