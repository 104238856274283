import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EntityDrug } from "src/app/models/entity.interface";
import { Notification } from "src/app/models/notifications.interface";
import { AddDrugIntakeDialogComponent } from "src/app/pages/add-drug-intake-dialog/add-drug-intake-dialog.component";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-drug-details",
  templateUrl: "./drug-details.component.html",
  styleUrls: ["./drug-details.component.scss"],
})
export class DrugDetailsComponent implements OnInit, OnDestroy {
  public nbSection: number;
  public sections: { [pageNbr: number]: Notification[] } = {};
  public selectedSection: Notification[] = [];
  public page = 0;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { drug: EntityDrug; notifications?: Notification[]; patientId: string },
    private sessionService: SessionService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // 10 results by page
    this.nbSection = Math.ceil(this.data.notifications.length / 10);
    // we build section with index
    for (let index = 0; index < this.nbSection; index++) {
      const start = index * 10;
      this.sections[index] = this.data.notifications.slice(start, start + 10);
    }
    this.selectedSection = this.sections[this.page];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public changePage(sign: "-" | "+"): void {
    switch (sign) {
      case "-":
        if (!this.isFirstSection()) {
          this.page--;
          this.selectedSection = this.sections[this.page];
        }
        break;
      case "+":
        if (!this.isLastSection()) {
          this.page++;
          this.selectedSection = this.sections[this.page];
        }
        break;
      default:
        break;
    }
  }

  public isLastSection(): boolean {
    return this.page + 1 === this.nbSection;
  }

  public isFirstSection(): boolean {
    return this.page + 1 === 1;
  }

  public onAddIntake(notif: Notification, index: number): void {
    const dialogRef = this.dialog.open(AddDrugIntakeDialogComponent, {
      data: {
        patientId: this.data.patientId,
        drug: this.data.drug,
        time: notif.time,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result?.length === 1) {
          this.selectedSection[+index] = result[0];
          this.sessionService.needRefreshDrugsData();
        }
      });
  }
}
