<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable">
  <mat-card-header class="widget-header">
    <mat-card-title class="custom-widget-title">
      {{ "widget.patientAlertNoTransmission" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="widget-content">
    <app-patient-alert-table [widgetName]="widgetName" [alertLevel]="1"></app-patient-alert-table>
  </mat-card-content>
</mat-card>
