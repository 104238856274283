<div class="close-cross">
  <button mat-icon-button mat-dialog-close class="negative-margin">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="CareplanKnowledgesModalContainer">
  <div *ngIf="isLoading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <form [formGroup]="form" *ngIf="!isLoading" style="display: flex; flex-direction: column">
    <div style="flex-direction: row">
      <label>{{ "drugIntake.administered" | translate }} : </label>
      <mat-radio-group formControlName="intakeValidation">
        <mat-radio-button style="margin-left: 5px" [value]="true">{{ "common.yes" | translate }}</mat-radio-button>
        <mat-radio-button style="margin-left: 5px" [value]="false">{{ "common.no" | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field style="width: 300px">
      <textarea cdkTextareaAutosize matInput placeholder="{{ 'forms.addComment' | translate }}" formControlName="comment"></textarea>
    </mat-form-field>
  </form>
  <button mat-flat-button color="primary" [matTooltip]="'btn.save' | translate" (click)="save()">
    {{ "btn.save" | translate }}
  </button>
</mat-dialog-content>
