<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modaleSchemaAdd">
  <div class="schemaAdd">
    <h2 *ngIf="isCreation">{{ "stepwiseDrug.creation" | translate }}</h2>
    <h2 *ngIf="!isCreation">{{ "stepwiseDrug.edition" | translate }}</h2>
    <form [formGroup]="schemaForm">
      <mat-form-field class="full-w">
        <input matInput type="text" placeholder="{{ 'stepwiseDrug.name' | translate }}" formControlName="name" />
        <mat-error>{{ "forms.errors.stepwiseDrug.nameError" | translate }}</mat-error>
      </mat-form-field>
      <div class="full-w">
        <mat-label>{{ "stepwiseDrug.nbrOfDays" | translate }} :</mat-label>
        <input
          matInput
          class="nbrInput"
          type="number"
          formControlName="nbrOfDays"
          step="1"
          min="0"
          max="365"
          (change)="onNbrOfDaysChange()"
        />
      </div>
      <div class="full-w mt-15">
        <div *ngFor="let eachRow of rowOfDisplayedColumns">
          <table mat-table [dataSource]="[stepwiseDrug.stepwises]">
            <ng-container *ngFor="let i of eachRow" [matColumnDef]="i">
              <th mat-header-cell *matHeaderCellDef>{{ +i + 1 }}</th>
              <td
                mat-cell
                *matCellDef="let element"
                (click)="onCellClick(i)"
                [ngClass]="i | getClassesForStepwise : cellsCurrentlySelected : lastCellClicked : stepwiseDrug"
                [mdePopoverTriggerFor]="appPopover"
                mdePopoverTriggerOn="hover"
                #popoverTrigger="mdePopoverTrigger"
              ></td>
              <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" mdePopoverPositionY="above" mdePopoverArrowWidth="0">
                <div *ngIf="i | isStepwiseSelected : stepwiseDrug" class="popoverContainer">
                  <div *ngFor="let moment of (i | getStepwise : stepwiseDrug)?.days[0]?.moment" class="moment">
                    {{ "careplan.timingCodes." + moment.timingCode | translate }}
                    <div *ngFor="let drug of moment.drugs" class="drugs">
                      <div class="drugName">
                        {{ drug.name }}
                      </div>
                      <div class="drugQuantity">
                        {{ drug.quantity }}
                      </div>
                    </div>
                  </div>
                </div>
              </mde-popover>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="eachRow"></tr>
            <tr mat-row *matRowDef="let row; columns: eachRow"></tr>
          </table>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center">
        <button class="stepwiseActionBtn" mat-raised-button color="primary" *ngIf="showAddStepwiseButton" (click)="onCreateStepwise()">
          {{ "drugSchema.stepwise.addStepwise" | translate }}
        </button>
        <button class="stepwiseActionBtn" mat-raised-button color="primary" *ngIf="showAddToPreviousButton" (click)="onExtendStepwise()">
          {{ "drugSchema.stepwise.extendStepwise" | translate }}
        </button>
        <button class="stepwiseActionBtn" mat-raised-button color="primary" *ngIf="showEditStepwiseButton" (click)="onEditStepwise()">
          {{ "drugSchema.stepwise.editStepwise" | translate }}
        </button>
        <button
          class="stepwiseActionBtn"
          mat-raised-button
          color="warn"
          *ngIf="showEditStepwiseButton && (lastCellClicked | isLastOrFirstCellOfStepwise : stepwiseDrug)"
          (click)="onDeleteCell()"
        >
          {{ "drugSchema.stepwise.deleteCell" | translate }}
        </button>
        <button class="stepwiseActionBtn" mat-raised-button color="warn" *ngIf="showEditStepwiseButton" (click)="onDeleteStepwise()">
          {{ "drugSchema.stepwise.deleteStepwise" | translate }}
        </button>
      </div>
      <div class="full-w flex mt-15" *ngIf="isAuthorizedAddStepwiseDrug()">
        <mat-checkbox formControlName="saveAsSchema">{{ "stepwiseDrug.saveAsSchema" | translate }}</mat-checkbox>
        <mat-form-field *ngIf="schemaForm.get('saveAsSchema').value === true">
          <mat-label>{{ "drugSchema.visibility" | translate }}</mat-label>
          <mat-select formControlName="forWho">
            <mat-option value="me">{{ "drugSchema.me" | translate }}</mat-option>
            <mat-option value="myOrg">{{ "drugSchema.myOrg" | translate }}</mat-option>
            <mat-option value="global">{{ "drugSchema.global" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="action-container">
      <button mat-raised-button color="primary" (click)="onCancel()">
        {{ "common.cancel" | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="onSave()">
        {{ "drugSchema.confirm" | translate }}
      </button>
    </div>
  </div>
</div>
