<form [formGroup]="serviceForm">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2 mat-dialog-title *ngIf="isCreation">
    {{ "page.organizations.creatingTitleServ" | translate }}
  </h2>
  <h2 mat-dialog-title *ngIf="!isCreation">
    {{ "page.organizations.modifyingTitleServ" | translate }}
  </h2>

  <mat-dialog-content>
    <mat-form-field class="font-12">
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.name' | translate }}"
        formControlName="name"
        autocomplete="off"
        pattern="[A-Za-zÀ-ú0-9- ']*"
      />
      <mat-error>{{ "forms.errors.nameFormat" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="font-12">
      <span matPrefix *ngIf="isCreation">{{ organizationRef }}/</span>
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.reference' | translate }}"
        formControlName="reference"
        autocomplete="off"
        pattern="[A-Za-z0-9-]*"
      />
      <mat-error>
        {{ "forms.errors.ins.pattern" | translate }}
      </mat-error>
    </mat-form-field>
    <br />
    <mat-form-field class="font-12" *ngIf="canReadAccessGroups" [ngStyle]="{ 'padding-top': !serviceForm.get('name').valid ? '3%' : '0' }">
      <mat-label>{{ "page.organizations.defaultAccessGroups" | translate }}</mat-label>
      <mat-select formControlName="defaultAccessGroups" multiple>
        <mat-option *ngFor="let group of allAccessGroups" [value]="group.identifier.value">
          {{ group.identifier.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="!isMonitoringService">
      <mat-form-field class="font-12">
        <mat-label>{{ "page.organizations.monitoringOrgsLink" | translate }}</mat-label>
        <mat-select formControlName="monitoringOrgsLink" multiple>
          <mat-option *ngFor="let monitOrg of monitoringOrgsRefs" [value]="monitOrg">
            {{ monitOrg.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="font-12" *ngIf="serviceForm.get('monitoringOrgsLink').value.length > 0">
        <mat-label>{{ "page.organizations.monitoringServicesLink" | translate }}</mat-label>
        <mat-select formControlName="monitoringServicesLink" multiple>
          <mat-option *ngFor="let monitService of monitoringServicesRefs" [value]="monitService">
            {{ monitService.reference | orgNameFromServiceRefAndMonitLinks : monitLinks : serviceForm.get("monitoringOrgsLink").value }}
            {{ monitService.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <br />

    <mat-form-field class="font-12">
      <mat-icon matSuffix class="color-button">phone</mat-icon>
      <input type="phone" matInput placeholder="{{ 'forms.phone' | translate }}" formControlName="phone" autocomplete="off" />
      <mat-error *ngIf="serviceForm.get('phone').hasError('phoneValidator')">{{ "forms.checkPhoneFormat" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="font-12">
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.description' | translate }}"
        formControlName="phoneLabel"
        autocomplete="off"
      />
    </mat-form-field>

    <br />

    <mat-form-field class="font-12">
      <mat-icon matSuffix class="color-button">mail</mat-icon>
      <input type="mail" matInput placeholder="{{ 'forms.mail' | translate }}" formControlName="mail" autocomplete="off" />
      <mat-error>{{ "page.login.email-required" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="font-12">
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.description' | translate }}"
        formControlName="mailLabel"
        autocomplete="off"
      />
    </mat-form-field>

    <p style="padding-top: 3%">{{ "page.organizations.table.address" | translate }} :</p>
    <mat-grid-list cols="2" rowHeight="55px">
      <mat-grid-tile colspan="2" class="left-align">
        <mat-form-field class="font-12 field-max-width">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.line1' | translate }}"
            formControlName="addressLine1"
            autocomplete="off"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="2" class="left-align">
        <mat-form-field class="font-12 field-max-width">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.line2' | translate }}"
            formControlName="addressLine2"
            autocomplete="off"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile class="left-align">
        <mat-form-field class="font-12">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.zipCode' | translate }}"
            formControlName="addressCP"
            autocomplete="off"
            pattern="[A-Za-z0-9-, ]{0,10}"
          />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile class="left-align">
        <mat-form-field class="font-12">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.city' | translate }}"
            formControlName="addressCity"
            autocomplete="off"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile class="left-align">
        <mat-select formControlName="addressCountry" placeholder="{{ 'page.organizations.table.country' | translate }}">
          <mat-option *ngFor="let country of availableCountries" [value]="country.countryCode">
            {{ "forms.country." + (country.countryCode | lowercase) | translate }}
          </mat-option>
        </mat-select>
      </mat-grid-tile>
      <mat-grid-tile></mat-grid-tile>
    </mat-grid-list>
    <p style="padding-top: 3%" *ngIf="imageUrl">Photo :</p>
    <mat-grid-list cols="2" rowHeight="55px" *ngIf="imageUrl">
      <mat-grid-tile rowspan="2" class="left-align">
        <img class="customImgModale" [src]="imageUrl" />
      </mat-grid-tile>
    </mat-grid-list>
    <input hidden (change)="uploadImage($event)" #fileInput type="file" accept="image/jpeg" id="file" />
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button *ngIf="isCreation" color="primary" (click)="onSave()">
      {{ "page.organizations.btn.createServ" | translate }}
    </button>
    <button mat-raised-button *ngIf="!isCreation" color="primary" (click)="onSave()">
      {{ "page.organizations.btn.updateServ" | translate }}
    </button>
    <button mat-raised-button matTooltip="{{ 'page.organizations.btn.addImage' | translate }}" (click)="fileInput.click()">
      <mat-icon>insert_photo</mat-icon>
    </button>
    <p *ngIf="showImgError" class="fail ml-10">
      {{ "page.myService.imageError" | translate }}
    </p>
  </mat-dialog-actions>
</form>
