import { Component, Input, OnChanges } from "@angular/core";
import { IStatsObsGroups } from "src/app/models/statObservation.interface";

@Component({
  selector: "app-stat-card",
  templateUrl: "./stat-card.component.html",
  styleUrls: ["./stat-card.component.scss"],
})
export class StatCardComponent implements OnChanges {
  public noData: boolean;
  @Input() statsObsGroups: IStatsObsGroups;
  @Input() reversed: boolean;
  ngOnChanges(): void {
    this.noData = this.statsObsGroups.statistics.every((statistic) => {
      return statistic.values.length === 0;
    });
  }
}
