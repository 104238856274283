import { Injectable, Injector } from "@angular/core";
import { CareplanEditorApiService } from "src/app/careplan-editor/application/careplan-editor-api.service";
import { ActivityApiService } from "src/app/providers/api/activity-api.service";
import { AppointmentApiService } from "src/app/providers/api/appointment-api.service";
import { AuthApiService } from "src/app/providers/api/auth-api.service";
import { CareplansApiService } from "src/app/providers/api/careplans-api.service";
import { CommunicationsApiService } from "src/app/providers/api/communications-api.service";
import { CountriesApiService } from "src/app/providers/api/countries-api.service";
import { DeleteRequestApiService } from "src/app/providers/api/deleteRequest-api.service";
import { DocumentApiService } from "src/app/providers/api/document-api.service";
import { DrugsApiService } from "src/app/providers/api/drugs-api.service";
import { DrugSchemaApiService } from "src/app/providers/api/drugSchema-api.service";
import { ExportApiService } from "src/app/providers/api/export-api.service";
import { GroupApiService } from "src/app/providers/api/group-api.service";
import { HealthcareserviceApiService } from "src/app/providers/api/healthcareservice-api.service";
import { INSApiService } from "src/app/providers/api/ins-api.service";
import { KnowledgeApiService } from "src/app/providers/api/knowledge-api.service";
import { KnowledgeCriteriaApiService } from "src/app/providers/api/knowledge-criteria-api.service";
import { LanguagesApiService } from "src/app/providers/api/languages-api.service";
import { ObservationApiService } from "src/app/providers/api/observations-api.service";
import { OrganizationsApiService } from "src/app/providers/api/organisations-api.service";
import { PatientApiService } from "src/app/providers/api/patient-api.service";
import { PractitionerApiService } from "src/app/providers/api/practitioner-api.service";
import { PreferenceApiService } from "src/app/providers/api/preference-api.service";
import { QRApiService } from "src/app/providers/api/qr-api.service";
import { QuestionnaireApiService } from "src/app/providers/api/questionnaires-api.service";
import { RewardDefinitionApiService } from "src/app/providers/api/rewardDefinition-api.service";
import { RewardScoreApiService } from "src/app/providers/api/rewardScore-api.service";
import { RiskAssessmentApiService } from "src/app/providers/api/risk-assessments-api.service";
import { RuleAlertApiService } from "src/app/providers/api/rule-alert-api.service";
import { StatObservationApiService } from "src/app/providers/api/stat-observation-api.service";
import { StepwiseDrugApiService } from "src/app/providers/api/stepwiseDrug-api.service";
import { ToolsApiService } from "src/app/providers/api/tools-api.service";
import { TownsApiService } from "src/app/providers/api/towns-api.service";
import { UserApiService } from "src/app/providers/api/user-api.service";
import { UserStatisticsApiService } from "src/app/providers/api/userStatistics-api.service";
import { ValueSetsApiService } from "src/app/providers/api/valueSets-api.service";
import { VitalProfileDefinitionApiService } from "src/app/providers/api/vital-profile-definition-api.service";
import { VitalSignApiService } from "src/app/providers/api/vital-sign-api.service";

@Injectable({
  providedIn: "root",
})
export class RoutesService {
  constructor(private injector: Injector) {
    injector.get(ActivityApiService);
    injector.get(AppointmentApiService);
    injector.get(AuthApiService);
    injector.get(CareplansApiService);
    injector.get(CommunicationsApiService);
    injector.get(DeleteRequestApiService);
    injector.get(DocumentApiService);
    injector.get(DrugsApiService);
    injector.get(DrugSchemaApiService);
    injector.get(GroupApiService);
    injector.get(HealthcareserviceApiService);
    injector.get(KnowledgeApiService);
    injector.get(KnowledgeCriteriaApiService);
    injector.get(LanguagesApiService);
    injector.get(ObservationApiService);
    injector.get(OrganizationsApiService);
    injector.get(PatientApiService);
    injector.get(PractitionerApiService);
    injector.get(PreferenceApiService);
    injector.get(QRApiService);
    injector.get(QuestionnaireApiService);
    injector.get(RewardDefinitionApiService);
    injector.get(RewardScoreApiService);
    injector.get(RiskAssessmentApiService);
    injector.get(RuleAlertApiService);
    injector.get(UserApiService);
    injector.get(ValueSetsApiService);
    injector.get(VitalProfileDefinitionApiService);
    injector.get(VitalSignApiService);
    injector.get(TownsApiService);
    injector.get(INSApiService);
    injector.get(UserStatisticsApiService);
    injector.get(ToolsApiService);
    injector.get(StatObservationApiService);
    injector.get(StepwiseDrugApiService);
    injector.get(ExportApiService);
    injector.get(CountriesApiService);
    injector.get(CareplanEditorApiService);
  }
}
