import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ChooseInsuranceDialogComponent } from "./choose-insurance-dialog/choose-insurance-dialog.component";

@Component({
  selector: "app-patient-already-exists-dialog",
  templateUrl: "./patient-already-exists-dialog.component.html",
  styleUrls: ["./patient-already-exists-dialog.component.scss"],
})
export class PatientAlreadyExistsDialogComponent implements OnInit {
  public isOtherOrg: boolean;
  public isPractitioner: boolean;
  public usersFound: {
    sameEmail: boolean;
    samePhone: boolean;
    isRelated: boolean;
    existingPatient: any;
    isSelected?: boolean;
  }[] = [];
  public indexSelected = 0;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PatientAlreadyExistsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const additionalData = this.data.additionalData;
    this.isPractitioner = additionalData[0].isPractitioner;
    if (!this.isPractitioner) {
      this.isOtherOrg = additionalData.length === 1 && !additionalData[0].isRelated && !additionalData[0].sameOrganization;
      if (!this.isOtherOrg) {
        additionalData.forEach((user) => {
          this.usersFound.push({
            existingPatient: user.existingPatient,
            isRelated: user.isRelated,
            sameEmail: user.existingPatient.mail === this.data.mail,
            samePhone: user.existingPatient.phone === this.data.phone,
          });
        });
        this.usersFound[this.indexSelected].isSelected = true;
      }
    }
  }

  public validate() {
    if (this.usersFound[this.indexSelected].existingPatient.requestChangeInsurance) {
      // need to choose the insurance
      const dialogRef = this.dialog.open(ChooseInsuranceDialogComponent, {
        disableClose: true,
        data: {
          oldIns: this.usersFound[this.indexSelected].existingPatient.insurance,
          newIns: this.data.insuranceRef.display,
        },
        panelClass: "dialog-container-scroll",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.dialogRef.close({
            forceUpdateUser: this.usersFound[this.indexSelected].existingPatient.mail,
            needChangeInsurance: result.needChange,
          });
        }
      });
    } else {
      this.dialogRef.close({
        forceUpdateUser: this.usersFound[this.indexSelected].existingPatient.mail,
      });
    }
  }

  public closeAll() {
    this.dialog.closeAll();
  }

  public sendMail() {
    const mailText = "mailto:contact@comunicare.be?subject=Patient already exists in a different organization"; // add the links to body
    window.location.href = mailText;
  }
}
