import { CdkDrag } from "@angular/cdk/drag-drop";
import { Filter } from "./filter.interface";
import { PatientUser } from "./patient.interface";

export enum WidgetName {
  PATIENT_ALERT_ORANGE = "patientAlertOrange",
  PATIENT_ALERT_RED = "patientAlertRed",
  TELE_CONSULTATION = "teleConsultation",
  LAST_PATIENT = "lastPatient",
  PATIENT_LAST_ACTIVITY = "lastActivityPatient",
  PATIENT_LIST = "patientList",
  PATIENT_ALERT_NO_TRANSMISSION = "patientAlertNoTransmission",
}

export interface IWidgetComponent {
  isDraggable: boolean;
  cdkDrag: CdkDrag;
  patientsUser?: PatientUser[];
  filters?: Filter[];
  patientsCount?: number;
  search?: string;
  groupId?: string;
}

export interface WidgetStored {
  name: string;
  column?: number;
  row?: number;
}
