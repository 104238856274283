import { Component, Input, OnChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { IStatisticsConfiguration } from "src/app/models/statObservation.interface";
import { StatObservationService } from "src/app/providers/stat-observation.service";

/**
 * This component is in charge of loading the statistic configuration based on the loinc of the an Observation and of
 * creating the grid container on which the observation-stats components will be displayed.
 */
@Component({
  selector: "app-observation-stats-container",
  templateUrl: "./observation-stats-container.component.html",
  styleUrls: ["./observation-stats-container.component.scss"],
})
export class ObservationStatsContainerComponent implements OnChanges {
  @Input() obsDefinition: IObservationDefinition;
  @Input() patientId: string;
  @Input() fromDate: moment.Moment;
  @Input() toDate: moment.Moment;

  public config: IStatisticsConfiguration;
  private configSubscription: Subscription;

  constructor(private statObservationService: StatObservationService) {}

  ngOnChanges(): void {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }

    this.configSubscription = this.statObservationService
      .list(this.patientId, this.obsDefinition.loinc, this.fromDate.format("YYYY-MM-DD"), this.toDate.format("YYYY-MM-DD"))
      .pipe(first())
      .subscribe((x) => {
        this.config = x;
      });
  }
}
