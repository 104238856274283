import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ACTION_DRUGS_TYPE, HISTORIC_MODE, IDrugsHistoric } from "src/app/models/drugsHistoric.interface";
import { IEntitylink } from "src/app/models/entity.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Reference } from "src/app/models/reference.interface";
import { ITiming } from "src/app/models/sharedInterfaces";
import { DrugsService } from "src/app/providers/drugs.service";
import { DrugsHistoricDataSource } from "./drugs-historic-datasource";

@Component({
  selector: "app-drugs-historic",
  templateUrl: "./drugs-historic.component.html",
  styleUrls: ["./drugs-historic.component.scss"],
})
export class DrugsHistoricComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IDrugsHistoric>;

  public dataSource: DrugsHistoricDataSource;
  public currentPageSize: number;
  public displayedColumns: string[] = ["date", "author", "drugsName", "type", "exValue"];
  public historic: IDrugsHistoric[] = [];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private drugService: DrugsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      historic: IDrugsHistoric[];
      allDrugs: IEntitylink[];
      mode: HISTORIC_MODE;
    }
  ) {}

  ngOnInit(): void {
    this.dataSource = new DrugsHistoricDataSource(this.data.allDrugs);
    this.initData();
    this.displayedColumns =
      this.data.mode === HISTORIC_MODE.LOCAL
        ? ["date", "author", "type", "exValue", "newValue"]
        : ["date", "author", "drugsName", "type", "exValue", "newValue"];
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public get isLocalMode(): boolean {
    return this.data.mode === HISTORIC_MODE.LOCAL;
  }

  public get localDrugName(): string {
    return this.data.allDrugs[0]?.entityData?.name;
  }

  private initData() {
    this.dataSource.loadData(this.data.historic);
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public getDrugName(id: string): string {
    return this.data.allDrugs.find((v) => v._id === id)?.entityData.name;
  }

  public getTypeArray(historic: IDrugsHistoric): unknown[] {
    return historic.actions.map((a) => {
      return this.translateService.instant("actionDrugsHistoric." + a.actionType);
    });
  }

  public getExValuesArray(historic: IDrugsHistoric): string[] {
    return historic.actions.map((a) => this.computeValueByType(a.actionType, a.exValue));
  }

  public getNewValuesArray(historic: IDrugsHistoric): string[] {
    return historic.actions.map((a) => this.computeValueByType(a.actionType, a.newValue));
  }

  public isNotUpdateHistoric(historic: IDrugsHistoric): boolean {
    return (
      historic.actions.length === 1 &&
      (historic.actions[0].actionType === ACTION_DRUGS_TYPE.NEW || historic.actions[0].actionType === ACTION_DRUGS_TYPE.DELETE)
    );
  }

  /**
   * called by template only if isNotUpdateHistoric = true
   */
  public getType(historic: IDrugsHistoric): string {
    return this.translateService.instant("actionDrugsHistoric." + historic.actions[0].actionType);
  }

  private computeValueByType(type: ACTION_DRUGS_TYPE, value: string) {
    try {
      if (value) {
        switch (type) {
          case ACTION_DRUGS_TYPE.UPDATE_COMMENT:
          case ACTION_DRUGS_TYPE.UPDATE_NAME:
            return value;
          case ACTION_DRUGS_TYPE.UPDATE_FREQUENCY: {
            const timing = JSON.parse(value) as ITiming;
            return this.drugService.computeFrequency(timing);
          }
          case ACTION_DRUGS_TYPE.UPDATE_PRESCRIPTOR: {
            const prescriptor = JSON.parse(value) as Reference;
            return this.drugService.getPerformerName(prescriptor.reference);
          }
          case ACTION_DRUGS_TYPE.UPDATE_DATE: {
            const dates = JSON.parse(value) as [string, string];
            const endDate = dates[1]?.startsWith("9999") ? "∞" : moment(dates[1]).format("DD-MM-YYYY");
            return `${moment(dates[0]).format("DD-MM-YYYY")} -> ${endDate}`;
          }
          case ACTION_DRUGS_TYPE.UPDATE_MOMENT:
            return this.drugService.getTimingFromTimingCode(value);
          default:
            return "";
        }
      } else {
        throw new Error("No value for computeValueByType");
      }
    } catch (err) {
      return "";
    }
  }
}
