import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";

export interface Item {
  value: string;
  display: string;
  checked: boolean;
}
@Component({
  selector: "app-item-selector",
  templateUrl: "./item-selector.component.html",
  styleUrls: ["./item-selector.component.scss"],
})
export class ItemSelectorComponent {
  public listDisplayed = false;

  @Input() items: Item[] = [];
  @Input() position: "left" | "right" = "right";
  @Input() logo: "settings" | "perm_data_setting" = "settings";
  @Input() translate = false;
  @Output() changed: EventEmitter<Item[]> = new EventEmitter();

  public toggleList() {
    this.listDisplayed = !this.listDisplayed;
  }

  public itemChange(change: MatCheckboxChange, item: Item) {
    item.checked = change.checked;
    this.changed.emit(this.items);
  }
}
