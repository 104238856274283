import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { first, last, takeUntil } from "rxjs/operators";
import { ChangeLangMode } from "src/app/components/change-lang/change-lang.component";
import { InitPwdComponent } from "src/app/components/forms/init-password/init-password.component";
import { ResetOwnPasswordComponent } from "src/app/components/forms/reset-own-password/reset-own-password.component";
import {
  LangConflictDialogComponent,
  LangConflictDialogResult,
} from "src/app/components/lang-conflict-dialog/lang-conflict-dialog.component";
import { ReleaseNoteDialogComponent } from "src/app/components/release-note-dialog/release-note-dialog.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { CONNECTION_OPTION } from "src/app/models/account.interface";
import { AppError, ERROR_MSG } from "src/app/models/app-error.interface";
import { IAfterLoginData } from "src/app/models/authentification.interface";
import { ICountry } from "src/app/models/country.interface";
import { PreferenceContext } from "src/app/models/preference.interface";
import { AuthService } from "src/app/providers/auth.service";
import { CountriesService } from "src/app/providers/countries.service";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ReleaseNoteService } from "src/app/providers/releaseNote.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { environment } from "src/environments/environment";
import packageInfo from "../../../../package.json";
import { FormPage } from "../formPage";
import { SelectAccountModalComponent } from "./select-account-modal/select-account-modal.component";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent extends FormPage implements OnDestroy {
  static readonly COOKIE_PAGE_ID = "redirect-pageid";
  private readonly COOKIE_EHEAlTH_ERROR = "redirect-ehealth-error";
  private readonly COOKIE_EHEAlTH_NOT_FOUND = "redirect-ehealth-usernotfound";
  private readonly COOKIE_EHEAlTH_DEACTIVATED = "redirect-ehealth-deactivated-error";
  private readonly COOKIE_EHEAlTH_SEVERAL_ACCOUNTS = "redirect-ehealth-several-accounts";
  private readonly COOKIE_EHEAlTH_SEVERAL_TOKENS = "redirect-ehealth-several-tokens";
  private readonly COOKIE_EHEALTH_WRONG_PROFILE = "redirect-ehealth-profile-not-supported";

  @Output() loadingPage: EventEmitter<boolean> = new EventEmitter();

  public loading = false;
  public errorMessage: string;
  public langMode: ChangeLangMode;
  public version: string = packageInfo.version;
  public loginForm = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
    password: ["", Validators.required],
    error: [],
  });

  public isDev = environment.envName === "dev";
  public isTest = environment.envName === "test";
  public OptionsConnections = CONNECTION_OPTION;
  public optionsConnections: CONNECTION_OPTION[] = [];
  public needClassicLogin = false;
  public showErrorForm = false;
  public selectedError: number;
  public countries: ICountry[];
  public selectedCountry = "BE";

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private ANSCode: string;
  private eHealthSSIN: string;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private preferenceService: PreferenceService,
    private practitionerService: PractitionerService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private userService: UserService,
    private dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    private cookieService: CookieService,
    private releaseNoteService: ReleaseNoteService,
    private countriesService: CountriesService
  ) {
    super();
    breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.langMode = value.matches ? ChangeLangMode.DROPDOWN : ChangeLangMode.BUTTON;
      });
    if (this.cookieService.check(this.COOKIE_EHEAlTH_ERROR)) {
      this.errorMessage = "eHealthError";
      this.cookieService.delete(this.COOKIE_EHEAlTH_ERROR);
    } else if (this.cookieService.check(this.COOKIE_EHEAlTH_NOT_FOUND)) {
      this.errorMessage = "eHealthNotFound";
      this.eHealthSSIN = this.cookieService.get(this.COOKIE_EHEAlTH_NOT_FOUND);
      this.cookieService.delete(this.COOKIE_EHEAlTH_NOT_FOUND);
    } else if (this.cookieService.check(this.COOKIE_EHEAlTH_DEACTIVATED)) {
      this.errorMessage = "eHealthDeactivated";
      this.cookieService.delete(this.COOKIE_EHEAlTH_DEACTIVATED);
    } else if (
      this.cookieService.check(this.COOKIE_EHEAlTH_SEVERAL_ACCOUNTS) &&
      this.cookieService.check(this.COOKIE_EHEAlTH_SEVERAL_TOKENS)
    ) {
      // Several accounts found. Need to select one.
      const accounts = JSON.parse(this.cookieService.get(this.COOKIE_EHEAlTH_SEVERAL_ACCOUNTS));
      const tokens = JSON.parse(this.cookieService.get(this.COOKIE_EHEAlTH_SEVERAL_TOKENS));
      const auth: IAfterLoginData = {
        accounts,
        account: null,
        tokens,
        url: "",
        newPasswordRequired: false,
        needClassicLogin: false,
      };
      this.selectAccount(auth);
    } else if (this.cookieService.check(this.COOKIE_EHEALTH_WRONG_PROFILE)) {
      this.errorMessage = "eHealthWrongProfile";
    }
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(async (params) => {
      if (params && params.code && this.ANSCode !== params.code) {
        // const state = params.state;
        // const session_state = params.session_state;
        this.ANSCode = params.code;
        try {
          this.authService.authenticateANS(this.ANSCode, this.loadingPage).subscribe((auth: IAfterLoginData) => {
            this.loading = false;
            if (auth.needClassicLogin) {
              // PSC login ok, but no account found. Need mail/password login
              this.needClassicLogin = true;
              this.loadingPage.emit(false);
            } else if (!auth.account && auth.accounts && auth.accounts.length) {
              // Several accounts found. Need to select one.
              this.selectAccount(auth);
            } else {
              // Only one account found
              this.handleAuth(auth.url);
            }
          });
        } catch (error) {
          this.loading = false;
          this.loadingPage.emit(false);
          FileLogger.error("LoginPageComponent", "Error while authenticating with ANS");
        }
      }
    });

    this.countriesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (countries) => {
          this.countries = countries;
          this.optionsConnections = this.countries.find((c) => c.countryCode === this.selectedCountry).connectionsOptions;
        },
        (err) => {
          FileLogger.error("CountriesService", "Error while downloading countries list", err, "none");
        }
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public loginViaEHealth(): void {
    this.authService.redirectToEHealth();
  }

  public redirectToANS(): void {
    this.authService.redirectToANS();
  }

  private selectAccount(auth: IAfterLoginData): void {
    // Several accounts found. Need to select one.
    FileLogger.warn("LoginPageComponent", "Several accounts detected: ", auth.accounts);
    const dialog = this.dialog.open(SelectAccountModalComponent, {
      disableClose: true,
      data: {
        accounts: auth.accounts,
      },
    });
    dialog.afterClosed().subscribe((idxSelected) => {
      FileLogger.warn("LoginPageComponent", "Selected account: " + idxSelected, auth.accounts[idxSelected]);
      this.authService.selectAccount(auth, idxSelected).subscribe((auth: IAfterLoginData) => {
        this.cookieService.delete(this.COOKIE_EHEAlTH_SEVERAL_ACCOUNTS);
        this.cookieService.delete(this.COOKIE_EHEAlTH_SEVERAL_TOKENS);
        this.handleAuth(auth.url);
      });
    });
  }

  public login(): void {
    this.errorMessage = undefined;
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService
        .authenticate(this.loginForm.get("email").value, this.loginForm.get("password").value, this.loadingPage)
        .pipe(first())
        .subscribe(
          (auth: IAfterLoginData) => {
            this.loading = false;
            if (this.sessionService.ANS_otherIDs.length) {
              this.practitionerService.updateOwnInfos({ identifier: this.sessionService.ANS_otherIDs }).subscribe();
            }
            if (this.eHealthSSIN) {
              this.practitionerService.updateOwnInfos({}, { nationalnumber: this.eHealthSSIN }).subscribe();
            }
            if (auth.newPasswordRequired) {
              this.dialog
                .open(InitPwdComponent, {
                  disableClose: true,
                  data: { account: auth.account },
                })
                .afterClosed()
                .subscribe((newPwd) => {
                  if (newPwd) {
                    auth.account.newPassword = newPwd;
                    this.userService
                      .updateAccount(auth.account)
                      .pipe(last())
                      .subscribe(() => {
                        this.handleAuth(auth.url);
                      });
                  } else {
                    return;
                  }
                });
            } else {
              this.handleAuth(auth.url);
            }
          },
          (error: AppError) => {
            this.loading = false;
            this.loadingPage.emit(false);
            if (error.message === ERROR_MSG.NEED_PASSWORD_REFRESH) {
              this.dialog.open(ResetOwnPasswordComponent, {
                disableClose: true,
                data: {
                  login: this.loginForm.get("email").value,
                  lang: this.sessionService.userLang,
                  expiredPassword: true,
                },
              });
            } else {
              if (error.global) {
                throw error;
              } else {
                this.errorMessage = error.message;
              }
            }
          }
        );
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }

  public handleAuth(url: string): void {
    // Check user lang preference
    this.preferenceService
      .getGlobal()
      .pipe(first())
      .subscribe(
        (globalParam) => {
          if (globalParam && globalParam.preferredLanguage) {
            if (globalParam.preferredLanguage !== this.translateService.currentLang) {
              // We save "remember my choice" in case of lang conflict
              if (globalParam.usePreferredLanguageWhenConflict !== undefined) {
                if (globalParam.usePreferredLanguageWhenConflict) {
                  this.changeLang(globalParam.preferredLanguage);
                }
                this.navigate(url);
              } else {
                // Ask user to resolve lang conflict
                this.dialog
                  .open(LangConflictDialogComponent, {
                    data: {
                      lang1: globalParam.preferredLanguage,
                      lang2: this.translateService.currentLang,
                    },
                    disableClose: true,
                    closeOnNavigation: false,
                  })
                  .afterClosed()
                  .subscribe((result: LangConflictDialogResult) => {
                    // Remember the choice
                    if (result.remember) {
                      const isConflict = result.lang === globalParam.preferredLanguage;
                      globalParam.usePreferredLanguageWhenConflict = isConflict;
                      this.preferenceService
                        .update({
                          context: PreferenceContext.GLOBAL,
                          parameters: globalParam,
                        })
                        .subscribe();
                    }

                    // No lang chosen in dialog, fallback to current
                    if (!result.lang) {
                      result.lang = this.translateService.currentLang;
                    }
                    this.changeLang(result.lang);
                    this.navigate(url);
                  });
              }
            } else {
              this.changeLang(globalParam.preferredLanguage);
              this.navigate(url);
            }
          } else {
            this.navigate(url);
          }
        },
        () => {
          // Error to get preference, non blocking, continue to url
          this.navigate(url);
        }
      );
  }

  /**
   * Method to navigate to a first page (after login) :
   * - if the cookie this.COOKIE_PAGE_ID exists, navigate to the page indicates in this cookie
   * - otherwise, navigate to the page indicates by the parameter "url"
   */
  private async navigate(url: string) {
    this.releaseNoteService
      .getSlidesMissingReleaseNoteList()
      .pipe(first())
      .subscribe((slidesReleaseNote) => {
        const preference = slidesReleaseNote.releaseNoteParameter;
        const slides = slidesReleaseNote.slides;
        if (slides.length && preference) {
          this.dialog.open(ReleaseNoteDialogComponent, {
            data: { preference, slides },
            disableClose: true,
          });
        }
      });
    if (this.cookieService.check(LoginPageComponent.COOKIE_PAGE_ID)) {
      const pageId = this.cookieService.get(LoginPageComponent.COOKIE_PAGE_ID);
      this.cookieService.delete(LoginPageComponent.COOKIE_PAGE_ID);
      await this.router.navigate([pageId]);
      this.loadingPage.emit(false);
    } else {
      await this.router.navigate([url]);
      this.loadingPage.emit(false);
    }
  }

  private changeLang(lang: string) {
    this.translateService.use(lang);
    this.sessionService.userLang = lang;
  }

  public getForm(): UntypedFormGroup {
    return this.loginForm;
  }

  public prepareMail(): void {
    const problem = this.loginForm.get("error").value === 1 ? "mieCompromission" : "connexionIssue";

    const email = "support@comunicare.be";
    const subject = "[" + environment.envName + "] " + this.translateService.instant("page.login.mail." + problem + ".object");
    const body = encodeURIComponent(this.translateService.instant("page.login.mail." + problem + ".content"))
      //replace encoding for \n (line break) present in i18n
      .replace(new RegExp("%5Cn", "g"), "%0A");

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${body}`;

    window.open(mailtoLink, "_blank");
    this.showErrorForm = false;
  }

  public countryChanged(): void {
    this.optionsConnections = this.countries.find((c) => c.countryCode === this.selectedCountry).connectionsOptions;
  }
}
