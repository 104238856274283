import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { concatMap, filter, finalize, first, map, switchMap, tap } from "rxjs/operators";
import { AccessValue, IAccessGroupPermission } from "../models/accessGroup.interface";
import { Account } from "../models/account.interface";
import { Healthcareservice } from "../models/healthcareservice.model";
import { Organization } from "../models/organization.model";
import { UserApiService } from "./api/user-api.service";
import { PreferenceService } from "./preference.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private isGettingAccount = false;

  private pAllOrganizations: Organization[] = [];
  public get allOrganizations(): Organization[] {
    return this.pAllOrganizations;
  }
  public set allOrganizations(value: Organization[]) {
    this.pAllOrganizations = value;
  }
  private pAllServices: Healthcareservice[] = [];
  public get allServices(): Healthcareservice[] {
    return this.pAllServices;
  }
  public set allServices(value: Healthcareservice[]) {
    this.pAllServices = value;
  }
  private pOwnOrganization: Organization;
  public get ownOrganization(): Organization {
    return this.pOwnOrganization;
  }
  public set ownOrganization(value: Organization) {
    this.pOwnOrganization = value;
  }
  private pAllMonitoringServices: Healthcareservice[] = [];
  public get allMonitoringServices(): Healthcareservice[] {
    return this.pAllMonitoringServices;
  }
  public set allMonitoringServices(value: Healthcareservice[]) {
    this.pAllMonitoringServices = value;
  }
  private pAllMonitoringOrganizations: Organization[] = [];
  public get allMonitoringOrganizations(): Organization[] {
    return this.pAllMonitoringOrganizations;
  }
  public set allMonitoringOrganizations(value: Organization[]) {
    this.pAllMonitoringOrganizations = value;
  }

  public isMonitoringUser = false;

  constructor(private userApi: UserApiService, private sessionService: SessionService, private preferenceService: PreferenceService) {}

  public clear(): void {
    this.allMonitoringOrganizations = [];
    this.allMonitoringServices = [];
    this.allOrganizations = [];
    this.allServices = [];
    this.ownOrganization = null;
    this.isMonitoringUser = false;
  }

  public list(): Observable<Account[]> {
    return this.userApi.list();
  }

  public account(): Observable<Account> {
    return this.sessionService.getObservableAccount().pipe(
      switchMap((account) => {
        if (account !== undefined || this.isGettingAccount) {
          return of(account);
        } else {
          this.isGettingAccount = true;
          return this.me().pipe(
            tap((acc) => {
              this.sessionService.account = acc;
            }),
            finalize(() => {
              this.isGettingAccount = false;
            })
          );
        }
      })
    );
  }

  /**
   * @returns the default page for this user
   */
  public defaultPage(): Observable<string> {
    return this.preferenceService.getGlobal().pipe(
      first(),
      concatMap((globalParam) => {
        if (
          globalParam?.defaultLandingPage === "dashboard" ||
          globalParam?.defaultLandingPage === null ||
          globalParam?.defaultLandingPage === undefined ||
          globalParam?.defaultLandingPage === ""
        ) {
          return this.isAuthorized("/dashboard", "GET").pipe(
            map((access) => {
              if (access) {
                return "/dashboard";
              } else {
                // see CMATE-1822
                return "/patients";
              }
            })
          );
        } else {
          return of(globalParam.defaultLandingPage);
        }
      })
    );
  }
  public isAuthorized(routeName: string, method: string): Observable<boolean> {
    return this.account().pipe(
      filter((account) => account !== undefined),
      map((account: Account) => {
        if (account.permissions && routeName && method) {
          const fv = account.permissions.filter((f) => f.routeName === routeName && f.method === method);
          if (fv.find((f) => f.access === AccessValue.RESTRICTED)) {
            return false;
          } else if (fv.find((f) => f.access === AccessValue.AUTHORIZED)) {
            return true;
          }
        }
        return false;
      })
    );
  }

  public isAuthorizedSync(account: Account, routeName: string, method: string): boolean {
    let permissions: IAccessGroupPermission[] = [];
    const acc = this.sessionService.account ? this.sessionService.account : account;
    if (acc) {
      if (acc.permissions && routeName && method) {
        permissions = acc.permissions.filter((f) => f.routeName === routeName && f.method === method);
        if (permissions.find((f) => f.access === AccessValue.RESTRICTED)) {
          return false;
        } else if (permissions.find((f) => f.access === AccessValue.AUTHORIZED)) {
          return true;
        }
      }
    }
    return false;
  }

  private me(): Observable<Account> {
    return this.userApi.me() as Observable<Account>;
  }

  public updateAccount(account: Account): Observable<Record<string, never>> {
    return this.userApi.updateAccount(account);
  }

  public getMyService(): Observable<Healthcareservice> {
    return this.userApi.getMyService().pipe(
      map((values) => {
        return new Healthcareservice(values);
      })
    );
  }
}
