import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { IDrugInfo } from "src/app/models/drugInfo.interface";
import { DrugsService } from "src/app/providers/drugs.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-drug-server-side-search",
  templateUrl: "./drug-server-side-search.component.html",
  styleUrls: ["./drug-server-side-search.component.scss"],
})
export class DrugServerSideSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("autocomp") auto: ElementRef;
  constructor(private translateService: TranslateService, private drugService: DrugsService, private sessionService: SessionService) {}

  @Output() drugChanged: EventEmitter<IDrugInfo> = new EventEmitter<IDrugInfo>();
  @Input() formCtrl: UntypedFormControl;
  @Input() isCreation: boolean;
  @Input() withoutInput = false;
  @Output() ref: EventEmitter<ElementRef<HTMLInputElement>> = new EventEmitter<ElementRef<HTMLInputElement>>();
  @Input() public drugServerSideFilteringCtrl: UntypedFormControl = new UntypedFormControl(undefined, [
    Validators.required,
    this.noEmptyValue,
  ]);
  @Output() selectOption: EventEmitter<string> = new EventEmitter<string>();

  /** indicate search operation is in progress */
  public searching = false;

  public filteredServerSideDrug: Observable<IDrugInfo[]>;
  public userLang = "en";

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  protected onDestroy$ = new Subject<void>();

  public noEntriesFoundLabel = this.translateService.instant("common.noEntriesFoundLabel") as string;
  public placeholderLabel = this.translateService.instant("common.placeholderLabelDrug") as string;
  public lastQuery = "";
  private lastResults: IDrugInfo[] = [];

  public dummyInfo: IDrugInfo = {
    code: undefined,
    ctiExtended: undefined,
    cnk: undefined,
    officialName: this.lastQuery,
    fr: {
      fullName: this.lastQuery,
    },
    en: {
      fullName: this.lastQuery,
    },
    nl: {
      fullName: this.lastQuery,
    },
    de: {
      fullName: this.lastQuery,
    },
    country: null,
    dbName: null,
  };

  ngOnInit(): void {
    this.userLang = this.sessionService.userLang;
    if (this.formCtrl?.value) {
      if (typeof this.formCtrl.value === "string") {
        this.drugServerSideFilteringCtrl = new UntypedFormControl(this.formCtrl.value, [Validators.required, this.noEmptyValue]);
        this.dummyInfo = this.createDummyInfo(this.formCtrl.value);
      } else {
        this.drugServerSideFilteringCtrl = new UntypedFormControl(this.formCtrl.value.officialName, [
          Validators.required,
          this.noEmptyValue,
        ]);
        this.dummyInfo = this.formCtrl.value;
      }
    }
    this.filteredServerSideDrug = this.drugServerSideFilteringCtrl.valueChanges.pipe(
      startWith(""),
      takeUntil(this.onDestroy$),
      debounceTime(500),
      distinctUntilChanged(),
      map((search: string | IDrugInfo) => {
        if (!search) {
          return "";
        }
        if (typeof search === "string") {
          this.lastQuery = search;
          return search;
        }
        this.lastQuery = search[this.userLang].fullName ? search[this.userLang].fullName : search.officialName;
        return this.lastQuery;
      }),
      switchMap((name: string) => (name.length > 2 ? this.drugService.listInfo(name) : of(undefined))),
      tap((results: IDrugInfo[]) => {
        this.lastResults = Array.isArray(results) ? results : [];
      })
    );
  }

  ngAfterViewInit(): void {
    this.ref.emit(this.auto);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * This method is written differently on purpose
   * It's to bind mat-autocomplete to this component or else we can't use "this" calls
   */
  drugDisplay = (drug: IDrugInfo): string => {
    return drug ? (drug[this.userLang] ? drug[this.userLang].fullName : drug.officialName ? drug.officialName : drug) : "";
  };

  private createDummyInfo(name: string): IDrugInfo {
    return {
      code: undefined,
      ctiExtended: undefined,
      cnk: undefined,
      officialName: name,
      fr: {
        fullName: name,
      },
      en: {
        fullName: name,
      },
      nl: {
        fullName: name,
      },
      de: {
        fullName: name,
      },
      country: null,
      dbName: null,
    };
  }

  onDrugNameChanged(event: string | IDrugInfo): void {
    if (typeof event === "string") {
      /*
      If the practitioner writes the whole name of the drug or if he selects the
      first one in the list (which is a copy of what he writes), it is necessary
      to search if this one was proposed in the list to find the information.
      */
      const drug = this.lastResults.find((d) => d[this.userLang]?.fullName === event);
      this.dummyInfo = Tools.isDefined(drug) ? drug : this.createDummyInfo(event);
      this.formCtrl.setValue(this.dummyInfo);
      this.drugChanged.emit(this.dummyInfo);
    } else {
      this.formCtrl.setValue(event);
      this.drugChanged.emit(event);
    }
  }

  private noEmptyValue(control: UntypedFormControl) {
    let isValid: boolean;
    // control.value can be a string or IDrugInfo if an item has been selected from the list
    if (Tools.isString(control.value)) {
      isValid = control.value?.trim();
    } else {
      isValid = control.value?.officialName?.trim();
    }
    return isValid ? null : { whitespace: true };
  }

  public selected(drug: IDrugInfo): void {
    this.selectOption.emit(drug.officialName);
  }
}
