<div>
  <!-- Observation title -->
  <h4 *ngIf="type === 'observation'">
    {{ obsDefinition.components | findObsDefComponentsByLoinc : $any(statsGroups.identifier) | getShortname }}
    {{
      (obsDefinition.components | findObsDefComponentsByLoinc : $any(statsGroups.identifier)).unit
        ? "(" + (obsDefinition.components | findObsDefComponentsByLoinc : $any(statsGroups.identifier)).unit + ")"
        : ""
    }}
  </h4>
  <!-- Alert title -->
  <h4 *ngIf="type === 'alert'">Alerte : {{ obsDefinition.nameTranslation[translateService.currentLang] }}</h4>
  <ng-container [ngSwitch]="statsGroups.type">
    <mat-card>
      <app-stat-card *ngSwitchCase="'card'" [statsObsGroups]="statsGroups"></app-stat-card>
      <app-stat-card *ngSwitchCase="'reversedCard'" [reversed]="true" [statsObsGroups]="statsGroups"></app-stat-card>
      <app-stat-table
        *ngSwitchCase="'table'"
        [statistics]="statsGroups.statistics"
        [observationDefinition]="obsDefinition"
      ></app-stat-table>
      <app-stat-linechart
        [statsObsGroups]="statsGroups"
        [chartId]="obsDefinition.loinc"
        [fromDate]="fromDate"
        [toDate]="toDate"
        *ngSwitchCase="TypeDisplayStats.lineChart"
      ></app-stat-linechart>

      <app-stat-stacked-barplot-chart
        [statsObsGroups]="statsGroups"
        [chartId]="obsDefinition.loinc"
        *ngSwitchCase="TypeDisplayStats.stackedBarplotChart"
      ></app-stat-stacked-barplot-chart>
    </mat-card>
  </ng-container>
</div>
