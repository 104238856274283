import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IEntitylink } from "src/app/models/entity.interface";
import { DrugsService } from "src/app/providers/drugs.service";

@Component({
  selector: "app-add-drug-intake-dialog",
  templateUrl: "./add-drug-intake-dialog.component.html",
  styleUrls: ["./add-drug-intake-dialog.component.scss"],
})
export class AddDrugIntakeDialogComponent implements OnInit, OnDestroy {
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  public isLoading = false;
  public form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddDrugIntakeDialogComponent>,
    private fb: UntypedFormBuilder,
    private drugService: DrugsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string; drug: IEntitylink; time: string }
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      intakeValidation: [true, Validators.required],
      comment: ["", []],
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public save(): void {
    this.isLoading = true;
    const formValue = this.form.getRawValue();
    this.drugService
      .addDrugIntake(this.data.patientId, this.data.drug, formValue.intakeValidation, formValue.comment, this.data.time)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((notif) => {
        this.isLoading = false;
        this.dialogRef.close(notif);
      });
  }
}
