import { IQuantity } from "./sharedInterfaces";

export interface IStatisticsConfiguration {
  loinc: string;
  statsObsGroups: IStatsObsGroups[];
  statsAlertsGroups: IStatsAlertsGroups[];
}

export interface IStatsGroup {
  type: TypeDisplayStats;
  position: IPosition;
  statistics: IStatistic[];
}

export interface IStatsObsGroups extends IStatsGroup {
  identifier: string; // loinc of a component of a observation
}

export interface IStatsAlertsGroups extends IStatsGroup {
  identifier: string[]; // identifier of rules
  definitionIdentifier: string[]; // identifier of ruleDefinitions
}

export interface IPosition {
  columnStart: number;
  columnEnd: number;
  rowStart?: number;
  rowEnd?: number;
}

export interface IStatistic {
  keyName: string;
  values: IValueStatistic[];
  xLines?: ILines;
  yLines?: ILines;
  baseColor?: string;
}

export interface IValueStatistic {
  valueQuantity: IQuantity;
  effectiveTimingCode?: string;
  date?: Date;
}

export enum TypeDisplayStats {
  card = "card",
  reversedCard = "reversedCard",
  table = "table",
  lineChart = "lineChart",
  stackedBarplotChart = "stackedBarplotChart",
}

export interface ILines {
  type: LineType;
  values: unknown[];
  vitalProfileCode?: string[];
}

export enum LineType {
  NUMBER = "number",
  STRING = "string",
  DATE = "date",
}
