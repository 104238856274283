/* eslint-disable @typescript-eslint/no-explicit-any */
import * as moment from "moment";
import { FileLogger } from "../helpers/fileLogger";
import { DrugSchemaService } from "../providers/drugSchema.service";
import { CycleSchema, Entity } from "./entity.interface";
import { KeyValue } from "./keyValues.model";
import { IEntity, ITiming, SCHEDULE_PERIOD } from "./sharedInterfaces";

export const FREQUENCY_OPTIONS: KeyValue[] = [
  new KeyValue({ key: "forms.fixedFrequency", value: "fixedFrequency" }),
  new KeyValue({ key: "forms.fixedDates", value: "fixedDates" }),
  new KeyValue({ key: "forms.asNecessary", value: "asNecessary" }),
];

export const TIMING_OPTIONS: KeyValue[] = [
  new KeyValue({ key: "forms.moments", value: "moments" }),
  new KeyValue({ key: "forms.fixedHours", value: "fixedHours" }),
];

export const TIMING_CODES = [
  {
    value: IEntity.TIMING_RISING,
    display: "rise",
  },
  {
    value: IEntity.TIMING_MORNING,
    display: "morning",
  },
  {
    value: IEntity.TIMING_NOON,
    display: "noon",
  },
  {
    value: IEntity.TIMING_EVENING,
    display: "evening",
  },
  {
    value: IEntity.TIMING_BED,
    display: "bedtime",
  },
];
/**
 * !!! Important: this code is a simplified version of the one in the mobile-app.
 * If you want to add something to it, check first that it isn't already coded
 * in the mobile-app.
 */
export class TimingData {
  public static fixedFreqOption = FREQUENCY_OPTIONS[0].value;
  public static fixedDaysOption = FREQUENCY_OPTIONS[1].value;
  public static fixedDaysOptionDisplay = FREQUENCY_OPTIONS[1].key;
  public static asNecessaryOption = FREQUENCY_OPTIONS[2].value;
  public static asNecessaryOptionDisplay = FREQUENCY_OPTIONS[2].key;
  public static momentTimingOption = TIMING_OPTIONS[0].value;
  public static fixedHoursTimingOption = TIMING_OPTIONS[1].value;

  public static getFreqOption(frequency: ITiming): any {
    const asNecessary = frequency.asNecessary;
    return asNecessary ? this.asNecessaryOption : frequency.event && frequency.event.length ? this.fixedDaysOption : this.fixedFreqOption;
  }

  public static getTimingOption(frequency: ITiming): any {
    return frequency.timeOfDay && frequency.timeOfDay.length ? this.fixedHoursTimingOption : this.momentTimingOption;
  }

  public static hasTiming(timing: ITiming, freqOption?: string, timingOption?: string): boolean {
    const timingCode = timing.timingCode;
    freqOption = freqOption ? freqOption : this.getFreqOption(timing);
    timingOption = timingOption ? timingOption : this.getTimingOption(timing);
    switch (freqOption) {
      case this.asNecessaryOption:
        return true;
      case this.fixedFreqOption:
      case this.fixedDaysOption:
        switch (timingOption) {
          case this.momentTimingOption:
            return !!timingCode;
          case this.fixedHoursTimingOption:
            return timing.timeOfDay && timing.timeOfDay.length > 0;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  public static getScheduledPeriod(timing: ITiming): SCHEDULE_PERIOD {
    if (!timing || !timing.periodUnits) {
      return SCHEDULE_PERIOD.DAY;
    }

    switch (timing.periodUnits.toLowerCase()) {
      case "min":
        return SCHEDULE_PERIOD.MINUTE;
      case "h":
        return SCHEDULE_PERIOD.HOUR;
      case "d":
        return SCHEDULE_PERIOD.DAY;
      case "w":
      case "wk":
        return SCHEDULE_PERIOD.WEEK;
      case "m":
        return SCHEDULE_PERIOD.MONTH;
      case "y":
        return SCHEDULE_PERIOD.YEAR;
      default:
        return SCHEDULE_PERIOD.DAY;
    }
  }

  public static getIntakesPerDay(timing: ITiming, option?: string): string[] {
    const timingOption = option ? option : TimingData.getTimingOption(timing);
    let intakes: string[] = [];
    if (!TimingData.hasTiming(timing, null, timingOption)) {
      return [];
    }
    if (timingOption === TimingData.momentTimingOption) {
      intakes = this.getMomentsTimingFromCode(timing.timingCode);
    } else if (timingOption === TimingData.fixedHoursTimingOption) {
      intakes = timing.timeOfDay;
    }
    return intakes;
  }

  public static getMomentsTimingFromCode(timingCode: string): string[] {
    const moments: string[] = [];
    Entity.toKeyValues(timingCode).forEach((kv) => {
      if (kv.value) {
        moments.push("mydrugs." + kv.key);
      }
    });
    return moments;
  }

  public static getTimingInstances(timing: ITiming, from?: moment.Moment, to?: moment.Moment): string[] {
    let instances: string[] = [];
    const freqOption = TimingData.getFreqOption(timing);
    const timingOption = TimingData.getTimingOption(timing);
    const hasTiming = TimingData.hasTiming(timing, freqOption, timingOption);
    if (!hasTiming || freqOption === TimingData.asNecessaryOption) {
      return [];
    } else if (freqOption === TimingData.fixedFreqOption) {
      switch (this.getScheduledPeriod(timing)) {
        case SCHEDULE_PERIOD.HOUR: // *** HOUR scheduled ***
          instances = this.getHourlyTimingInstances(timing, from, to);
          break;
        case SCHEDULE_PERIOD.DAY: // *** DAY scheduled ***
          instances = this.getDailyTimingInstances(timing, from, to);
          break;
        case SCHEDULE_PERIOD.WEEK: // *** WEEK scheduled ***
          instances = this.getWeeklyTimingInstances(timing, from, to);
          break;
        case SCHEDULE_PERIOD.MONTH: // *** MONTH scheduled ***
          instances = this.getMonthlyTimingInstances(timing, from, to);
          break;
      }
    } else if (freqOption === TimingData.fixedDaysOption) {
      instances = this.getFixedDaysTimingInstances(timing, from, to);
    } else {
      return [];
    }
    return instances;
  }
  private static getHourlyTimingInstances(_timing: ITiming, _from?: moment.Moment, _to?: moment.Moment): string[] {
    return [];
  }
  private static getDailyTimingInstances(frequency: ITiming, from?: moment.Moment, to?: moment.Moment): string[] {
    let instances: string[] = [];
    try {
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      const startDay = startDate.clone();
      instances = this.computeTimingInstances(startDate, startDay, frequency, "days", endDay, from, to, null);
    } catch (err) {
      FileLogger.error("TimingData", "scheduleDrugNotificationDaily", err);
    }
    return instances;
  }
  private static getWeeklyTimingInstances(frequency: ITiming, from?: moment.Moment, to?: moment.Moment): string[] {
    const instances: string[] = [];
    try {
      // "when" contains week days index (from 1 to 7) in an array (ex: ["1,"3"] )
      const days = JSON.parse(frequency.when) as string[];
      if (!days || days.length === 0) {
        return [];
      } // nothing to do
      // start of current processing
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      // end of current processing date
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each week day
      days.forEach((dayIdx) => {
        const dayIndex = Number(dayIdx);
        const startDay = startDate.clone();
        // set week day
        startDay.isoWeekday(dayIndex);
        instances.push(...this.computeTimingInstances(startDate, startDay, frequency, "weeks", endDay, from, to));
      });
    } catch (err) {
      FileLogger.error("TimingData", "scheduleDrugNotificationWeekly", err);
    }
    return instances;
  }

  private static getMonthlyTimingInstances(frequency: ITiming, from?: moment.Moment, to?: moment.Moment): string[] {
    const instances: string[] = [];
    try {
      // get days (1,2,3,...,31)
      const days = JSON.parse(frequency.when) as number[];
      if (!days || days.length === 0) {
        return [];
      } // nothing to do
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each day in month to schedule
      days.forEach((day) => {
        const dayMonth = Number(day);
        const startDay = startDate.clone();
        // set day of the month (do not bubble up to next month)
        if (dayMonth === 31) {
          startDay.endOf("month").startOf("day");
        } else if (dayMonth > 28 && startDay.daysInMonth() < dayMonth) {
          startDay.endOf("month").startOf("day");
        } else {
          startDay.date(dayMonth);
        }
        instances.push(...this.computeTimingInstances(startDate, startDay, frequency, "months", endDay, from, to, dayMonth));
      });
    } catch (err) {
      FileLogger.error("TimingData", "scheduleDrugNotificationMonthly", err);
    }
    return instances;
  }

  private static computeTimingInstances(
    startDate: any,
    startDay: any,
    frequency: ITiming,
    freqType: string,
    endDay: any,
    from?: moment.Moment,
    _to?: moment.Moment,
    dayMonth?: number
  ) {
    const instances: string[] = [];
    while (startDay.isSameOrBefore(endDay)) {
      // add it only if it is not before "From" date
      // if start date defined in Drug is in middle of current month, we may have to ignore some dates at the beginning of that month
      if (startDay.isSameOrAfter(startDate) && (!from || startDay.isSameOrAfter(from))) {
        instances.push(startDay.format());
      }
      // every X weeks
      startDay.add(frequency.period, freqType);
      if (freqType === "month" && dayMonth) {
        if (dayMonth === 31) {
          startDay.endOf("month").startOf("day");
        }
      }
    }
    return instances;
  }
  private static getFixedDaysTimingInstances(frequency: ITiming, from?: moment.Moment, to?: moment.Moment): string[] {
    const instances: string[] = [];
    try {
      const days = frequency.event;
      if (!days || days.length === 0) {
        return [];
      } // nothing to do
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each day in event
      days.forEach((day) => {
        const startDay = moment(day).startOf("day");
        if (startDay.isSameOrBefore(endDay) && (!from || startDay.isSameOrAfter(from))) {
          instances.push(startDay.format());
        }
      });
    } catch (err) {
      FileLogger.error("TimingData", "scheduleNotificationOnFixedDays", err);
    }
    return instances;
  }

  public static getCycleTimingInstances(cycle: CycleSchema, frequency: ITiming, from?: moment.Moment, to?: moment.Moment): string[] {
    const instances: string[] = [];
    try {
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);

      const actualDayOfCycle = from
        ? DrugSchemaService.getDayOfCycleOnDate(cycle, startDate.toDate())
        : DrugSchemaService.getDayOfCycleOnDate(cycle);
      let isPaused = DrugSchemaService.isCycleInPause(cycle);
      let isPausedAndToday = DrugSchemaService.isCycleInPauseOnDate(cycle, new Date());
      const startDay = startDate.clone();
      let i = actualDayOfCycle;

      while (startDay.isSameOrBefore(endDay)) {
        if ((!isPaused && cycle.cycle[i]) || (isPaused && !isPausedAndToday && cycle.cycle[i])) {
          instances.push(startDay.format());
        }
        if (i === cycle.cycle.length - 1) {
          // end of cycle, start a new one
          i = 0;
          isPaused = false;
        } else {
          i++;
        }
        startDay.add(1, "days");
        if (isPaused && !isPausedAndToday) {
          isPausedAndToday = DrugSchemaService.isCycleInPauseOnDate(cycle, startDay.toDate());
        }
      }
    } catch (err) {
      FileLogger.error("TimingData", "scheduleNotificationForCycle", err);
    }
    return instances;
  }
}
