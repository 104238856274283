<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable" *ngIf="isVisible">
  <mat-card-header class="widget-header">
    <mat-card-title class="custom-widget-title">
      {{ "widget.patientList" | translate }}
      <a mat-icon-button aria-label="icon button with a ? icon" (click)="openPatientsListWidgetHelp()">
        <mat-icon>help_outline</mat-icon>
      </a>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="widget-content">
    <table
      mat-table
      matSort
      matSortDirection="asc"
      matSortActive="name"
      matSortDisableClear
      [dataSource]="dataSource"
      [ngClass]="{ hide: isNoneData }"
    >
      <!-- Patient Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.patient" | translate }}
        </th>
        <td class="noPadding custom-widget-font" *matCellDef="let element">
          <button
            mat-button
            class="normalWeight custom-widget-font"
            [routerLink]="['/patient', { id: encodeToB64(element.caremateIdentifier) }]"
            [state]="{
              caremateIdentifier: element.caremateIdentifier,
              widget: widgetName
            }"
            (click)="recordUseOfWidget()"
          >
            <mat-icon>face</mat-icon>
            {{ element.firstname }} {{ element.name }}
          </button>
        </td>
      </ng-container>

      <!-- Birthday Column -->
      <ng-container matColumnDef="birthDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.birthdate" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.birthdate' | translate }}"
            propertyName="birthDate"
            [dataType]="dataTypeDate"
            [translateFieldKey]="'model.patient.birthdate'"
            [defaultValue]="getFilter('birthDate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td class="custom-widget-font" *matCellDef="let element">
          {{ element.birthDate | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageIndex]="0"
      [length]="patientsCount"
      [pageSize]="10"
      [hidePageSize]="true"
      [ngClass]="{ hide: isNoneData }"
    ></mat-paginator>
    <p class="text-center custom-widget-font" *ngIf="isNoneData">
      {{ "widget.noData" | translate }}
    </p>
  </mat-card-content>
</mat-card>
