import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools";
import { DataSourceBackendParam } from "../models/data-sources-backend-param.interface";
import { Filter } from "../models/filter.interface";
import {
  Contained,
  IQuestionnaire,
  IQuestionnaireHistoryEvent,
  IQuestionnaireParam,
  IQuizListInfo,
  QuestionnaireStatus,
  SPECIFIC_USE,
} from "../models/questionnaire.interface";
import { IQuestionnaireScoring } from "../models/questionnaireScoring.interface";
import { AccessLevel } from "../models/sharedInterfaces";
import { QuestionnaireApiService } from "./api/questionnaires-api.service";
import { ValueSetsApiService } from "./api/valueSets-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class QuestionnairesService {
  constructor(
    private questionnaireApiService: QuestionnaireApiService,
    private valueSetsApiService: ValueSetsApiService,
    private userService: UserService,
    private router: Router
  ) {}

  /**
   * Get all the questionnaires linked to a careplan
   * @param careplanId (string) the careplan's id
   * @param language (string) the language
   * @param filterOnlyPractitioner we only want the "practitioner" questionnaires
   * @param draft (boolean)
   * @param date (string)
   * @returns an observable with the list of questionnaires found
   */
  public getQuestionnairesByCareplan(
    careplanId: string,
    language: string,
    filterOnlyPractitioner?: boolean,
    draft = false,
    date?: string
  ): Observable<IQuestionnaire[]> {
    return this.questionnaireApiService.getByCareplan(careplanId, language, filterOnlyPractitioner, draft, date);
  }

  /**
   * Get one questionnaire using its id, its lang version and whether we want the draft or not
   * @param questId (string) the questionnaire's id
   * @param language (string) the language
   * @param draft (boolean) if we want the latest version and it's okay if it is a draft
   * @param latest (boolean) if we want the latest active version only and nothing else if it does not exists
   * @param date (string) if we want a specific version, we identifiy it by it's date
   * @returns an promise with the questionnaire found
   */
  public getQuestionnaire(questId: string, language: string, draft = false, latest = true, date?: string): Promise<IQuestionnaire> {
    return this.questionnaireApiService.getQuestionnaire(questId, language, draft, latest, date).toPromise();
  }

  /**
   * Get all questionnaireScoring using the questionnaire id
   * @param questId (string) the questionnaire's id
   * @param draft (boolean) draft version or not
   * @param date (string)
   * @returns an promise with the questionnaire found
   */
  public getQuestionnaireScoringsFromApi(questId: string, draft = false, date?: string): Promise<IQuestionnaireScoring[]> {
    return this.questionnaireApiService.getQuestionnaireScorings(questId, draft, date).toPromise();
  }

  /**
   * Create a draft "classic" questionnaire, new or for an existing version of a questionnaire.
   * Also create the associated scoring draft.
   * And add the questionnaire to the link2careplan when it's a new one.
   * @param careplansIds (string[]) list of careplans this questionnaire is part of, only needed for totally new questionnaires
   * @param questionnaire  (IQuestionnaire) the new draft questionnaire
   * @param isNewQuestionnaire (boolean) whether or not this is a totally new questionnaire
   * @param linkType (QUESTIONNAIRE_LINK_TYPE) how the questionnaire is linked to a careplan
   * @param scorings (IQuestionnaireScoring[]) the potential scorings
   * @returns the created draft questionnaire
   */
  public async createQuestionnaireDraft(questionnaire: IQuestionnaire, scorings?: IQuestionnaireScoring[]): Promise<IQuestionnaire> {
    try {
      if (!questionnaire.identifier || !questionnaire.identifier.length || !questionnaire.identifier[0].value) {
        FileLogger.error("QuestionnaireService", "Missing questionnaire identifier", null, "none");
        return null;
      }
      const newDraftQuestionnaire = await this.questionnaireApiService.createQuestionnaireDraft(questionnaire).toPromise<IQuestionnaire>();

      // And we save the scorings if there's some
      if (scorings?.length) {
        try {
          await this.questionnaireApiService.createQuestionnaireScoringsDrafts(scorings).toPromise<IQuestionnaireScoring[]>();
        } catch (sErr) {
          FileLogger.error("QuestionnairesService", "Error while creating the draft scorings: ", sErr);
          return null;
        }
      }
      return newDraftQuestionnaire;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while creating questionnaire : ", err);
      return null;
    }
  }

  /**
   * Update a questionnaire draft (beware, it does not update its links to careplans)
   * @param questionnaire (IQuestionnaire) the questionnaire we want to update
   * @returns the updated questionnaire
   */
  public async updateQuestionnaireDraft(
    questionnaire: IQuestionnaire,
    newScorings: IQuestionnaireScoring[],
    oldScorings: IQuestionnaireScoring[]
  ): Promise<IQuestionnaire> {
    try {
      const scoringToDelete = [];
      const scoringToEdit = [];
      const scoringToAdd = [];
      oldScorings?.forEach((os) => {
        const index = newScorings.findIndex((ns) => ns.scoringId === os.scoringId);
        if (index === -1) {
          scoringToDelete.push(os.scoringId);
        } else {
          if (
            os.scoringId !== newScorings[index].scoringId ||
            os.status !== newScorings[index].status ||
            os.targets[0].reference !== newScorings[index].targets[0].reference ||
            os.identifier.value !== newScorings[index].identifier.value ||
            os.identifier.label !== newScorings[index].identifier.label ||
            os.formula !== newScorings[index].formula ||
            !Tools.isEqual(os.interpretations, newScorings[index].interpretations) ||
            !Tools.isEqual(os.valueSet, newScorings[index].valueSet)
          ) {
            scoringToEdit.push(newScorings[index]);
          }
        }
      });
      newScorings?.forEach((ns) => {
        if (!oldScorings || oldScorings.findIndex((os) => os.scoringId === ns.scoringId) === -1) {
          scoringToAdd.push(ns);
        }
      });
      if (scoringToDelete.length) {
        await this.questionnaireApiService.deleteQuestionnaireScorings(scoringToDelete).toPromise<boolean>();
      }
      if (scoringToEdit.length) {
        await this.questionnaireApiService.updateQuestionnaireScorings(scoringToEdit).toPromise<IQuestionnaireScoring[]>();
      }
      if (scoringToAdd.length) {
        await this.questionnaireApiService.createQuestionnaireScoringsDrafts(scoringToAdd).toPromise<IQuestionnaireScoring[]>();
      }
      return await this.questionnaireApiService.updateQuestionnaireDraft(questionnaire).toPromise<IQuestionnaire>();
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while updating questionnaire : ", err);
      return null;
    }
  }

  /**
   * Delete a questionnaire
   * @param questionnaire (IQuestionnaire) the questionnaire we want to delete
   * @returns a promise indicating whether or not the operation was successful
   */
  public async deleteQuestionnaire(questionnaire: IQuestionnaire): Promise<boolean> {
    if (!questionnaire._id) {
      return false;
    }
    try {
      await this.questionnaireApiService.deleteQuestionnaire(questionnaire).toPromise();
      return true;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while deleting questionnaire : ", err);
      return false;
    }
  }

  /**
   * Set a draft questionnaire status to "active"
   * @param idQuestionnaire (string)
   * @param version (string)
   * @returns the updated questionnaire
   */
  public async publishQuestionnaire(questionnaire: IQuestionnaire): Promise<IQuestionnaire> {
    try {
      return await this.questionnaireApiService
        .publishQuestionnaire(questionnaire.identifier[0].value, questionnaire.version)
        .toPromise<IQuestionnaire>();
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while publishing questionnaire : ", err);
      return null;
    }
  }

  public async getQuestionnaireHistory(identifier: string, lang: string): Promise<IQuestionnaireHistoryEvent[]> {
    try {
      return await this.questionnaireApiService.getQuestionnaireHistory(identifier, lang).toPromise<IQuestionnaireHistoryEvent[]>();
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while getting questionnaire's history : ", err);
      return [];
    }
  }

  // --------------------------------------------------------------------------
  // ---------------------------- VALUESET ------------------------------------

  /**
   * Get valueSets templates (answers' options) based on language and services
   * @param lang the language of the valueSets we want
   * @param serviceIds the serviceIds in which the valueSets are available
   * @param orgsIds the organizations ids in which the valueSets are available (for valuesets avaiblable organization wide)
   */
  public async getValueSets(lang: string, serviceIds: string[], orgsIds: string[]): Promise<Contained[]> {
    try {
      const result = await this.valueSetsApiService.getValueSets(lang, serviceIds, orgsIds).toPromise();
      return result;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while getting valueSets templates : ", err);
      return [];
    }
  }

  /**
   * Create a new valueSet template (answers' options)
   * @param valueSet the new valueSet
   */
  public async createValueSet(valueSet: Contained): Promise<boolean> {
    if (!valueSet.version || !valueSet.useContext || !valueSet.useContext.length || !valueSet.name) {
      FileLogger.error("QuestionnairesService", "Missing data for creating valueSet template");
      return false;
    }
    try {
      await this.valueSetsApiService.createValueSet(valueSet).toPromise<Contained>();
      return true;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while creating valueSet template : ", err);
      return false;
    }
  }

  /**
   * Update an existing valueSet template
   * @param valueSet the updated valueSet
   */
  public async updateValueSet(valueSet: Contained): Promise<boolean> {
    if (!valueSet.version || !valueSet.useContext || !valueSet.useContext.length || !valueSet.name || !valueSet._id) {
      return false;
    }
    try {
      await this.valueSetsApiService.updateValueSet(valueSet).toPromise<Contained>();
      return true;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while updating valueSe template : ", err);
      return false;
    }
  }

  /**
   * Delete an existing valueSet template
   * @param valueSet the template we want to delete
   */
  public async deleteValueSet(valueSet: Contained): Promise<boolean> {
    if (!valueSet.version || !valueSet.useContext || !valueSet.useContext.length || !valueSet.name || !valueSet._id) {
      return false;
    }
    try {
      await this.valueSetsApiService.deleteValueSet(valueSet).toPromise<Contained>();
      return true;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while deleting a valueSet template : ", err);
      return false;
    }
  }

  /**
   * Get all the units' templates value sets
   * ex: 'cm - in', 'kg - lb'...
   * @param lang the language of the valueSets we want
   * @param serviceIds the serviceIds in which the valueSets are available
   * @param orgsIds the organizations ids in which the valueSets are available (for valuesets avaiblable organization wide)
   */
  public async getUnitsTemplate(lang: string, serviceIds: string[], orgsIds: string[]): Promise<Contained[]> {
    try {
      const result = await this.valueSetsApiService.getValueSetsUnits(lang, serviceIds, orgsIds).toPromise<Contained[]>();
      return result;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while getting units templates valueSets : ", err);
      return [];
    }
  }

  public generateValueSet(cid: string, startCode: number, translatedDefaultDisplay: string): Contained {
    return {
      resourceType: "ValueSet",
      idSet: cid,
      id: cid,
      name: "",
      description: "",
      status: "",
      compose: {
        include: [
          {
            system: "",
            concept: [
              {
                code: String(startCode),
                display: translatedDefaultDisplay + " 1",
              },
              {
                code: String(startCode + 1),
                display: translatedDefaultDisplay + " 2",
              },
              {
                code: String(startCode + 2),
                display: translatedDefaultDisplay + " 3",
              },
            ],
          },
        ],
      },
    };
  }

  // --------------------------------------------------------------------------
  // ------------------------------ PARAMS ------------------------------------

  /**
   * Get a questionnaire params based on its identifier
   * @param questId the questionnaire id we want the params for
   */
  public async getParams(questId: string): Promise<IQuestionnaireParam> {
    try {
      const result = await this.questionnaireApiService.getParams(questId).toPromise();
      return result;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while getting questionnaire params : ", err);
      return null;
    }
  }

  /**
   * Create a new questionnaire params
   * @param params the params
   */
  public async createParams(params: IQuestionnaireParam): Promise<IQuestionnaireParam> {
    if (!params.identifier.system) {
      FileLogger.error("QuestionnairesService", "Missing data for creating questionnaire params");
      return null;
    }
    try {
      return await this.questionnaireApiService.createParams(params).toPromise<IQuestionnaireParam>();
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while creating questionnaire params : ", err);
      return null;
    }
  }

  /**
   * Update an existing questionnaire params
   * @param params the updated params
   */
  public async updateParams(params: IQuestionnaireParam): Promise<boolean> {
    if (!params.identifier.system || !params._id) {
      return false;
    }
    try {
      await this.questionnaireApiService.updateParams(params).toPromise<IQuestionnaireParam>();
      return true;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while updating questionnaire params : ", err);
      return false;
    }
  }

  /**
   * Delete an existing questionnaire params
   * @param paramsId the params id
   */
  public async deleteParams(paramsId: string): Promise<boolean> {
    if (!paramsId) {
      return false;
    }
    try {
      await this.questionnaireApiService.deleteParams(paramsId).toPromise<IQuestionnaireParam>();
      return true;
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while deleting a questionnaire params : ", err);
      return false;
    }
  }

  // --------------------------------------------------------------------------
  // --------------------------- QUIZ & CONSENT -------------------------------

  /**
   * Create a new quiz draft
   * @param quiz  (IQuestionnaire) the new quiz
   * @returns the created specific questionnaire
   */
  public async createSpecificQuestionnaireDraft(sQuestionnaire: IQuestionnaire): Promise<IQuestionnaire> {
    if (!sQuestionnaire.identifier || !sQuestionnaire.identifier.length || !sQuestionnaire.identifier[0].value) {
      return null;
    }
    try {
      return await this.questionnaireApiService.createQuestionnaireDraft(sQuestionnaire).toPromise<IQuestionnaire>();
    } catch (err) {
      FileLogger.error("QuestionnairesService", "Error while creating quiz : ", err);
      return null;
    }
  }
  /**
   * Get all quizzes
   */
  public findSpecificQuestionnaire(params: DataSourceBackendParam): Observable<IQuizListInfo[]> {
    return this.questionnaireApiService.getSpecificQuestionnaireInfos(params);
  }

  /**
   *  Get the number of specificQuestionnaire
   */
  public getSpecificQuestionnaireInfosCount(specificUse: SPECIFIC_USE, search?: string, filters?: Filter[]): Observable<{ count: number }> {
    return this.questionnaireApiService.getSpecificQuestionnaireInfosCount(specificUse, search, filters);
  }

  /**
   * Get the specificQuestionnaire
   */
  public getSpecificQuestionnaireListWithLang(lang: string, specificUse: SPECIFIC_USE): Observable<IQuestionnaire[]> {
    return this.questionnaireApiService.getSpecificQuestionnaires(specificUse, lang);
  }

  public getSpecificQuestionnaireList(specificUse: SPECIFIC_USE): Promise<IQuestionnaire[]> {
    return this.questionnaireApiService.getSpecificQuestionnaires(specificUse).toPromise();
  }

  public linkQuestionnaireToMedia(
    questionnaireId: string,
    questionnaireVersion: string,
    knowledgeId: string,
    mediaId: string
  ): Promise<IQuestionnaire> {
    return this.questionnaireApiService.linkQuestionnaireToMedia(questionnaireId, questionnaireVersion, knowledgeId, mediaId).toPromise();
  }

  // --------------------------------------------------------------------------
  // ----------------- QUESTIONNAIRES FROM ACTIVITIES -------------------------

  /**
   * Backend Table : Count all questionnairesListInfo accessible by some services or organizations.
   */
  public getQuestionnairesCount(
    search?: string,
    filters?: Filter[],
    orgsAndServicesRefs?: string[]
  ): Observable<{
    count: number;
  }> {
    if (!this.userService.isAuthorizedSync(null, "dashboard/userQuestionnairesListInfos", "GET")) {
      FileLogger.warn("questionnaires.service.ts", "Not Authorized to getQuestionnairesCount");
      return of({ count: 0 });
    }
    return this.questionnaireApiService.getQuestionnairesCount(search, filters, orgsAndServicesRefs);
  }

  public async editQuestionnaire(
    id: string,
    version: string,
    hasDraft: boolean,
    accessLevel: AccessLevel,
    previousLocation?: string
  ): Promise<void> {
    let questionnaire: IQuestionnaire;
    let questionnaireScorings: IQuestionnaireScoring[];
    if (hasDraft) {
      questionnaire = await this.getQuestDraftFirstOrActive(id, version);
      questionnaireScorings = await this.getQuestionnaireScoringsDraft(id);
    } else {
      questionnaire = await this.getQuestDraftFirstOrActive(id, version);
      questionnaire.status = QuestionnaireStatus.DRAFT;
      questionnaire.publisher = "";
      questionnaire.date = null;
      questionnaire._id = undefined;
      questionnaireScorings = await this.getQuestionnaireScorings(id);
      questionnaireScorings?.forEach((s) => {
        s.status = QuestionnaireStatus.DRAFT;
        s.questionnairePublicationDate = null;
        s._id = undefined;
      });
    }
    this.router.navigate(["/questionnaireEditor"], {
      state: {
        questionnaire,
        scorings: questionnaireScorings,
        visualization: accessLevel < AccessLevel.WRITE,
        userHighestAccessLevel: accessLevel,
        previousLocation: previousLocation,
      },
    });
  }

  /**
   *  Get a questionnaire from its questionnaire list infos. It will try to get the draft version if
   * it exists, else, it will return the active version
   * @param q the questionnaire infos
   * @returns
   */
  public async getQuestDraftFirstOrActive(id: string, version: string): Promise<IQuestionnaire> {
    try {
      const questionnaire = await this.getQuestionnaire(id, version, true);
      return questionnaire;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while getQuestDraftFirstOrActive: ", err);
      throw err;
    }
  }

  public async getQuestionnaireScoringsDraft(id: string): Promise<IQuestionnaireScoring[]> {
    try {
      const scorings = await this.getQuestionnaireScoringsFromApi(id, true);
      return scorings;
    } catch (err) {
      FileLogger.warn("FhirQuestionnaireListPageComponent", "Error while get questionnairescoringsDraft: ", err);
    }
  }
  public async getQuestionnaireScorings(id: string): Promise<IQuestionnaireScoring[]> {
    try {
      const scorings = await this.getQuestionnaireScoringsFromApi(id);
      return scorings;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while get questionnairescorings: ", err);
    }
  }
}
