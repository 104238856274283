import { Component, Input } from "@angular/core";
import { Coding } from "src/app/models/coding.interface";

@Component({
  selector: "app-qr-values-possibles",
  templateUrl: "./qr-values-possibles.component.html",
  styleUrls: ["./qr-values-possibles.component.scss"],
})
export class QRValuePossiblesComponent {
  @Input() public valuesPossibles?: Coding[];
  @Input() public answer?: string;

  public isAnswer(code: string): boolean {
    if (!this.answer || !this.answer.length) {
      return false;
    }
    return this.answer === code;
  }
}
