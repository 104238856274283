<div class="item-selector">
  <button mat-stroked-button (click)="toggleList()">
    <mat-icon>{{ logo }}</mat-icon>
  </button>
  <div
    class="item-list mat-elevation-z2"
    [ngClass]="{
      open: listDisplayed,
      left: position === 'left',
      right: position === 'right'
    }"
  >
    <mat-checkbox *ngFor="let item of items" [checked]="item.checked" (change)="itemChange($event, item)">
      <span *ngIf="translate" class="item-selector-checkbox-label">{{ item.display | translate }}</span>
      <span *ngIf="!translate" class="item-selector-checkbox-label">{{ item.display }}</span>
    </mat-checkbox>
  </div>
</div>
