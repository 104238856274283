import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TimingEditorComponent } from "src/app/components/timing-editor/timing-editor.component";
import { FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IDrugInfo } from "src/app/models/drugInfo.interface";
import {
  ActionStatusEntity,
  CycleSchema,
  EntityDrug,
  Entitylink,
  IEntitylink,
  PARENT_TYPE,
  StatusEntity,
} from "src/app/models/entity.interface";
import { KeyValue } from "src/app/models/keyValues.model";
import { PatientUser } from "src/app/models/patient.interface";
import { IStepwiseDrug } from "src/app/models/stepwiseDrug.interface";
import { DrugsService } from "src/app/providers/drugs.service";
import { SessionService } from "src/app/providers/session.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { DrugCommentComponent } from "./drug-comment/drug-comment.component";
import { DrugCycleComponent } from "./drug-cycle/drug-cycle.component";
import { DrugServerSideSearchComponent } from "./drug-server-side-search-component/drug-server-side-search.component";

@Component({
  selector: "app-drug-add",
  templateUrl: "./drug-add.component.html",
  styleUrls: ["./drug-add.component.scss"],
})
export class DrugAddComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild(DrugServerSideSearchComponent)
  search: DrugServerSideSearchComponent;
  @ViewChild(TimingEditorComponent) timing: TimingEditorComponent;
  @ViewChild(DrugCycleComponent) cycle: DrugCycleComponent;
  @ViewChild(DrugCommentComponent) comment: DrugCommentComponent;
  public availablePrescriptors: KeyValue[] = this.formsData.PRESCIPTORS;
  public drug: IEntitylink;
  public hasValidate = false;
  public drugForm = this.fb.group({
    allowUserEdit: [false, Validators.required],
    name: [undefined, Validators.required],
    prescriptor: [this.availablePrescriptors[0], Validators.required],
  });
  public isLoading = true;
  public notUpdateName = true;
  public drugName = "";
  public hasCycle = false;
  public hasStepwise = false;
  public isInputValidate = false;
  public PARENT_TYPE = PARENT_TYPE;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<DrugAddComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientUser: PatientUser;
      drug: IEntitylink;
      mode: FORMS_MODE;
    },
    private fb: UntypedFormBuilder,
    private formsData: FormsData,
    private sessionService: SessionService,
    private drugService: DrugsService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private cdref: ChangeDetectorRef,
    private statService: UserStatisticsService
  ) {}

  ngOnInit(): void {
    this.initDrug();
    this.drugForm
      .get("name")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((v: IDrugInfo) => (this.drugName = v?.[this.sessionService.userLang]?.fullName));
    this.isLoading = false;
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initDrug(): void {
    if (this.data.mode === FORMS_MODE.CREATE) {
      const idPatient = this.data?.patientUser?.user?.caremateIdentifier;
      const idPractitioner = this.sessionService.account.caremateIdentifier;
      this.drug = Entitylink.createDrug(idPatient, idPractitioner, PARENT_TYPE.DRUG);
    } else if (this.data.mode === FORMS_MODE.UPDATE) {
      this.drug = this.data.drug;
      const drugData = this.drug.entityData as EntityDrug;
      this.hasCycle = drugData.cycle ? true : false;
      this.hasStepwise = drugData.stepwiseSchema ? true : false;
      this.drugName = drugData.name;
      const prescriptor = this.availablePrescriptors.find((v) => v.value === drugData.performer.reference);
      const lockedForUser = this.drug.entityStatus?.includes(StatusEntity.LOCKED) ? true : false;
      this.drugForm = this.fb.group({
        allowUserEdit: [
          {
            value: !lockedForUser,
            disabled: this.drug.parentType === PARENT_TYPE.ACTIVITY_DRUG,
          },
          Validators.required,
        ],
        name: [this.drugInfo(drugData), Validators.required],
        prescriptor: [prescriptor, Validators.required],
        comment: [drugData.comment],
      });
    }
  }

  private drugInfo(drug: EntityDrug): IDrugInfo {
    const source = drug.source;
    let country: string;
    let dbName: string;
    if (Tools.isDefined(source)) {
      const split = source.split("/");
      country = split[0];
      dbName = split[1];
    }
    return {
      code: undefined,
      cnk: drug.reference,
      ctiExtended: undefined,
      officialName: drug.name,
      fr: {
        fullName: drug.name,
      },
      nl: {
        fullName: drug.name,
      },
      en: {
        fullName: drug.name,
      },
      de: {
        fullName: drug.name,
      },
      country,
      dbName,
    };
  }

  public get isCreation(): boolean {
    return this.data.mode === FORMS_MODE.CREATE;
  }

  public drugChanged(newDrug: IDrugInfo): void {
    if (this.cycle) {
      this.cycle.drugChanged(newDrug);
    }
  }

  public cycleChanged(cycle: CycleSchema): void {
    if (cycle) {
      this.hasCycle = true;
    } else {
      this.hasCycle = false;
    }
  }

  public stepwiseDrugChanged(stepwise: IStepwiseDrug): void {
    this.hasStepwise = stepwise ? true : false;
  }

  public save(close: boolean): void {
    this.hasValidate = true;
    const frequencyValid = this.timing.save();
    const cycleValid = this.cycle ? this.cycle.save() : true;
    const searchValid = this.drug.parentType !== PARENT_TYPE.ACTIVITY_DRUG ? this.search.drugServerSideFilteringCtrl.valid : true;
    this.comment.save();
    const formIsValid = this.drugForm.valid && frequencyValid && cycleValid && searchValid;
    if (!formIsValid) {
      // mark child component formControl as touched in order to show error state
      // using ? because the drug search bar isn't always visible
      this.search?.drugServerSideFilteringCtrl?.markAsTouched();
      return;
    }
    const entityDrug = this.drug.entityData as EntityDrug;
    const drugInfo = this.drugForm.get("name").value as IDrugInfo;
    entityDrug.name = drugInfo?.[this.sessionService.userLang]?.fullName;

    entityDrug.reference = drugInfo?.cnk;
    entityDrug.source =
      Tools.isDefined(drugInfo?.country) && Tools.isDefined(drugInfo?.dbName) ? `${drugInfo?.country}/${drugInfo?.dbName}` : null;

    const prescriptor = this.drugForm.get("prescriptor").value as KeyValue;
    entityDrug.performer = {
      display: prescriptor.key,
      reference: prescriptor.value,
    };
    this.drug.actionStatus = ActionStatusEntity.CREATED;
    if (!this.drug.entityStatus) {
      this.drug.entityStatus = [];
    }
    const i = this.drug.entityStatus.findIndex((s) => s === StatusEntity.LOCKED);
    if (this.drugForm.get("allowUserEdit").value && i > -1) {
      this.drug.entityStatus.splice(i, 1);
    } else if (!this.drugForm.get("allowUserEdit").value && i === -1) {
      this.drug.entityStatus.push(StatusEntity.LOCKED);
    }
    if (entityDrug.cycle) {
      entityDrug.cycle.startDate = new Date(entityDrug.frequency.boundsPeriod.start);
    }

    this.drugService
      .save(this.drug)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.snackBar.open(this.translateService.instant("common.success"), "ok", { duration: 3000 });
        this.sessionService.needRefreshDrugsData();
        this.statService.createStatEvent("Drug with stepwise schema created : " + this.drug.entityData.name);
        if (close) {
          this.dialogRef.close();
        } else {
          this.hasValidate = false;
          this.cleanForm(prescriptor);
          this.initDrug();
        }
      });
  }

  private cleanForm(prescrptior?: KeyValue): void {
    this.drugForm = this.fb.group({
      allowUserEdit: [false, Validators.required],
      name: [undefined, Validators.required],
      prescriptor: [prescrptior ? prescrptior : this.availablePrescriptors[0], Validators.required],
      comment: [],
    });
    this.search?.drugServerSideFilteringCtrl.setValue(undefined);
  }
}
