import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { TimingEditorComponent } from "src/app/components/timing-editor/timing-editor.component";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { Careplan } from "src/app/models/careplan.model";
import { ACTION_TARGET, Activity, IAddresses, ICareplan, IOrder } from "src/app/models/careplans.interface";
import { ICommunication, STATUS_CODE } from "src/app/models/communications.interface";
import { CycleSchema, EntityDrug } from "src/app/models/entity.interface";
import { KeyValue } from "src/app/models/keyValues.model";
import { PatientUser } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { IEntity, ITiming } from "src/app/models/sharedInterfaces";
import { IStepwiseDrug } from "src/app/models/stepwiseDrug.interface";
import { CareplansService } from "src/app/providers/careplans.service";
import { DrugsService } from "src/app/providers/drugs.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { CommunicationOverviewComponent } from "../communication-overview/communication-overview.component";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../confirmation-dialog/confirmation-dialog.component";
import { EditAnonymousPatientComponent } from "../forms/edit-anonymous-patient/edit-anonymous-patient.component";
import { IGroup } from "../patient-careplans/patient-careplans.component";
import { DrugCommentComponent } from "../patient-drugs/drug-add/drug-comment/drug-comment.component";
import { DrugCycleComponent } from "../patient-drugs/drug-add/drug-cycle/drug-cycle.component";

@Pipe({ name: "isActivityADrug" })
export class IsActivityADrugPipe implements PipeTransform {
  constructor(private careplanComponent: CareplanComponent) {}
  transform(activity: Activity): boolean {
    return this.careplanComponent.isActivityADrug(activity);
  }
}
@Component({
  selector: "app-careplan",
  templateUrl: "./careplan.component.html",
  styleUrls: ["./careplan.component.scss"],
})
export class CareplanComponent implements OnInit, OnDestroy {
  @Input() patient: PatientUser;
  @Input() careplan: ICareplan;
  @Input() careplans: IGroup[];
  @Input() createNewCareplan = true;
  @Input() includeBirthdateAndGenderForm = true;
  @Input() registration: boolean;
  @Input() healthcareService: Reference;
  @Output() saved = new EventEmitter<void>();

  @ViewChild(EditAnonymousPatientComponent)
  initPatientComp: EditAnonymousPatientComponent;
  @ViewChildren(TimingEditorComponent)
  drugsTimings: QueryList<TimingEditorComponent>;
  @ViewChildren(DrugCycleComponent)
  drugCycleComponent: QueryList<DrugCycleComponent>;
  @ViewChildren(DrugCommentComponent)
  drugCommentComponent: QueryList<DrugCommentComponent>;

  private careplanDefinitions: ICareplan[] = [];
  public pageLoaded = false;
  public careplanOriginalTemplates: ICareplan[];
  public initializedCareplanTemplates: ICareplan[];
  public templateSelectedIndex: number;
  public protocols: { [id: string]: Array<Reference> } = {};
  public noCp: boolean;
  public noEndDate = false;
  public authorizedToEdit = true;
  public departments: Reference[];
  public selectedDepartment: Reference = undefined;
  public timingCodes = ["rise", "morning", "noon", "evening", "bedtime"];
  public PeriodUnits = {
    d: "days",
    w: "weeks",
    M: "months",
    y: "years",
  };
  public quantities = ["1/4", "1/3", "1/2", "2/3", "3/4", "1", "2", "3", "1 + 1/4", "1 + 1/3", "1 + 1/2", "1 + 2/3", "1 + 3/4"];
  public drugCycles: { [id: string]: CycleSchema } = {};
  public drugStepwises: { [id: string]: IStepwiseDrug } = {};
  public isLoading = false;
  public isInit = false;
  public isAllServices = false;
  public hasCycle = false;
  public hasStepwise = false;
  public availablePeriodUnits: KeyValue[] = this.formsData.PERIOD_UNITS;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private cpTranslationSub$: Subscription;

  constructor(
    private careplansService: CareplansService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private userService: UserService,
    private drugService: DrugsService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private formsData: FormsData
  ) {}

  ngOnInit(): void {
    const patientOrgRef = this.patient?.patient?.managingOrganization.reference;
    const servicesFromPatientOrg = this.userService.allServices.filter((s) => s.providedBy.reference === patientOrgRef);
    this.departments = servicesFromPatientOrg.map((s) => s.asReference);

    if (this.createNewCareplan) {
      if (this.sessionService.currentService?.display === this.sessionService.allsOption) {
        this.isAllServices = true;
        if (this.registration) {
          this.selectedDepartment = this.healthcareService;
        } else {
          this.selectedDepartment = this.departments[0];
        }
      } else if (this.departments.find((s) => s.reference === this.sessionService.currentService.reference)) {
        this.selectedDepartment = this.sessionService.currentService;
      } else {
        this.selectedDepartment = this.departments[0];
        // Alert the user that he was trying to assign a careplan from an organization
        // in which the patient does not belong
        const trans = this.translateService.instant("careplan.selectCorrectService") + " " + this.selectedDepartment.display;
        this.snackBar.open(trans, "ok", { duration: 15000 });
      }
    } else {
      this.isAllServices = false;
      // Get the careplan's service
      const currentCareplanHealthcareservice = this.careplan.identifier.find((identifier) => identifier.system === "organization")?.use;
      // check if the user has access to the careplan's service:
      const careplanService = this.userService.allServices.find((hs) => hs.serviceRef === currentCareplanHealthcareservice)?.asReference;
      if (careplanService) {
        this.authorizedToEdit = true;
        this.selectedDepartment = careplanService;
      } else {
        this.authorizedToEdit = false;
        this.selectedDepartment = {
          reference: currentCareplanHealthcareservice,
          display: "",
        };
      }
      // Just in case:
      if (this.sessionService.isAdmin()) {
        this.authorizedToEdit = true;
      }
    }

    // pre-complete the starting date
    if (this.careplan && !this.careplan.period.start) {
      this.careplan.period.start = moment().toISOString();
    }

    if (!this.careplans) {
      this.careplans = [];
    }

    this.setupCareplanTranslations(this.selectedDepartment.reference);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private async getCareplanDrugs(careplan: ICareplan) {
    const promises: Promise<void>[] = [];
    const defaultTiming: ITiming = {
      boundsPeriod: {
        start: moment().format(),
        end: moment().add(1, "months").format(),
      },
      count: null,
      frequency: 1,
      period: 1,
      periodUnits: "d",
      when: "",
      timingCode: "", // M=morning, N=noon, E=evening
    };
    for (const activity of careplan.activity) {
      if (
        this.isActivityADrug(activity) &&
        activity.detail.status === "active" &&
        activity.detail.productReference &&
        activity.detail.productReference.display
      ) {
        if (!activity.detail.scheduledTiming) {
          activity.detail.scheduledTiming = { repeat: defaultTiming };
        }
        const p = this.drugService
          .getOne(activity._id)
          .then((drug) => {
            if (drug) {
              const entityDrug = drug.entityData as EntityDrug;
              activity.detail.scheduledTiming.repeat = entityDrug.frequency;
              if (entityDrug.cycle) {
                this.drugCycles[activity.reference.reference] = entityDrug.cycle;
                activity.detail.cycle = entityDrug.cycle;
              } else {
                delete activity.detail.cycle;
              }
              if (entityDrug.stepwiseSchema) {
                this.drugStepwises[activity.reference.reference] = entityDrug.stepwiseSchema;
                activity.detail.stepwise = entityDrug.stepwiseSchema;
              } else {
                delete activity.detail.stepwise;
              }
              if (entityDrug.comment) {
                activity.detail.comment = entityDrug.comment;
              } else {
                delete activity.detail.comment;
              }
              this.hasCycle = this.drugCycles[activity.reference.reference] ? true : false;
              this.hasStepwise = this.drugStepwises[activity.reference.reference] ? true : false;
            } else {
              FileLogger.warn("CareplanComponent", "No drug found for activity: ", activity.reference);
            }
          })
          .catch((err) => {
            FileLogger.error("CareplanComponent", "Error while getting careplan's drug", err);
          })
          .finally(() => {
            if (!activity.detail.scheduledTiming.repeat) {
              activity.detail.scheduledTiming.repeat = defaultTiming;
            }
          });
        promises.push(p);
      }
    }
    return await Promise.all(promises);
  }

  private setupCareplanTranslations(serviceRef: string) {
    this.cpTranslationSub$?.unsubscribe();
    if (!this.createNewCareplan && !this.careplan.templatePublicationDate) {
      FileLogger.error("setupCareplanTranslations", "this patient careplan has no templatePublicationDate", "none");
    }
    this.cpTranslationSub$ = (
      this.createNewCareplan
        ? this.careplansService.getCareplanTemplates(serviceRef, this.translateService.currentLang)
        : this.careplan.templatePublicationDate
        ? this.careplansService
            .getCareplanTemplateByPublicationDate(this.careplan.support[0].reference, this.careplan.templatePublicationDate)
            .pipe(map((c) => (c ? [c] : [])))
        : // if we don't have a templatePublicationDate, then we take the active versions
          this.careplansService.getCareplanTemplates(serviceRef, this.translateService.currentLang)
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((careplanDefs) => {
        if (careplanDefs.length === 0 && this.careplan) {
          FileLogger.error(
            "setupCareplanTranslations",
            `no careplantemplate found (${this.careplan.support[0].reference} - ${this.careplan.templatePublicationDate})`,
            "none"
          );
        }
        this.careplanDefinitions = careplanDefs;
        this.setupTemplates();
      });
  }

  public updateDepartment(event: MatSelectChange): void {
    this.selectedDepartment = event.value;
    this.setupCareplanTranslations(this.selectedDepartment.reference);
  }

  private setupTemplates() {
    this.careplanOriginalTemplates = Tools.clone(this.careplanDefinitions);
    if (this.createNewCareplan) {
      this.initializedCareplanTemplates = this.initializeCareplanTemplate(this.careplanDefinitions);
      if (this.initializedCareplanTemplates.length > 0) {
        this.noCp = false;
        this.templateSelectedIndex = 0;
        this.onTemplateSelected(this.templateSelectedIndex);
      } else {
        this.careplan = undefined;
        this.noCp = true;
      }
      this.noEndDate = Careplan.hasNoEndDate(this.careplan);
      this.pageLoaded = true;
    } else {
      this.getCareplanDrugs(this.careplan).then(() => {
        this.reorderCareplanSubActivities();
        this.noEndDate = Careplan.hasNoEndDate(this.careplan);
        this.pageLoaded = true;
        this.setupIsDate();
      });
    }
    this.buildProtocols();
  }

  /**
   * build list of protocols (drugs)
   */
  private buildProtocols() {
    this.protocols = {}; // reset content
    if (this.careplan) {
      let currentCareplan = this.createNewCareplan
        ? this.careplan
        : this.careplanOriginalTemplates.find((cp) => cp.support[0].reference === this.careplan.support[0].reference);
      currentCareplan = currentCareplan ? currentCareplan : this.careplan;
      let idxActivity = 0;
      for (const activity of currentCareplan.activity) {
        if (activity.detail.productTemplateList && activity.reference.reference) {
          this.protocols[activity.reference.reference] = activity.detail.productTemplateList;
        }
        if (this.createNewCareplan) {
          if (activity.reference?.reference && this.protocols[activity.reference.reference] && !activity.detail.productReference) {
            activity.detail.productReference = {
              display: null,
              reference: null,
            };
          }
        } else {
          // Sadly we sometimes have activities with the same reference in some careplans
          const activitiesList = this.careplan.activity.filter((act) => act.reference.reference === activity.reference.reference);
          // ... so we must check and if that's the case, we can only use the position in the array.
          const currentActivity = activitiesList.length > 1 ? this.careplan.activity[idxActivity] : activitiesList[0];
          if (
            activity.reference?.reference &&
            this.protocols[activity.reference.reference] &&
            currentActivity?.detail &&
            !currentActivity.detail.productReference
          ) {
            currentActivity.detail.productReference = {
              display: null,
              reference: null,
            };
          }
          // the if statement below is needed to check if a productReference is already assigned to any careplans drug
          // this makes sure that if the drug doesn't have a productTemplateList/protocol then productReference has to be empty
          if (activity.reference?.reference && !this.protocols[activity.reference.reference] && currentActivity?.detail?.productReference) {
            currentActivity.detail.productReference = {
              display: null,
              reference: null,
            };
          } else if (
            activity.reference?.reference &&
            this.protocols[activity.reference.reference] &&
            currentActivity?.detail?.productReference
          ) {
            if (
              !this.protocols[activity.reference.reference].find((p) => p.reference === currentActivity?.detail?.productReference.reference)
            ) {
              currentActivity.detail.productReference = {
                display: null,
                reference: null,
              };
            }
          }
        }
        ++idxActivity;
      }
    }
    this.isInit = true;
  }

  /**
   * Re order sub-activities based on timing
   */
  private reorderCareplanSubActivities() {
    for (const activity of this.careplan.activity) {
      if (activity.actionResulting) {
        activity.actionResulting = activity.actionResulting.sort((o1, o2) => o1.when?.period - o2.when?.period);
      }
    }
  }

  /**
   * update activity protocol
   */
  public onProtocolChanged(activity: Activity): void {
    Careplan.setProtocol(activity, activity.detail.productReference);
  }

  public compareDrugDisplay(o1: Reference, o2: Reference): boolean {
    return o1?.display === o2?.display;
  }

  /**
   * select another careplan template
   */
  public onTemplateSelected(idx: number): void {
    this.templateSelectedIndex = idx;
    this.careplan = this.initializedCareplanTemplates[this.templateSelectedIndex];
    this.noEndDate = Careplan.hasNoEndDate(this.careplan);
    if (!this.noEndDate) {
      // pre-complete the end date
      if (this.careplan && !this.careplan.period.end && this.careplan.period.endTiming) {
        const { period, periodUnits } = this.careplan.period.endTiming.repeat;
        if (!period || !periodUnits) return;
        if (!this.PeriodUnits[periodUnits]) throw new Error("Invalid period unit");
        this.careplan.period.end = moment().add(period, this.PeriodUnits[periodUnits]).toISOString();
      }
    }
    this.buildProtocols();
  }

  public isActivityADrug(activity: Activity): boolean {
    const isDrug = activity.detail.category.coding.findIndex((code) => code.code === "drug") > -1;
    return isDrug;
  }

  public isNewActivityDrug(activity: Activity): FORMS_MODE {
    if (this.createNewCareplan) {
      return FORMS_MODE.CREATE;
    }
    if (activity.detail.scheduledTiming.repeat.boundsPeriod.start) {
      return FORMS_MODE.UPDATE;
    }
    return FORMS_MODE.CREATE;
  }

  /**
   * Check if careplan is valid before saving
   */
  private isCareplanValid(): boolean {
    let hasEndlessActivity = false;
    let frequenciesValid = true;
    this.drugsTimings.forEach((t) => {
      if (!t.save()) {
        frequenciesValid = false;
      }
    });
    this.drugCommentComponent.forEach((d) => d.save());
    if (!frequenciesValid) {
      return false;
    }
    const careplanStartDate = moment(this.careplan.period.start);
    const careplanEndDate = moment(this.careplan.period.end);
    if (!this.noEndDate && !careplanEndDate.isValid()) {
      return false;
    }
    if (!careplanStartDate.isValid()) {
      return false;
    }
    // check activities
    for (const activity of this.careplan.activity) {
      if (activity.detail.status !== Careplan.ACTIVITY_STATUS_INACTIVE) {
        if (this.isScheduledTiming(activity) && (this.createNewCareplan || !this.isActivityADrug(activity))) {
          const activityStartDate = moment(activity.detail.scheduledTiming.repeat.boundsPeriod.start);
          const activityEndDate = moment(activity.detail.scheduledTiming.repeat.boundsPeriod.end);
          // check activity start date
          if (
            !activityStartDate.isValid() ||
            activityStartDate.isBefore(careplanStartDate, "day") ||
            (!this.noEndDate && activityStartDate.isAfter(careplanEndDate, "day"))
          ) {
            return false;
          }
          // check activity end date if not endless activity
          if (activity.detail.scheduledTiming.repeat.endless === undefined || activity.detail.scheduledTiming.repeat.endless === false) {
            if (
              !activityEndDate.isValid() ||
              activityEndDate.isBefore(activityStartDate, "day") ||
              (!this.noEndDate && activityEndDate.isAfter(careplanEndDate, "day"))
            ) {
              return false;
            }
          }
          // check activity start date against careplan end date if activity endless
          else {
            if (!this.noEndDate && activityStartDate.isAfter(careplanEndDate, "day")) {
              return false;
            }
            hasEndlessActivity = true;
          }
          if (!activity.detail.scheduledTiming.repeat.frequency) {
            return false;
          }
        } else if (this.isScheduledPeriod(activity)) {
          const activityStartDate = moment(activity.detail.scheduledPeriod.start);
          const activityEndDate = moment(activity.detail.scheduledPeriod.end);
          // check activity start date
          if (
            !activityStartDate.isValid() ||
            activityStartDate.isBefore(careplanStartDate, "day") ||
            (!this.noEndDate && activityStartDate.isAfter(careplanEndDate, "day"))
          ) {
            return false;
          }
          if (
            !activityEndDate.isValid() ||
            activityEndDate.isBefore(activityStartDate, "day") ||
            (!this.noEndDate && activityEndDate.isAfter(careplanEndDate, "day"))
          ) {
            return false;
          }
        } else if (this.isScheduledString(activity)) {
          const activityDate = moment(activity.detail.scheduledString);
          if (
            !activityDate.isValid() ||
            activityDate.isBefore(careplanStartDate, "day") ||
            (!this.noEndDate && activityDate.isAfter(careplanEndDate, "day"))
          ) {
            return false;
          }
        }
      }

      if (activity?.actionResulting?.length) {
        for (const action of activity.actionResulting) {
          if (action?.when?.period < -366 || action?.when?.period > 366) {
            return false;
          }
        }
      }
    }

    if (!hasEndlessActivity && !careplanEndDate && !this.noEndDate) {
      return false;
    }

    if (
      this.careplan?.addresses &&
      this.careplan?.addresses.length &&
      this.careplan.addresses.find((elem) => elem.status === "active") === undefined
    ) {
      return false;
    }

    return true;
  }

  public cycleChanged(cycle: CycleSchema): void {
    if (cycle) {
      this.hasCycle = true;
    } else {
      this.hasCycle = false;
    }
  }

  public stepwiseDrugChanged(stepwise: IStepwiseDrug): void {
    this.hasStepwise = stepwise ? true : false;
  }

  /**
   *  create/update careplan
   */
  public save(): void {
    this.isLoading = true;
    // mark form controls as touched to trigger validator on save
    this.initPatientComp?.patientForm?.markAllAsTouched();
    //  Check if careplan is valid before saving
    if (!this.isCareplanValid() || (this.includeBirthdateAndGenderForm && !this.initPatientComp?.patientForm?.valid)) {
      this.snackBar.open(this.translateService.instant("error.invalidData"), undefined, {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
      this.isLoading = false;
      return;
    }
    const careplanToSave = Tools.clone(this.careplan);

    // Build identifier field on careplan creation and create templatePublicationDate field
    if (this.createNewCareplan) {
      careplanToSave.templatePublicationDate = this.careplan.publicationDate;
      delete careplanToSave.publicationDate;
      careplanToSave.identifier = [
        {
          use: "usual",
          system: FHIRHelper.SYSTEM_COMUNICARE,
          value: this.patient.patient.managingOrganization.reference + "/" + moment().format("X"),
        },
      ];

      careplanToSave.identifier.push({
        use: this.registration ? this.healthcareService.reference : this.selectedDepartment.reference,
        system: "organization",
        value: this.patient.patient.managingOrganization.reference,
      });
    }

    // force Moment format for start/end date and scheduled activity period
    careplanToSave.period.start = moment(this.careplan.period.start).format();
    careplanToSave.period.end = this.noEndDate ? moment(Careplan.NO_END_DATE).format() : moment(careplanToSave.period.end).format();

    careplanToSave.activity.forEach((activity) => {
      if (activity.detail.status !== Careplan.ACTIVITY_STATUS_INACTIVE) {
        if (this.isScheduledTiming(activity)) {
          activity.detail.scheduledTiming.repeat.boundsPeriod.start = moment(
            activity.detail.scheduledTiming.repeat.boundsPeriod.start
          ).format();

          if (activity.detail.scheduledTiming.repeat.boundsPeriod.end) {
            activity.detail.scheduledTiming.repeat.boundsPeriod.end = moment(
              activity.detail.scheduledTiming.repeat.boundsPeriod.end
            ).format();
          }
        } else if (this.isScheduledPeriod(activity)) {
          activity.detail.scheduledPeriod.start = moment(activity.detail.scheduledPeriod.start).format();
          activity.detail.scheduledPeriod.end = moment(activity.detail.scheduledPeriod.end).format();
        } else if (this.isScheduledString(activity)) {
          if (activity.detail.isDate || !activity.detail.scheduledString?.includes("T")) {
            activity.detail.scheduledString = moment(activity.detail.scheduledString).format("YYYY-MM-DD");
          } else {
            activity.detail.scheduledString = moment(activity.detail.scheduledString).format();
          }
        }
        if (this.drugCycles[activity.reference.reference]) {
          activity.detail.cycle = this.drugCycles[activity.reference.reference];
        } else {
          delete activity.detail.cycle;
        }
        if (this.drugStepwises[activity.reference.reference]) {
          activity.detail.stepwise = this.drugStepwises[activity.reference.reference];
        } else {
          delete activity.detail.stepwise;
        }
        if (activity.actionResulting?.length > 0) {
          activity.actionResulting.forEach((action) => {
            if (action.detail.target === ACTION_TARGET.COMMUNICATION) {
              action.detail.contentCommunication.sender = {
                reference: this.sessionService.account.caremateIdentifier,
                display: this.sessionService.account.displayName,
              };
              action.detail.contentCommunication.subjects = [
                {
                  reference: this.patient.user.caremateIdentifier,
                  display: this.patient.user.displayName
                    ? this.patient.user.displayName
                    : `${this.patient.user.name} ${this.patient.user.firstname}`,
                },
              ];
              action.detail.contentCommunication.healthservice = this.selectedDepartment;
              action.detail.contentCommunication.status = STATUS_CODE.COMPLETED;
            }
          });
        }

        if (!activity.detail.comment) {
          delete activity.detail.comment;
        }
        if (activity?.progress?.length && activity.progress[0].text) {
          activity.progress[0].authorReference = {
            reference: this.sessionService.account.caremateIdentifier,
            display: this.sessionService.account.displayName,
          };
          activity.progress[0].time = moment().format();
        }
      }
      if (activity.detail.productReference && !activity.detail.productReference.display) {
        delete activity.detail.productReference;
      }
    });
    //  re-compute sub-activity date base on main activity and timing
    this.computeSubActivityDates(careplanToSave);

    // check if careplan already exists on creation only
    let alreadyExists = false;
    if (this.createNewCareplan && this.careplans.length) {
      this.careplans.forEach((group) => {
        const found = group.careplans.find((careplan) => careplan.support[0].reference === careplanToSave.support[0].reference);
        if (found) {
          alreadyExists = true;
        }
      });
    }

    if (alreadyExists) {
      this.confirmSaveIfAlreadyExists(careplanToSave);
    } else {
      // send careplan creation request to server
      this.createOrUpdateCareplan(careplanToSave);
    }
  }

  private createOrUpdateCareplan(careplanToSave: ICareplan) {
    const apiAction = this.createNewCareplan
      ? this.careplansService.createCareplan(careplanToSave)
      : this.careplansService.updateCareplan(careplanToSave);
    apiAction.pipe(takeUntil(this.onDestroy$)).subscribe(
      () => {
        this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        this.sessionService.needRefreshDrugsData();
        this.sessionService.needRefreshTeleconsultationsDataList();
      },
      (err) => {
        FileLogger.error("CareplanComponent", "createOrUpdateCareplan", err);
      },
      () => {
        // save patient gender and Birthdate
        if (this.includeBirthdateAndGenderForm) {
          this.initPatientComp?.save();
        }
        this.saved.emit();
        this.isLoading = false;
      }
    );
  }

  private confirmSaveIfAlreadyExists(careplanToSave: ICareplan) {
    const message = this.translateService
      .instant("page.careplan.alreadyExists")
      .replace(
        "%CAREPLAN_NAME%",
        this.templateSelectedIndex ? this.initializedCareplanTemplates[this.templateSelectedIndex].description : this.careplan?.description
      );
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message,
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((yes) => {
        if (yes) {
          // send careplan creation request to server
          this.createOrUpdateCareplan(careplanToSave);
        } else {
          this.isLoading = false;
          return;
        }
      });
  }

  /**
   * re-compute sub-activity date base on main activity and timing
   */
  private computeSubActivityDates(careplan: ICareplan) {
    for (const activity of careplan.activity) {
      if (activity.detail.status === Careplan.ACTIVITY_STATUS_ACTIVE && activity.actionResulting) {
        for (const action of activity.actionResulting) {
          if (action.status === Careplan.ACTIVITY_STATUS_ACTIVE && action.detail.target !== ACTION_TARGET.KNOWLEDGE) {
            if (this.isScheduledString(activity)) {
              let actionDate = moment(activity.detail.scheduledString);
              actionDate = actionDate.add(action.when.period, action.when.periodUnits as moment.unitOfTime.Base);
              if (actionDate.isValid()) {
                if (action.when.boundsPeriod) {
                  action.when.boundsPeriod.start = actionDate.format();
                } else {
                  action.when.boundsPeriod = {
                    start: actionDate.format(),
                  };
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Toggle "no end date"
   */
  public onEndlessActivity(activity: Activity): void {
    // reset end date
    if (activity.detail.scheduledTiming.repeat.endless) {
      activity.detail.scheduledTiming.repeat.boundsPeriod.end = null;
    }
  }

  /**
   * Activity is scheduled with timing
   */
  private isScheduledTiming(activity: Activity): boolean {
    return activity.detail.scheduledTiming !== undefined;
  }

  /**
   * Activity is scheduled with period
   */
  private isScheduledPeriod(activity: Activity): boolean {
    return activity.detail.scheduledPeriod !== undefined;
  }

  /**
   * Activity is not scheduled but a one shot
   */
  private isScheduledString(activity: Activity): boolean {
    return activity.detail.scheduledString !== undefined;
  }

  /**
   * Start date of an activity has been changed >> set careplan start date to smallest one
   */
  public onStartDateChanged(): void {
    let currentStartDate: moment.Moment = null;
    let smallestDate: moment.Moment = null;
    for (const activity of this.careplan.activity) {
      // ignore not active activity or drug activity if we are updating the careplan
      if (activity.detail.status !== "active" || (!this.createNewCareplan && this.isActivityADrug(activity))) {
        continue;
      }
      if (this.isScheduledTiming(activity)) {
        currentStartDate = moment(activity.detail.scheduledTiming.repeat.boundsPeriod.start);
      } else if (this.isScheduledPeriod(activity)) {
        currentStartDate = moment(activity.detail.scheduledPeriod.start);
      } else if (this.isScheduledString(activity)) {
        currentStartDate = moment(activity.detail.scheduledString);
      }
      if (smallestDate === null) {
        smallestDate = currentStartDate;
      }
      if (currentStartDate?.isValid()) {
        if (smallestDate === null) {
          smallestDate = currentStartDate;
        }
        if (currentStartDate.isSameOrBefore(smallestDate, "day")) {
          smallestDate = currentStartDate;
        }
      }
    }
    // set smallest
    if (smallestDate && smallestDate.isValid() && smallestDate.isBefore(this.careplan.period.start, "day")) {
      this.careplan.period.start = smallestDate.format();
    }
  }

  /**
   * Recompute start & end careplan date
   */
  private recomputeStartEndDate() {
    this.onStartDateChanged();
    this.onEndDateChanged();
  }

  /**
   * End date of an activity has been changed >> set careplan end date to highest one
   */
  public onEndDateChanged(): void {
    let currentEndDate: moment.Moment = null;
    let highestDate: moment.Moment = null;
    for (const activity of this.careplan.activity) {
      // ignore not active activity
      if (activity.detail.status !== "active" || (!this.createNewCareplan && this.isActivityADrug(activity))) {
        continue;
      }
      if (this.isScheduledTiming(activity)) {
        if (activity.detail.scheduledTiming.repeat.endless) {
          continue;
        }
        currentEndDate = moment(activity.detail.scheduledTiming.repeat.boundsPeriod.end);
      } else if (this.isScheduledPeriod(activity)) {
        currentEndDate = moment(activity.detail.scheduledPeriod.end);
      } else if (this.isScheduledString(activity)) {
        continue;
      }

      if (currentEndDate?.isValid()) {
        if (highestDate === null) {
          highestDate = currentEndDate;
        }
        if (currentEndDate.isSameOrAfter(highestDate, "day")) {
          highestDate = currentEndDate;
        }
      }
    }
    // set highest
    if (highestDate && highestDate.isValid() && highestDate.isAfter(this.careplan.period.end, "day")) {
      this.careplan.period.end = highestDate.format();
    }
  }

  /**
   * Switch activity status: active/inactive
   */
  public onCheckedActivity(activity: Activity): void {
    activity.detail.status = activity.detail.status === "active" ? "inactive" : "active";
    this.recomputeStartEndDate();
  }

  /**
   * Switch addresses status: active/inactive
   */
  public onCheckedAddresses(addresses: IAddresses): void {
    addresses.status = addresses.status === "active" ? "inactive" : "active";
  }

  /**
   * Switch quantities status: checked/unchecked
   */
  public onCheckedTiming($event: MatCheckboxChange, activity: Activity, timing: string): void {
    const changedActivity = this.careplan.activity.find((a) => a === activity);
    let activityTimingCode = changedActivity.detail.scheduledTiming.repeat.timingCode;
    let changedQuantity = changedActivity.detail.scheduledTiming.repeat.quantities[timing];
    activityTimingCode = activityTimingCode === "undefined" ? undefined : activityTimingCode;
    if (changedActivity.detail.scheduledTiming.repeat.periodUnits !== "h") {
      if (!activityTimingCode && $event.checked) {
        activityTimingCode = "";
      }
      if (timing === this.timingCodes[0]) {
        if ($event.checked) {
          activityTimingCode += IEntity.TIMING_RISING;
        } else {
          activityTimingCode = activityTimingCode.replace(IEntity.TIMING_RISING, "");
          changedQuantity = null;
        }
      }
      if (timing === this.timingCodes[1]) {
        if ($event.checked) {
          activityTimingCode += IEntity.TIMING_MORNING;
        } else {
          activityTimingCode = activityTimingCode.replace(IEntity.TIMING_MORNING, "");
          changedQuantity = null;
        }
      }
      if (timing === this.timingCodes[2]) {
        if ($event.checked) {
          activityTimingCode += IEntity.TIMING_NOON;
        } else {
          activityTimingCode = activityTimingCode.replace(IEntity.TIMING_NOON, "");
          changedQuantity = null;
        }
      }
      if (timing === this.timingCodes[3]) {
        if ($event.checked) {
          activityTimingCode += IEntity.TIMING_EVENING;
        } else {
          activityTimingCode = activityTimingCode.replace(IEntity.TIMING_EVENING, "");
          changedQuantity = null;
        }
      }
      if (timing === this.timingCodes[4]) {
        if ($event.checked) {
          activityTimingCode += IEntity.TIMING_BED;
        } else {
          activityTimingCode = activityTimingCode.replace(IEntity.TIMING_BED, "");
          changedQuantity = null;
        }
      }
      changedActivity.detail.scheduledTiming.repeat.timingCode = activityTimingCode;
      changedActivity.detail.scheduledTiming.repeat.quantities[timing] = changedQuantity;
    }
  }

  /**
   * Check which moment of the day is selected
   */
  public isTimingChecked(timingCodes: string, timing: string): boolean {
    if (!timingCodes) {
      return false;
    }
    switch (timing) {
      case "rise":
        return timingCodes.includes(IEntity.TIMING_RISING);
      case "morning":
        return timingCodes.includes(IEntity.TIMING_MORNING);
      case "noon":
        return timingCodes.includes(IEntity.TIMING_NOON);
      case "evening":
        return timingCodes.includes(IEntity.TIMING_EVENING);
      case "bedtime":
        return timingCodes.includes(IEntity.TIMING_BED);
      default:
        return false;
    }
  }

  /**
   * This is called on careplan update to check if scheduledString needs a date or dateTime
   */
  private setupIsDate() {
    for (const activity of this.careplan.activity) {
      if (this.isScheduledString(activity)) {
        if (activity.detail.scheduledString === "dateTime") {
          activity.detail.isDate = false;
        } else if (activity.detail.scheduledString !== null && activity.detail.scheduledString.includes("T")) {
          activity.detail.isDate = false;
        } else {
          activity.detail.isDate = true;
        }
      }
    }
  }

  /**
   * Adapt some values in original careplan template (dates, etc.) and translate it
   */
  private initializeCareplanTemplate(defs: ICareplan[]): ICareplan[] {
    if (!defs || !defs.length) {
      return [];
    }

    const arrTemplates = new Array<ICareplan>();
    const today = moment().hours(8).minutes(0).seconds(0).milliseconds(0);
    defs.forEach((careplanTemplate) => {
      try {
        const template = { ...careplanTemplate };
        // set start/end date
        if (!template.period.start) {
          template.period.start = today.format();
        }
        // set patient as subject
        template.subject = {
          reference: this.patient.user.caremateIdentifier,
          display: `${this.patient.user.name} ${this.patient.user.firstname}`,
        };
        // set schedule activity timing
        template.activity.forEach((activity) => {
          if (this.isScheduledTiming(activity)) {
            activity.detail.scheduledTiming.repeat.boundsPeriod.start = null;
            if (activity.detail.scheduledTiming.repeat.boundsPeriod.end) {
              activity.detail.scheduledTiming.repeat.boundsPeriod.end = null;
            }
            if (activity.detail.scheduledTiming.repeat.quantities) {
              this.timingCodes.forEach((t) => {
                if (activity.detail.scheduledTiming.repeat.quantities[t]) {
                  if (!activity.detail.scheduledTiming.repeat.timingCode) {
                    activity.detail.scheduledTiming.repeat.timingCode = "";
                  }
                  if (t === this.timingCodes[0]) {
                    activity.detail.scheduledTiming.repeat.timingCode += IEntity.TIMING_RISING;
                  }
                  if (t === this.timingCodes[1]) {
                    activity.detail.scheduledTiming.repeat.timingCode += IEntity.TIMING_MORNING;
                  }
                  if (t === this.timingCodes[2]) {
                    activity.detail.scheduledTiming.repeat.timingCode += IEntity.TIMING_NOON;
                  }
                  if (t === this.timingCodes[3]) {
                    activity.detail.scheduledTiming.repeat.timingCode += IEntity.TIMING_EVENING;
                  }
                  if (t === this.timingCodes[4]) {
                    activity.detail.scheduledTiming.repeat.timingCode += IEntity.TIMING_BED;
                  }
                }
              });
            }
          } else if (this.isScheduledPeriod(activity)) {
            activity.detail.scheduledPeriod.start = null;
            activity.detail.scheduledPeriod.end = null;
          } else if (this.isScheduledString(activity)) {
            if (activity.detail.scheduledString === "dateTime") {
              activity.detail.isDate = false;
            } else {
              activity.detail.isDate = true;
            }
          }
        });
        // this.formatDatesForCalendarUI(template);
        arrTemplates.push(template);
      } catch (err) {
        FileLogger.error("CareplanComponent", "initializeCareplanTemplate", err);
      }
    });
    return arrTemplates;
  }

  public displayActionResulting(actions: IOrder[]): boolean {
    if (!actions || actions.length === 0) {
      return false;
    }
    return !actions.map((a) => a.notDisplay).reduce((a1, a2) => a1 && a2);
  }

  public detectChanges(): void {
    this.cdr.detectChanges();
  }

  public compareReference(o1: Reference, o2: Reference): boolean {
    return o1?.reference === o2?.reference;
  }

  public periodChange(activityIndex: number, actionIndex: number): void {
    // if there is the same observation/questionnaire in an other activity,
    // these two (or more) observations/questionnaires must have the same period value
    const ref = this.careplan.activity[activityIndex].actionResulting[actionIndex].detail.reference;
    if (ref) {
      this.careplan.activity?.forEach((activity) => {
        activity.actionResulting?.forEach((action) => {
          if (action.detail?.reference?.reference === ref.reference) {
            action.when.period = this.careplan.activity[activityIndex].actionResulting[actionIndex].when.period;
          }
        });
      });
    }
  }

  public showCommunication(action: IOrder): void {
    // we create a fictive communication,
    // we only need to set :
    // the basedOn.type = 'careplan' to show the service instead of the practitioner name,
    // the topic and the content in the practitioner language
    // the service
    const communication: ICommunication = {
      entityStatus: null,
      resourceType: null,
      identifier: null,
      basedOn: [
        {
          reference: null,
          display: null,
          type: "careplan",
        },
      ],
      partOf: null,
      inResponseTo: null,
      status: null,
      statusReason: null,
      category: null,
      priority: null,
      medium: null,
      subject: null,
      topic: {
        coding: null,
        text: action.detail.contentCommunication.topicTranslation?.[this.translateService.currentLang],
      },
      about: null,
      encounter: null,
      sent: null,
      received: null,
      recipient: null,
      sender: {
        reference: this.sessionService.account.caremateIdentifier,
        display: this.sessionService.account.displayName,
      },
      reasonCode: null,
      reasonReference: null,
      payload: [
        {
          contentString: action.detail.contentCommunication.contentTranslation?.[this.translateService.currentLang],
        },
      ],
      note: null,
      records: null,
      healthservice: this.selectedDepartment,
    };
    this.dialog.open(CommunicationOverviewComponent, {
      data: { communication },
    });
  }

  public onPeriodStartDateChanged(minDate: string, maxDate: string): void {
    let smallestDate = moment(minDate);
    const highestDate = moment(maxDate);
    if (smallestDate && highestDate && smallestDate > highestDate) {
      smallestDate = highestDate;
    }
    if (smallestDate && smallestDate.isValid()) {
      this.careplan.period.start = smallestDate.format();
    }
    this.onStartDateChanged();
  }

  public onPeriodEndDateChanged(minDate: string, maxDate: string): void {
    const smallestDate = moment(minDate);
    let highestDate = moment(maxDate);
    if (smallestDate && highestDate && highestDate < smallestDate) {
      highestDate = smallestDate;
    }
    if (highestDate && highestDate.isValid()) {
      this.careplan.period.end = highestDate.format();
    }
    this.onEndDateChanged();
  }

  public comparePeriodUnitsFn(o1: never, o2: never): boolean {
    return Tools.standardizePeriodUnit(o1) === Tools.standardizePeriodUnit(o2);
  }
}
