import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { CustomErrorStateMatcher } from "src/app/helpers/formValidators";
import { FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { ICareplan } from "src/app/models/careplans.interface";
import { Coding } from "src/app/models/coding.interface";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { IKnowMedia } from "src/app/models/knowledge.interface";
import { Questionnaire } from "src/app/models/questionnaire.mdel";
import { IQuestionnaireScoring, SCORING_TYPE } from "src/app/models/questionnaireScoring.interface";
import { Reference } from "src/app/models/reference.interface";
import { AccessLevel, EnableWhenBehavior, IUseContext } from "src/app/models/sharedInterfaces";
import { CareplansService } from "src/app/providers/careplans.service";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import uuid from "uuid-random";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogType,
} from "../../../../components/confirmation-dialog/confirmation-dialog.component";
import {
  IQuestionnaire,
  IQuestionnaireParam,
  PAGING_MODE,
  QUESTIONNAIRE_LINK_TYPE,
  QuestionQuestionnaire,
  QuestionnaireStatus,
  SPECIFIC_USE,
} from "../../../../models/questionnaire.interface";
import { QuestionnaireParamsComponent } from "./questionnaire-params/questionnaire-params.component";
import { QuestionnaireScoringFormulaComponent } from "./questionnaire-scoring-formula/questionnaire-scoring-formula.component";

export interface ICareplanAndActivitiesRefs {
  careplanId: string;
  selectedActivityRefs: string[];
}

@Component({
  selector: "app-questionnaire-properties",
  templateUrl: "./questionnaire-properties.component.html",
  styleUrls: ["./questionnaire-properties.component.scss"],
})
export class QuestionnairePropertiesComponent implements OnInit, OnDestroy {
  // Important: no changes in the inputs of this component are transmitted to
  // the parent component until the "applyChanges()" method is called !
  @Input() questionnaire: IQuestionnaire;
  @Input() set parameters(p: IQuestionnaireParam) {
    this.params = Tools.clone(p);
  }
  @Input() isNewQuestionnaire: boolean;
  @Input() visualization = false;
  @Input() scorings: IQuestionnaireScoring[];
  @Input() withObservations: boolean;

  @Output() questionnaireChange = new EventEmitter<IQuestionnaire>();
  @Output() parametersChange = new EventEmitter<IQuestionnaireParam>();
  @Output() scoringsChange = new EventEmitter<IQuestionnaireScoring[]>();
  @Output() withObservationsChange = new EventEmitter<boolean>();
  @Output() closeEvent = new EventEmitter<void>();

  @ViewChild(QuestionnaireParamsComponent) paramsEditor: QuestionnaireParamsComponent;

  public questionnaireLinkType: QUESTIONNAIRE_LINK_TYPE;
  public SPECIFIC_USE = SPECIFIC_USE; // for accessing Enum in template
  public QUESTIONNAIRE_LINK_TYPE = QUESTIONNAIRE_LINK_TYPE;
  public questionnairePropsForm: UntypedFormGroup;
  public scoringsForm: UntypedFormGroup;
  public languageOptions: Coding[];
  public careplanOptions: ICareplan[];
  public knowledgeTitle: string;
  public mediaTitle: string[];
  public hasKnowledgeInfos = false;
  public params: IQuestionnaireParam;
  public linkedCareplans: ICareplan[] = [];
  public originalCareplansWithQuestActivities: ICareplan[]; // the careplans whose activities where linked to this questionnaire
  public QuestionnaireStatus = QuestionnaireStatus;
  public questions: QuestionQuestionnaire[];
  public operatorOptions = ["=", ">", "<", ">=", "<="];
  public enableWhenBehaviorOptions = [
    {
      display: this.translateService.instant("page.questionnaireEditor.properties.and"),
      value: EnableWhenBehavior.AND,
    },
    {
      display: this.translateService.instant("page.questionnaireEditor.properties.or"),
      value: EnableWhenBehavior.OR,
    },
  ];
  public matcher = new CustomErrorStateMatcher();

  public orgServices: Healthcareservice[];
  public organizationsRefs: Reference[];
  public useContext: IUseContext[];

  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private careplansService: CareplansService,
    private formsData: FormsData,
    private knowledgeService: KnowledgeService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.setupOrgsAndServicesLists();
    this.questions =
      this.questionnaire.paging === PAGING_MODE.GROUP ? this.questionnaire.group.group[0].question : this.questionnaire.group.question;
    this.formsData.getLanguages().then((languages) => (this.languageOptions = languages));
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(async () => {
      this.languageOptions = await this.formsData.getLanguages();
    });
    if (this.questionnaire) {
      this.questionnaireLinkType = this.findQuestionnaireLinkType();
      if (!this.isNewQuestionnaire) {
        // Get the potential link to careplan activities in order to display it
        // (it will NEVER be editable in this editor):
        this.getLinkedCareplansActivities()
          .then((careplans: ICareplan[]) => {
            this.originalCareplansWithQuestActivities = careplans;
            if (careplans?.length) {
              this.questionnaireLinkType = QUESTIONNAIRE_LINK_TYPE.ACTIVITY;
              this.linkedCareplans = Tools.clone(careplans);
            }
          })
          .catch((err) => {
            FileLogger.warn("QuestionnaireProperties", "Error while getting linkedCareplansActivities", err, "none");
            this.originalCareplansWithQuestActivities = undefined;
          });
      }
      this.questionnairePropsForm = this.fb.group({
        title: [this.questionnaire.subjectType, [Validators.required]],
        subtitle: [this.questionnaire.group.title, [Validators.required]],
        description: [this.questionnaire.group.text, []],
        language: [this.questionnaire.version, []],
        practitionersOnly: [{ value: this.questionnaire.onlyPractitioner, disabled: !this.isNewQuestionnaire }, []],
        withObservations: [this.withObservations, []],
        onlyOnline: [this.questionnaire.onlyOnline, []],
        scorings: this.fb.array([]),
      });
      this.initScorings();
      // Special setup for quiz and consent:
      if (this.questionnaire.specificUse === SPECIFIC_USE.QUIZ || this.questionnaire.specificUse === SPECIFIC_USE.CONSENT) {
        this.questionnairePropsForm.removeControl("practitionersOnly");
        this.questionnairePropsForm.addControl(
          "successThreshold",
          new UntypedFormControl(this.questionnaire.successThreshold, [Validators.required])
        );
        if (this.questionnaire.specificUse === SPECIFIC_USE.QUIZ) {
          this.questionnairePropsForm.addControl("manualTransition", new UntypedFormControl(this.questionnaire.manualTransition, []));
        }

        if (this.questionnaire.specificUse === SPECIFIC_USE.CONSENT) {
          this.questionnairePropsForm.get("successThreshold").disable();
        } else if (this.questionnaire.knowledgeId && this.questionnaire.mediaId) {
          this.knowledgeService
            .getKnowledge(this.questionnaire.knowledgeId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((result) => {
              if (result.published) {
                this.hasKnowledgeInfos = true;
                this.knowledgeTitle = result.published.snomedReference.display;
                this.mediaTitle = result.published.medias
                  .filter((media: IKnowMedia) => media.identifier.value === this.questionnaire.mediaId)
                  .map((media: IKnowMedia) => media.label);
              } else {
                this.hasKnowledgeInfos = false;
              }
            });
        }
      } else {
        this.questionnairePropsForm
          .get("practitionersOnly")
          .valueChanges.pipe(takeUntil(this.onDestroy$))
          .subscribe((practitionersOnly: boolean) => {
            if (practitionersOnly && this.questionnairePropsForm.get("linkTo").value !== "careplans") {
              this.questionnairePropsForm.get("linkTo").patchValue("careplans");
            }
          });
      }
      this.useContext = Tools.clone(this.questionnaire.useContext);
    }
    if (this.visualization) {
      this.questionnairePropsForm.disable();
    } else if (!this.isNewQuestionnaire && !this.userService.isAuthorizedSync(null, "dashboard/questionnaireScorings", "PUT")) {
      this.scoringsFormArray.disable();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Apply the change to the page and send
   * them to the parent component.
   */
  public applyChanges(): void {
    // ---- Valid properties for Classic questionnaires:
    if (this.questionnairePropsForm.valid && this.areUseContextPropertiesValid()) {
      const paramsValid = this.paramsEditor ? this.paramsEditor.applyChanges() : true;
      if (paramsValid || !this.params.notify) {
        this.applyQuestionnaireChanges();
        this.applyParamsChanges();
        this.applyScoringChanges();
        if (this.questionnaire.onlyPractitioner) {
          this.withObservationsChange.emit(this.questionnairePropsForm.get("withObservations").value);
        } else {
          this.withObservationsChange.emit(false);
        }
        // close panel after changes
        this.closeEvent.next();
      } else {
        FileLogger.warn("Questionnaire properties", "Invalid parameters", this.params, "none");
      }
    }
    // ---- Valid properties for Quiz & Consent
    else if (this.questionnairePropsForm.valid && !Questionnaire.isClassicQuestionnaire(this.questionnaire)) {
      this.applyQuestionnaireChanges();
      this.questionnaireChange.emit(this.questionnaire);
      // close panel after changes
      this.closeEvent.next();
    }
    // ---- Bad properties !
    else {
      if (this.questionnaireLinkType !== QUESTIONNAIRE_LINK_TYPE.ACTIVITY && !this.areUseContextPropertiesValid()) {
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            message: this.translateService.instant("page.questionnaireEditor.properties.needAccess"),
            type: ConfirmationDialogType.INFORMATION,
          },
        });
      } else {
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            message: this.translateService.instant("page.questionnaireEditor.properties.needValidate"),
            type: ConfirmationDialogType.INFORMATION,
          },
        });
      }
    }
  }

  /**
   * Checks if the useContext properties are valid for "classic" questionnaire.
   * Return false if it's a quiz or a consent
   * @returns
   */
  private areUseContextPropertiesValid(): boolean {
    const isClassic = Questionnaire.isClassicQuestionnaire(this.questionnaire);
    if (!isClassic) {
      return false;
    }
    return (
      this.questionnaire.useContext?.length > 0 && this.questionnaire.useContext.find((u) => u.accessLevel >= AccessLevel.WRITE) !== null
    );
  }

  /**
   * Apply and emit the changes in the questionnaire form to the questionnaire.
   * Only apply basic changes such as title, subtitle, version...
   */
  private applyQuestionnaireChanges(): void {
    this.questionnaire.subjectType = this.questionnairePropsForm.value.title;
    this.questionnaire.group.title = this.questionnairePropsForm.value.subtitle;
    this.questionnaire.group.text = this.questionnairePropsForm.value.description;
    this.questionnaire.version = this.questionnairePropsForm.value.language;
    this.questionnaire.successThreshold = this.questionnairePropsForm.value.successThreshold;
    this.questionnaire.manualTransition = this.questionnairePropsForm.value.manualTransition;
    this.questionnaire.onlyOnline = this.questionnairePropsForm.value.onlyOnline;
    if (this.isNewQuestionnaire) {
      this.questionnaire.onlyPractitioner = this.questionnairePropsForm.value.practitionersOnly;
    }

    this.questionnaire.useContext = this.useContext;
    this.questionnaireChange.emit(this.questionnaire);
  }

  /**
   * Apply the updated questionnaire data to the params and emit the changes.
   * Note: Params were cloned when we received them in the input, so changes are not immediately
   * transmitted even thought we did not use a form as intermediary. So we need to specifically emit them.
   */
  private applyParamsChanges(): void {
    this.params.version = this.questionnaire.version;
    this.params.identifier.value = this.questionnaire.subjectType;
    this.params.identifier.system = this.questionnaire.identifier[0].value;
    this.parametersChange.emit(this.params);
  }

  public showErrors(): void {
    this.questionnairePropsForm.markAllAsTouched();
  }

  private async getLinkedCareplansActivities(): Promise<ICareplan[]> {
    if (!this.questionnaire.identifier[0].value) {
      return [];
    }
    return this.careplansService.getCareplanTemplatesWithActivitiesLinkedToQuestionnaire(this.questionnaire.identifier[0].value);
  }

  private findQuestionnaireLinkType(): QUESTIONNAIRE_LINK_TYPE {
    if (!this.linkedCareplans || this.linkedCareplans.length === 0) {
      return QUESTIONNAIRE_LINK_TYPE.LINK2CAREPLAN;
    }
    for (const careplan of this.linkedCareplans) {
      const foundActionResulting = careplan.actionResulting?.find(
        (ar) => ar.detail?.reference?.reference === this.questionnaire.identifier[0].value
      );
      if (foundActionResulting) {
        return QUESTIONNAIRE_LINK_TYPE.CAREPLAN_AR;
      }
      for (const activity of careplan.activity) {
        const foundActivityActionResulting = activity.actionResulting?.find(
          (ar) => ar.detail?.reference?.reference === this.questionnaire.identifier[0].value
        );
        if (foundActivityActionResulting) {
          return QUESTIONNAIRE_LINK_TYPE.ACTIVITY;
        }
      }
    }
    return QUESTIONNAIRE_LINK_TYPE.LINK2CAREPLAN;
  }

  /**
   * Create the organizations and services lists using the user service
   */
  private setupOrgsAndServicesLists(): void {
    if (!this.orgServices) {
      if (this.userService.isMonitoringUser) {
        this.orgServices = this.userService.allMonitoringServices;
      } else {
        this.orgServices = this.userService.allServices;
      }
    }
    this.organizationsRefs = this.orgServices.map((s) => s.providedBy).filter(ArrayHelper.onlyUniqueReference);
  }

  // --------------------------------------------------------------------------
  // ---------------------------- SCORING -------------------------------------
  // --------------------------------------------------------------------------

  /**
   * Apply and emit the changes in the scoring form to the scorings.
   */
  private applyScoringChanges(): void {
    const newScorings = [];
    this.scoringsFormArray.getRawValue().forEach((s, i) => {
      newScorings.push({
        scoringId: s.scoringId ? s.scoringId : uuid(),
        status: s.status ? s.status : QuestionnaireStatus.DRAFT,
        targets: [
          {
            reference: this.questionnaire.identifier[0].value,
            display: this.questionnaire.subjectType,
          },
        ],
        scoringType: SCORING_TYPE.FORMULA,
        identifier: {
          value: s.name,
          label: s.label,
          system: "http://comunicare.io",
        },
        formula: s.formula,
        valueSet: this.scorings[i].valueSet,
      });
      newScorings[i].interpretations = [];
      s.interpretations.forEach((interp, iInterp) => {
        newScorings[i].interpretations.push({
          enableWhenBehavior: interp.enableWhenBehavior,
          result: { [this.questionnaire.version]: interp.text },
        });
        newScorings[i].interpretations[iInterp].enableWhen = [];
        interp.enableWhens.forEach((e) => {
          newScorings[i].interpretations[iInterp].enableWhen.push({
            operator: e.operator,
            answer: e.answer,
          });
        });
      });
    });
    this.scoringsChange.emit(newScorings);
  }
  private initScorings() {
    this.scorings?.forEach((s, i) => {
      this.scoringsFormArray.push(
        new UntypedFormGroup({
          scoringId: new UntypedFormControl(s.scoringId, []),
          status: new UntypedFormControl(s.status, []),
          name: new UntypedFormControl(s.identifier.value, [Validators.required]),
          label: new UntypedFormControl(s.identifier.label, [Validators.required]),

          formula: new UntypedFormControl({ value: s.formula, disabled: !this.sessionService.isAdmin() }, []),
          interpretations: this.fb.array([]),
        })
      );
      s.interpretations?.forEach((interp, iInterp) => {
        this.getInterpretationFormArray(i).push(
          new UntypedFormGroup({
            enableWhens: this.fb.array([]),
            enableWhenBehavior: new UntypedFormControl(interp.enableWhenBehavior, []),
            text: new UntypedFormControl(interp.result[this.questionnaire.version], []),
          })
        );
        interp.enableWhen?.forEach((e) => {
          this.getEnableWhenFormArray(i, iInterp).push(
            new UntypedFormGroup({
              operator: new UntypedFormControl(e.operator, []),
              answer: new UntypedFormControl(e.answer, []),
            })
          );
        });
      });
    });
  }

  public addScoring(): void {
    const newScoring = this.getEmptyScoring();
    if (!this.scorings?.length) {
      this.scorings = [];
    }
    this.scorings.push(newScoring);
    this.scoringsFormArray.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(newScoring.identifier.value, [Validators.required]),
        label: new UntypedFormControl(newScoring.identifier.label, [Validators.required]),
        formula: new UntypedFormControl({ value: "", disabled: !this.sessionService.isAdmin() }, []),
        interpretations: this.fb.array([]),
      })
    );
  }

  public addInterpretation(i: number): void {
    const interp = this.getInterpretationFormArray(i);
    interp.push(
      new UntypedFormGroup({
        enableWhens: this.fb.array([], [Validators.required]),
        enableWhenBehavior: new UntypedFormControl(EnableWhenBehavior.AND, [Validators.required]),
        text: new UntypedFormControl("", [Validators.required]),
      })
    );
    this.addEnableWhen(i, interp.length - 1);
  }

  public addEnableWhen(i: number, iInterp: number): void {
    const enableWhen = this.getEnableWhenFormArray(i, iInterp);
    enableWhen.push(
      new UntypedFormGroup({
        operator: new UntypedFormControl("", [Validators.required]),
        answer: new UntypedFormControl("", [Validators.required]),
      })
    );
  }

  public deleteEnableWhen(i: number, iInterp: number, IEnableWhen: number): void {
    this.getEnableWhenFormArray(i, iInterp).removeAt(IEnableWhen);
  }

  public deleteInterpretation(i: number, iInterp: number): void {
    this.getInterpretationFormArray(i).removeAt(iInterp);
  }

  public deleteScoring(i: number): void {
    this.scoringsFormArray.removeAt(i);
  }

  public getEnableWhenFormArray(i: number, iInterp: number): UntypedFormArray {
    return this.getInterpretationFormArray(i).controls[iInterp].get("enableWhens") as UntypedFormArray;
  }

  public getInterpretationFormArray(i: number): UntypedFormArray {
    return this.scoringsFormArray.controls[i].get("interpretations") as UntypedFormArray;
  }

  public get scoringsFormArray(): UntypedFormArray {
    return this.questionnairePropsForm.get("scorings") as UntypedFormArray;
  }

  private getEmptyScoring(): IQuestionnaireScoring {
    return {
      scoringId: uuid(),
      status: QuestionnaireStatus.DRAFT,
      targets: [
        {
          reference: this.questionnaire.identifier[0].value,
          display: this.questionnaire.subjectType,
        },
      ],
      scoringType: SCORING_TYPE.FORMULA,
      identifier: {
        value: "",
        label: "",
        system: "http://comunicare.io",
      },
    };
  }

  public editScoringFormula(index: number): void {
    let allQuestions: QuestionQuestionnaire[] = [];
    if (this.questionnaire.paging === PAGING_MODE.GROUP) {
      this.questionnaire.group.group.forEach((g) => allQuestions.push(...g.question));
    } else {
      allQuestions = this.questionnaire.group.question;
    }

    const dialogRef = this.dialog.open(QuestionnaireScoringFormulaComponent, {
      width: "70%",
      height: "80%",
      disableClose: true,
      data: {
        questions: allQuestions,
        formula: this.scoringsFormArray.getRawValue()[index].formula,
        visualization:
          this.visualization ||
          (!this.userService.isAuthorizedSync(null, "dashboard/questionnaireScorings", "PUT") &&
            this.scorings[index].identifier.value !== ""),
        contained: this.questionnaire.contained,
        scoringValueSet: this.scorings[index].valueSet,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.formula) {
        this.scoringsFormArray.controls[index].patchValue({
          formula: result.formula,
        });
      }
      if (result?.scoringValueSet?.length) {
        this.scorings[index].valueSet = result.scoringValueSet;
      }
    });
  }
}
