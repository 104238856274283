import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subject, merge } from "rxjs";
import { first, skipWhile, takeUntil, tap } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceUser, TableParameter } from "src/app/models/preference.interface";
import { IQuestionnaireScoring } from "src/app/models/questionnaireScoring.interface";
import { AccessLevel } from "src/app/models/sharedInterfaces";
import { QuestionnaireApiService } from "src/app/providers/api/questionnaires-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import {
  IQuestionnaire,
  IQuestionnaireHistoryEvent,
  IQuestionnaireListInfo,
  QuestionnaireStatus,
} from "../../models/questionnaire.interface";
import { QuestionnairesService } from "../../providers/questionnaires.service";
import { UserService } from "../../providers/user.service";
import { QuestionnaireHistoryComponent } from "./questionnaire-history/questionnaire-history.component";
import { QuestionnaireListDataSource } from "./questionnaire-list-datasource";
export interface IChoice {
  value: string;
  checked: boolean;
}
@Component({
  selector: "app-fhir-questionnaire-list-page",
  templateUrl: "./fhir-questionnaire-list-page.component.html",
  styleUrls: ["./fhir-questionnaire-list-page.component.scss"],
})
export class FhirQuestionnaireListPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private orgsAndServices: string[] = [];
  public pageLoaded = false;
  // table variables:
  public isFiltered = false;
  public filters: Filter[] = [];
  public DATA_TYPE = DataType;
  public displayedColumns: string[] = [
    "questionnaireStatus",
    "title",
    "subtitle",
    "latestAuthor",
    "latestUpdate",
    "nbPages",
    "nbQuestions",
    "practitionerOnly",
    "questionnaireDraft",
    "actions",
  ];
  public questionnairesCount: number;
  public dataSource: QuestionnaireListDataSource;
  public dataType = DataType;
  public AccessLevel = AccessLevel;
  private preferences: TableParameter;
  public currentPageSize: number;

  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private userService: UserService,
    private questionnairesService: QuestionnairesService,
    private questionnaireApiService: QuestionnaireApiService,
    private healthcareService: HealthcareserviceService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private preferenceService: PreferenceService,
    private route: ActivatedRoute
  ) {
    // get data from resolvers
    this.preferences = this.route.snapshot.data.preferences;
    this.filters = this.preferences?.filters ? this.preferences.filters : [];
    this.isFiltered = this.filters && this.filters.length > 0;
    this.currentPageSize = this.preferences?.itemsPerPage ? this.preferences.itemsPerPage : 25;
  }

  ngOnInit(): void {
    this.dataSource = new QuestionnaireListDataSource(this.questionnaireApiService, this.userService);
    // apply filter
    this.filters?.forEach((filter) => this.dataSource.setFilter(filter));
    this.loadQuestionnairesWithoutCounting();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.paginator.pageIndex = 0));

    //  watch paginator and sort
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadQuestionnairesWithoutCounting();
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
      this.updatePreference();
    });

    if (this.sessionService.currentService && this.userService.ownOrganization) {
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.setupServicesWatch();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServicesWatch(): void {
    this.setupOrgsAndServices();
    this.loadQuestionnaires();

    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.setupOrgsAndServices();
      this.loadQuestionnaires();
    });
  }

  private setupOrgsAndServices(): void {
    const org =
      this.sessionService.organization.reference === this.sessionService.allsOption ? null : [this.sessionService.organization.reference];
    const services =
      org !== null
        ? this.sessionService.currentService.reference === this.sessionService.allsOption
          ? this.healthcareService.availableServices().map((h) => h.asReference.reference)
          : [this.sessionService.currentService.reference]
        : null;

    this.orgsAndServices = org || services ? [...(org ?? []), ...(services ?? [])] : undefined;
  }

  private loadQuestionnaires(): void {
    this.pageLoaded = false;

    // update questionnaires count with search result
    this.questionnairesService
      .getQuestionnairesCount(null, this.getFiltersFormated(), this.orgsAndServices)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.questionnairesCount = result?.count;
        this.loadQuestionnairesWithoutCounting();
        this.pageLoaded = true;
      });
  }

  private loadQuestionnairesWithoutCounting(): void {
    if (this.questionnairesCount > 0) {
      this.dataSource.loadData({
        sortId: this.sort.active ? this.sort.active : "subjectType",
        sortOrder: this.sort.direction ? this.sort.direction : "asc",
        pageNumber: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        filters: this.getFiltersFormated(),
        orgsAndServicesRefs: this.orgsAndServices,
      });
    } else {
      this.dataSource.clearData();
    }
  }

  private getFiltersFormated(): Filter[] {
    const formatedFilters: Filter[] = [];
    const filters = Tools.clone(this.dataSource.getAllFilters());
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        switch (filter.dataType) {
          case this.DATA_TYPE.DATE:
            // format date params to string
            filter.data.fromDate = moment(filter.data.fromDate).format("YYYY-MM-DD");
            filter.data.toDate = moment(filter.data.toDate).format("YYYY-MM-DD");
            break;
          case this.DATA_TYPE.CHOICE: {
            const values: Array<string> = [];
            const choices: IChoice[] = filter.data ? filter.data : [];
            choices.forEach((choice) => {
              if (choice.checked) {
                values.push(choice.value);
              }
            });
            filter.data = {
              value: values,
            };
            break;
          }
        }
        formatedFilters.push(filter);
      });
    }
    this.filters = formatedFilters;
    return formatedFilters;
  }

  public clearFilter(): void {
    this.dataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.filters = [];
    this.isFiltered = false;
    this.loadQuestionnaires();
    this.updatePreference();
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public correctAndApplyFilter(filter: Filter): void {
    if (filter.propertyName === "questionnaireStatus") {
      filter.propertyName = "status";
    }
    this.dataSource.setFilter(filter);
    this.isFiltered = this.filters && this.filters.length > 0;
    this.loadQuestionnaires();
    this.updatePreference();
  }

  // --------------------------------------------------------------------------
  // --------------------------- ACTIONS --------------------------------------
  // --------------------------------------------------------------------------

  public createQuestionnaire(): void {
    this.router.navigate(["/questionnaireEditor"]);
  }

  public async editQuestionnaire(q: IQuestionnaireListInfo): Promise<void> {
    this.questionnairesService.editQuestionnaire(q.identifier[0].value, q.version, q.hasDraft, q.useContext[0].accessLevel);
  }

  public async deleteQuestionnaire(q: IQuestionnaireListInfo): Promise<void> {
    const questionnaire = await this.getQuestActiveFirstOrDraft(q);
    if (!questionnaire) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while deleting questionnaire: Questionnaire not found. ");
    }
    const response = await this.questionnairesService.deleteQuestionnaire(questionnaire);
    if (response) {
      this.loadQuestionnaires();
    }
  }

  public async copyQuestionnaire(q: IQuestionnaireListInfo): Promise<void> {
    const questionnaire = await this.getQuestActiveFirstOrDraft(q);
    questionnaire.status = QuestionnaireStatus.DRAFT;
    questionnaire.date = moment().format();
    questionnaire.publisher = "";
    questionnaire.identifier = undefined;
    questionnaire.useContext = [
      {
        valueReference: "all",
        accessLevel: AccessLevel.WRITE,
      },
    ];
    const questionnaireScorings = Tools.clone(await this.getQuestionnaireScorings(q));
    questionnaireScorings?.forEach((s) => {
      s.status = QuestionnaireStatus.DRAFT;
      s.questionnairePublicationDate = null;
      s.scoringId = undefined;
      s.targets = undefined;
    });
    this.router.navigate(["questionnaireEditor"], {
      state: { questionnaire, action: "copy", scorings: questionnaireScorings, userHighestAccessLevel: AccessLevel.WRITE },
    });
  }

  /**
   * Export questionnaire
   */
  public async download(q: IQuestionnaireListInfo): Promise<void> {
    const questionnaire = await this.getQuestActiveFirstOrDraft(q);
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(questionnaire)));
    element.setAttribute("download", questionnaire.subjectType + ".json");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public onImportQuestionnaireFile(event: File[]): void {
    const file: File = event[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        try {
          const questionnaireStr = fileReader.result as string;
          const questionnaire = JSON.parse(questionnaireStr) as IQuestionnaire;
          questionnaire.status = QuestionnaireStatus.DRAFT;
          questionnaire.date = moment().format();
          questionnaire.publisher = "";
          questionnaire.identifier = undefined;
          questionnaire.useContext = [
            {
              valueReference: "all",
              accessLevel: AccessLevel.WRITE,
            },
          ];
        } catch (err) {
          FileLogger.error("FhirQuestionnaireListPageComponent", "Error while importing questionnaire: ", err);
          this.translateService.get("page.fhirQuestionnaireList.importQuestionnaireError").subscribe((trans) => {
            this.snackBar.open(trans, "ok", { duration: 10000 });
          });
        }
      };
      fileReader.readAsText(file);
    }
  }

  /**
   * Get a questionnaire from its questionnaire list infos. It will try to get the latest active version if
   * it exists, else, it will return the draft.
   * @param q the questionnaire infos
   * @returns
   */
  private async getQuestActiveFirstOrDraft(q: IQuestionnaireListInfo): Promise<IQuestionnaire> {
    try {
      const questionnaire = await this.questionnairesService.getQuestionnaire(
        q.identifier[0].value,
        q.version,
        q.status !== QuestionnaireStatus.ACTIVE, // meaning, there's only a draft, no active version
        q.status === QuestionnaireStatus.ACTIVE
      );
      return questionnaire;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while getQuestActiveFirstOrDraft: ", err);
      return null;
    }
  }

  private async getQuestionnaireVersion(q: IQuestionnaireListInfo): Promise<IQuestionnaire> {
    try {
      const questionnaire = await this.questionnairesService.getQuestionnaire(q.identifier[0].value, q.version, false, false, q.date);
      return questionnaire;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while get questionnaireVersion: ", err);
      return null;
    }
  }

  public async showQuestionnaireVersion(q: IQuestionnaireListInfo, isDraft = false): Promise<void> {
    const questionnaire = isDraft
      ? await this.questionnairesService.getQuestDraftFirstOrActive(q.identifier[0].value, q.version)
      : await this.getQuestionnaireVersion(q);
    const scorings = isDraft
      ? await this.questionnairesService.getQuestionnaireScoringsDraft(q.identifier[0].value)
      : await this.getQuestionnaireScoringsVersion(q);
    if (questionnaire) {
      this.router.navigate(["questionnaireEditor"], {
        state: {
          questionnaire,
          visualization: !isDraft || q.useContext[0].accessLevel < AccessLevel.WRITE,
          scorings: scorings,
          userHighestAccessLevel: q.useContext[0].accessLevel,
        },
      });
    } else {
      this.translateService.get("page.fhirQuestionnaireList.showQuestionnaireVersionError").subscribe((trans) => {
        this.snackBar.open(trans, "ok", { duration: 10000 });
      });
    }
  }

  public async showHistory(identifier: string, lang: string): Promise<void> {
    const history: IQuestionnaireHistoryEvent[] = await this.questionnairesService.getQuestionnaireHistory(identifier, lang);
    this.dialog.open(QuestionnaireHistoryComponent, {
      data: { history, identifier, lang },
    });
  }

  private async getQuestionnaireScorings(q: IQuestionnaireListInfo): Promise<IQuestionnaireScoring[]> {
    try {
      const scorings = await this.questionnairesService.getQuestionnaireScoringsFromApi(q.identifier[0].value);
      return scorings;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while get questionnaireScorings: ", err);
      return null;
    }
  }

  private async getQuestionnaireScoringsVersion(q: IQuestionnaireListInfo): Promise<IQuestionnaireScoring[]> {
    try {
      const scorings = await this.questionnairesService.getQuestionnaireScoringsFromApi(q.identifier[0].value, false, q.date);
      return scorings;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while get questionnaireScoringsVersion: ", err);
      return null;
    }
  }

  private updatePreference() {
    this.preferenceService
      .update({
        context: this.route.snapshot.data.ctx,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: PreferenceUser) => {
        const param = parameters.preferences.find((p) => p.context === this.route.snapshot.data.ctx);
        this.preferences = param.parameters;
      });
  }
}
