import { FileLogger } from "../helpers/fileLogger";
import { Address } from "./address.interface";
import { Codes } from "./codes.interface";
import { Contact } from "./contact.interface";
import { Entity } from "./entity.interface";
import { Identifier } from "./identifier.interface";
import { IOrganization } from "./organization.interface";
import { Reference } from "./reference.interface";
import { IMonitoringOrganizationLink } from "./sharedInterfaces";

export enum OrganisationType {
  PROV = "prov",
  INS = "ins",
  OLDINS = "oldins",
  BUS = "bus",
  CG = "cg",
  CRS = "crs",
}

export const MonitoringCodes = [OrganisationType.BUS, OrganisationType.CG, OrganisationType.CRS];
export class Organization extends Entity implements IOrganization {
  public resourceType: string;
  public identifier: Identifier[];
  public active: boolean;
  public type: Codes;
  public name: string;
  public telecom?: Identifier[];
  public address?: Address[];
  public contact?: Contact[];
  public linkedMonitoringOrganizations?: IMonitoringOrganizationLink[];
  public asReference: Reference;
  public organizationName: string;
  public organizationReference: string | undefined;
  public organizationType: OrganisationType | undefined;
  public defaultAccessGroups?: string[];

  constructor(data: IOrganization) {
    super();
    this.resourceType = data.resourceType;
    this.identifier = data.identifier;
    this.active = data.active;
    this.type = data.type;
    this.name = data.name;
    this.telecom = data.telecom;
    this.address = data.address;
    this.contact = data.contact;
    this.linkedMonitoringOrganizations = data.linkedMonitoringOrganizations;
    this.organizationName = this.identifier?.[0]?.label || this.name;
    this.organizationReference = this.identifier?.[0]?.value;
    this.organizationType = this.type?.coding?.[0]?.code as OrganisationType;
    this.asReference = {
      reference: this.organizationReference,
      display: this.organizationName,
    };
    this.defaultAccessGroups = data.defaultAccessGroups;
  }

  public isMonitoring(): boolean {
    if (!this.type || !this.type.coding || this.type.coding.length === 0) {
      FileLogger.warn("Organization", "Found an organization without a type: ", this?.identifier);
      return false;
    }
    const orgType = this.type.coding[0].code as OrganisationType;
    if (MonitoringCodes.includes(orgType)) {
      return true;
    }
    return false;
  }

  /**
   * Check the monitoring link of the organization. If no reference is provided in the parameters, it simply
   * returns whether or not there's is a monitoring organization linked to it.
   * If a reference is provided, it will check if this organization is linked to this particular monitoring
   * organization (or service).
   * @param orgRef        (optional) the monitoring organization we want to check if this organization is linked to
   * @param serviceRef    (optional) the monitoring service we want to check if this organization is linked to
   * @returns             whether or not this organization is linked to a monitoring organization and/or service
   */
  public isLinkedToMonitoring(orgRef?: string, serviceRef?: string): boolean {
    if (!this.linkedMonitoringOrganizations || this.linkedMonitoringOrganizations.length === 0) {
      return false;
    }
    if (!orgRef && !serviceRef) {
      return true;
    }
    let orgLinkId = -1;
    if (orgRef) {
      orgLinkId = this.linkedMonitoringOrganizations.findIndex((link) => link.organizationRef.reference === orgRef);
      if (orgLinkId < 0) {
        return false;
      }
    }
    if (serviceRef) {
      let serviceLinkId = -1;
      if (orgLinkId >= 0) {
        serviceLinkId = this.linkedMonitoringOrganizations[orgLinkId].servicesRef.findIndex((s) => s.reference === serviceRef);
      } else {
        serviceLinkId = this.linkedMonitoringOrganizations.findIndex(
          (link) => link.servicesRef.findIndex((s) => s.reference === serviceRef) >= 0
        );
      }
      if (serviceLinkId < 0) {
        return false;
      }
    }
    return true;
  }
}
