<ng-container *ngIf="config?.statsObsGroups?.length || config?.statsAlertsGroups?.length">
  <h3 class="mt-1">{{ obsDefinition.nameTranslation["fr"] }}</h3>
  <div class="grid">
    <!-- Observation Statistics -->
    <app-observation-stats
      *ngFor="let statsGroups of config?.statsObsGroups"
      [ngStyle]="statsGroups.position | positionStyle"
      [obsDefinition]="obsDefinition"
      [statsGroups]="statsGroups"
      [type]="'observation'"
      [fromDate]="fromDate"
      [toDate]="toDate"
    ></app-observation-stats>
    <!-- Alert Statistics -->
    <app-observation-stats
      *ngFor="let statsGroups of config?.statsAlertsGroups"
      [ngStyle]="statsGroups.position | positionStyle"
      [obsDefinition]="obsDefinition"
      [statsGroups]="statsGroups"
      [type]="'alert'"
      [fromDate]="fromDate"
      [toDate]="toDate"
    ></app-observation-stats>
  </div>
</ng-container>
