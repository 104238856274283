<span #focus></span>
<mat-card class="widget" [ngClass]="{ big: isBig }">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "drugs.title" | translate }}</p>
    <button mat-icon-button aria-label="icon button with a ? icon" (click)="openDrugHelp()">
      <mat-icon>help_outline</mat-icon>
    </button>
    <span class="spacerBetween"></span>
    <span class="mr-5 font-12" *ngIf="drugs?.length > 0">
      {{ (activeOnlyPref ? "mydrugs.toggleOn" : "mydrugs.toggleOff") | translate }}
    </span>
    <mat-slide-toggle
      class="mr-5 font-12"
      *ngIf="drugs?.length > 0"
      (change)="onActiveOnlyChange()"
      [(ngModel)]="activeOnlyPref"
      labelPosition="left"
    >
      {{ drugs?.length > 0 && activeOnlyPref && getInactiveNbr() ? "( " + getInactiveNbr() + " )" : "" }}
    </mat-slide-toggle>
    <button
      mat-stroked-button
      *ngIf="drugs?.length !== 0 && ('dashboard/exportDrugsForPatient' | isAuthorized : 'GET' | async) === true"
      class="mr-5 ml-5"
      (click)="onExportDrugs()"
    >
      {{ "observation.export" | translate }}
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.newDrug' | translate }}"
      aria-label="icon button with a plus icon"
      *ngIf="('entity' | isAuthorized : 'POST' | async) === true"
      (click)="addDrug()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.history' | translate }}"
      *ngIf="historic && historic.length"
      (click)="showHistoric()"
      aria-label="icon button with a historic icon"
    >
      <mat-icon>history</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.calendar' | translate }}"
      *ngIf="!isBig && drugs?.length > 0"
      aria-label="icon button with a calendar icon"
      (click)="onCalendar()"
    >
      <mat-icon>calendar_today</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.details' | translate }}"
      *ngIf="!isBig && drugs?.length > 0"
      (click)="onPlus()"
      aria-label="icon button with a plus icon"
    >
      <mat-icon>open_in_full</mat-icon>
    </button>

    <button
      mat-icon-button
      matTooltip="{{ 'btn.reduce' | translate }}"
      *ngIf="isBig"
      (click)="onPlus()"
      aria-label="icon button with a pencil icon"
    >
      <mat-icon>close_fullscreen</mat-icon>
    </button>
  </mat-toolbar>
  <mat-card-content [ngClass]="{ hide: dataSource?.data?.length === 0 }">
    <table mat-table matSort matSortDirection="asc" matSortActive="name" [dataSource]="dataSource">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.name" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.name' | translate }}"
            propertyName="entityData.name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('entityData.name')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.entityData.name }}
          <mat-icon *ngIf="isHospital(element.entityData)" class="fail custom-icon-small bottom-align"> local_hospital </mat-icon>
          <mat-icon
            *ngIf="element.entityData?.cycle && element.entityData?.cycle?.cycle.length"
            class="custom-icon-small bottom-align"
            matTooltip="{{ 'drugSchema.withCycle' | translate }}"
          >
            timelapse
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.DESCRIPTION"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.DESCRIPTION)"
            [matTooltip]="'knowledgebase.doccategory.' + KNOW_DOC_CATEGORY.DESCRIPTION | translate"
          >
            info
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.SIDEEFFECT"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.SIDEEFFECT)"
            [matTooltip]="'knowledgebase.doccategory.' + KNOW_DOC_CATEGORY.SIDEEFFECT | translate"
          >
            error
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.RECOMMENDATION"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.RECOMMENDATION)"
            [matTooltip]="'knowledgebase.doccategory.' + KNOW_DOC_CATEGORY.RECOMMENDATION | translate"
          >
            bookmark
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugInfoLink : drugsInfo"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="onURL(element.entityData)"
            [matTooltip]="'btn.moreInfo' | translate"
          >
            link
          </mat-icon>
        </td>
      </ng-container>

      <!-- Frequency Column -->
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.frequency" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.entityData | frequencyLabel }}
        </td>
      </ng-container>

      <!-- startDate Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.startDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.startDate' | translate }}"
            propertyName="entityData.frequency.boundsPeriod.start"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('entityData.frequency.boundsPeriod.start')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.entityData.frequency.boundsPeriod.start | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- endDate Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.endDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.endDate' | translate }}"
            propertyName="entityData.frequency.boundsPeriod.end"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('entityData.frequency.boundsPeriod.end')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!isInfinite(element.entityData.frequency.boundsPeriod.end)">
            {{ element.entityData.frequency.boundsPeriod.end | date : "dd/MM/yyyy" }}
          </span>
          <mat-icon *ngIf="isInfinite(element.entityData.frequency.boundsPeriod.end)" class="custom-icon-small"> all_inclusive</mat-icon>
        </td>
      </ng-container>

      <!-- moment Column -->
      <ng-container matColumnDef="moment">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.moment" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ getTiming(element.entityData) }}
        </td>
      </ng-container>

      <!-- precriptor Column -->
      <ng-container matColumnDef="prescriptor">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.prescriptor" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ getPerformerName(element.entityData.performer.reference) }}
        </td>
      </ng-container>

      <!-- gauge Column -->
      <ng-container matColumnDef="ratioIntake">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.ratioIntake" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            class="gauge"
            *ngIf="element | hasIntakesData : intakeDrugs"
            matTooltip="{{ element | intakesToolTip : historic : intakeDrugs }}"
            [ngStyle]="element | intakesGauge : historic : intakeDrugs"
          ></div>
          <p *ngIf="!(element | hasIntakesData : intakeDrugs) && !intakeIsLoading">
            {{ "widget.noData" | translate }}
          </p>
          <p *ngIf="intakeIsLoading">{{ "widget.loading" | translate }}...</p>
        </td>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.update' | translate }}"
            *ngIf="('entity' | isAuthorized : 'POST' | async) === true"
            (click)="updateDrug(element)"
            class="warn custom-icon-w"
            aria-label="icon button with a pencil icon"
          >
            <mat-icon class="custom-icon-w baseline-align">create</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.removeDrug' | translate }}"
            *ngIf="('entity' | isAuthorized : 'POST' | async) === true"
            (click)="deleteDrug(element)"
            class="fail custom-icon-w"
            aria-label="icon button with a trash icon"
          >
            <mat-icon class="custom-icon-w baseline-align">delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.history' | translate }}"
            *ngIf="historic && historic.length"
            (click)="showHistoricLocal(element)"
            class="primary custom-icon-w"
            aria-label="icon button with a historic icon"
          >
            <mat-icon class="custom-icon-w baseline-align">history</mat-icon>
          </button>
          <button
            class="disabledButton"
            mat-icon-button
            *ngIf="element.entityData?.cycle && element.entityData?.cycle?.cycle.length"
            [disabled]="isCycleInPause(element.entityData.cycle)"
          >
            <mat-icon
              class="custom-icon-w baseline-align"
              *ngIf="!isCycleInPause(element.entityData.cycle) && ('entity' | isAuthorized : 'POST' | async) === true"
              matTooltip="{{ 'drugSchema.pause' | translate }}"
              (click)="OnPauseCycle(element)"
            >
              pause
            </mat-icon>
            <mat-icon
              class="custom-icon-w baseline-align"
              *ngIf="isCycleInPause(element.entityData.cycle)"
              matTooltip="{{
                ('drugSchema.pauseInfo' | translate) +
                  ' ' +
                  getFormatDate(element.entityData.cycle.pauseDate[element.entityData.cycle.pauseDate.length - 1])
              }}"
            >
              play_arrow
            </mat-icon>
          </button>
          <button
            *ngIf="element | hasIntakesData : intakeDrugs"
            class="custom-icon-w"
            mat-icon-button
            matTooltip="{{ 'btn.drugDetails' | translate }}"
            (click)="showMore(element)"
            aria-label="icon button with a loupe icon"
          >
            <mat-icon class="custom-icon-w baseline-align">search</mat-icon>
          </button>
          <button
            class="custom-icon-w"
            mat-icon-button
            matTooltip="{{ 'drugIntake.addIntake' | translate }}"
            (click)="onAddIntake(element)"
            aria-label="icon button with a add icon"
            *ngIf="('dashboard/drugIntake' | isAuthorized : 'POST' | async) === true"
          >
            <mat-icon class="custom-icon-w baseline-align">add</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [ngClass]="{ hide: drugs?.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [pageSizeOptions]="isBig ? [5, 10, 15, 20] : [5]"
      [hidePageSize]="!isBig"
    >
    </mat-paginator>
  </mat-card-content>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center" *ngIf="dataSource?.data?.length === 0 && !loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
