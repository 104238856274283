import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { Quantities } from "src/app/models/careplans.interface";
import { IDrugInfo } from "src/app/models/drugInfo.interface";
import { KeyValue } from "src/app/models/keyValues.model";
import { ITiming } from "src/app/models/sharedInterfaces";
import { FREQUENCY_OPTIONS, TIMING_OPTIONS, TimingData } from "src/app/models/timingData.model";

@Pipe({ name: "isAdditionalOptionVisible" })
export class IsAdditionalOptionVisiblePipe implements PipeTransform {
  transform(i: number, quantitiesValues: string[], availableQuantities: KeyValue[]): boolean {
    if (quantitiesValues[i] === "" || quantitiesValues[i] === null) {
      return false;
    }
    // if the value entered by the user already exist, move it to the top of the list
    const index = availableQuantities.findIndex((q) => q.value === quantitiesValues[i]);
    if (index !== -1) {
      const elem = availableQuantities[index];
      availableQuantities.splice(index, 1);
      availableQuantities.unshift(elem);
    }
    return index === -1;
  }
}

@Component({
  selector: "app-timing-editor",
  templateUrl: "./timing-editor.component.html",
  styleUrls: ["./timing-editor.component.scss"],
})
export class TimingEditorComponent implements OnInit, OnDestroy {
  @Input() mode: FORMS_MODE;
  @Input() authorizedToEdit = true;
  @Input() isMomentRequired: boolean;
  @Input() largeDisplay: boolean;
  @Input()
  get hasCycle(): boolean {
    return this.cycleActive;
  }
  set hasCycle(has: boolean) {
    this.cycleActive = has;
    if (this.isInit) {
      this.hasSchemaChange();
    }
  }
  @Input()
  get hasStepwise(): boolean {
    return this.stepwiseActive;
  }
  set hasStepwise(has: boolean) {
    this.stepwiseActive = has;
    if (this.isInit) {
      this.hasSchemaChange();
    }
  }
  @Input() needStartEnd = true;
  @Input() needQuantities = true;
  @Input() isFrequencyDisplayed: boolean;
  @Input() careplanStartDate?: string;
  @Input() careplanEndDate?: string;
  @Input() isQuestionnaire?: boolean;
  @Input() context?: string;
  @Input()
  get frequency(): ITiming {
    return this.freq;
  }
  set frequency(freq: ITiming) {
    this.freq = freq;
    if (this.isInit) {
      this.setup();
    }
  }
  @Input() set disabled(d: boolean) {
    this.isDisabled = d;
    if (d) {
      setTimeout(() => {
        this.freqForm?.disable();
      });
    } else {
      setTimeout(() => {
        this.freqForm?.enable();
      });
    }
  }
  @Output() frequencyChange: EventEmitter<ITiming> = new EventEmitter<ITiming>();

  private freq: ITiming;
  private cycleActive = false;
  private stepwiseActive = false;
  public isDisabled = false;
  public availableEveryType: KeyValue[] = this.formsData.EVERY_TYPES;
  public availableTimings: KeyValue[] = this.formsData.TIMING;
  public availableQuantities: KeyValue[] = this.formsData.QUANTITTY;
  public availableWeekDays: KeyValue[] = this.formsData.DAYS;
  public availableMonths: KeyValue[] = this.formsData.MONTHS;
  public freqOptions: KeyValue[] = FREQUENCY_OPTIONS;
  public timingOptions: KeyValue[] = TIMING_OPTIONS;
  public availableMonthDays: number[] = [...Array(31).keys()].map((v) => v + 1);
  public noEndDate = true;
  public noEndHour = false;
  public hasValidate = false;
  public freqForm: UntypedFormGroup;
  public isInputValidate = false;
  public type: KeyValue;
  private isInit = false;
  private weekDays: KeyValue[];
  private monthDays: number[];
  private months: number[];
  private hourTo: string;
  private hourFrom: string;
  private defaultTimingOption: string;
  private timingFormArray: UntypedFormArray;
  private quantitiesFormArray: UntypedFormArray;
  public fixedDatesFormArray: UntypedFormArray;
  public timeOfDayFormArray: UntypedFormArray;
  private frequencyTypeSub$: Subscription;
  private timingOptionSub$: Subscription;
  private onDestroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, private formsData: FormsData, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.availableMonths = this.formsData.MONTHS;
    });
    if (this.isQuestionnaire) {
      this.availableEveryType.push(
        new KeyValue({
          key: this.translateService.instant("periodUnit.year.singPlural"),
          value: "y",
        })
      );
      this.freqOptions.splice(1, 2);
    }
    this.setup();
    if (this.context === "activityFrequency") {
      this.freqForm
        .get("everyValue")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.save();
        });
      this.freqForm
        .get("everyType")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.save();
        });
    }
    this.isInit = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setup() {
    this.cleanForm();
    this.initFrequency();
  }

  /**
   * Setup de form according to the values of the frequency
   */
  private initFrequency() {
    if (this.mode === FORMS_MODE.CREATE && !this.freq) {
      this.freq = {
        boundsPeriod: {
          start: this.needStartEnd ? moment().format() : null,
          end: this.needStartEnd ? moment().add(1, "months").format() : null,
        },
        count: null,
        endless: false,
        frequency: 1,
        period: 1,
        periodUnits: "d",
        when: null, // this should absolutely be null by default (not undefined) or everything crashes
        timingCode: "", // M=morning, N=noon, E=evening
      };
    }
    this.type = this.availableEveryType.find((kv) => kv.value === this.freq.periodUnits);
    const checkBox = this.availableTimings.map((x) => {
      const isChecked = this.freq.timingCode ? this.freq.timingCode.includes(x.value) : false;
      return this.fb.control(isChecked);
    });
    const chosenWeekDays =
      this.type?.value === "w" && this.freq.when
        ? (JSON.parse(this.freq.when) as unknown[]).map((w) => Number(w)) // in this case, we want number
        : undefined;
    this.weekDays = chosenWeekDays ? this.availableWeekDays.filter((v) => chosenWeekDays.includes(v.value)) : [];

    this.hourFrom = this.type?.value === "h" && this.freq.when ? String(JSON.parse(this.freq.when)?.[0]) : undefined;
    this.hourTo = this.type?.value === "h" && this.freq.when ? String(JSON.parse(this.freq.when)?.[1]) : undefined;
    this.noEndHour = this.hourFrom !== undefined && this.hourTo !== undefined;

    this.monthDays =
      this.type?.value === "m" && this.freq.when ? (JSON.parse(this.freq.when) as unknown[]).map((w) => Number(w)) : undefined;
    this.months = this.type?.value === "y" && this.freq.when ? (JSON.parse(this.freq.when) as unknown[]).map((w) => Number(w)) : undefined;

    this.timingFormArray = this.fb.array(checkBox, this.atLeastOneCheckboxCheckedValidator());
    const fixedDates =
      this.freq.event && this.freq.event.length > 0
        ? this.freq.event.map((e) => moment(e.toString()).format())
        : [moment(this.freq.boundsPeriod?.start).format()];
    this.fixedDatesFormArray = this.fb.array(fixedDates, this.atLeastOneValidator());
    this.fixedDatesFormArray.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((dates) => {
      this.adaptStartEndWithFixedDates(dates);
    });
    const fixedHours = this.freq.timeOfDay && this.freq.timeOfDay.length > 0 ? this.freq.timeOfDay : [moment().format("HH:mm")];
    this.timeOfDayFormArray = this.fb.array(fixedHours, this.atLeastOneValidator());
    if (this.needQuantities) {
      const quantities: Quantities = {
        rise: this.freq.quantities?.rise ? this.freq.quantities.rise : null,
        morning: this.freq.quantities?.morning ? this.freq.quantities.morning : null,
        noon: this.freq.quantities?.noon ? this.freq.quantities.noon : null,
        evening: this.freq.quantities?.evening ? this.freq.quantities.evening : null,
        bedtime: this.freq.quantities?.bedtime ? this.freq.quantities.bedtime : null,
      };
      const quantitiesArray = [];
      Object.keys(quantities).forEach((prop) => {
        quantitiesArray.push(quantities[prop]);
      });
      this.quantitiesFormArray = this.fb.array(quantitiesArray);
    }
    if (!this.isMomentRequired) {
      this.timingFormArray = this.fb.array(checkBox);
    }
    const asNecessary = this.freq.asNecessary;
    const freqOption = TimingData.getFreqOption(this.freq);
    this.defaultTimingOption = TimingData.getTimingOption(this.freq);
    // those field are the only one that will all
    this.freqForm = this.fb.group({
      freqOptions: ["", Validators.required],
    });

    if (this.needStartEnd) {
      const fromDate = this.freq.boundsPeriod.start;
      const toDate = this.freq.boundsPeriod.end;
      this.noEndDate = this.freq.endless ? true : toDate ? toDate.startsWith("9999") : true;
      this.freqForm.addControl(
        "fromDate",
        new UntypedFormControl(
          {
            value: moment(fromDate),
            disabled: !this.authorizedToEdit,
          },
          Validators.required
        )
      );
      this.freqForm.addControl(
        "toDate",
        new UntypedFormControl(
          {
            value: moment(toDate),
            disabled: !this.authorizedToEdit,
          },
          Validators.required
        )
      );
    }
    this.setupFrequencyOptionsChange();
    this.handlenoEndDate();
    this.freqForm.get("freqOptions").patchValue(freqOption);
    if (!asNecessary) {
      this.initTimingOption();
    }
    if (this.isDisabled) {
      this.freqForm.disable();
    }
  }

  private setupFrequencyType(type?: KeyValue) {
    this.removeConditionalsControl();

    if (!this.freqForm.contains("everyType")) {
      this.initTimingOption();
      return;
    }
    this.type = type ? type : (this.freqForm.get("everyType").value as KeyValue);
    let control: UntypedFormControl;
    switch (this.type?.value) {
      case "h":
        if (this.isMomentRequired) {
          control = new UntypedFormControl(this.hourFrom, Validators.required);
        } else {
          control = new UntypedFormControl(this.hourFrom);
        }
        this.freqForm.addControl("hourFrom", control);
        if (this.freqForm.contains("timing")) {
          this.freqForm.removeControl("timing");
        }
        this.handleNoEndHour();
        break;
      case "d":
        this.initTimingOption();
        break;
      case "w":
        if (this.isMomentRequired) {
          control = new UntypedFormControl(this.weekDays, Validators.required);
        } else {
          control = new UntypedFormControl(this.weekDays);
        }
        this.freqForm.addControl("weekDays", control);
        this.initTimingOption();
        break;
      case "m":
        if (this.isMomentRequired) {
          control = new UntypedFormControl(this.monthDays, Validators.required);
        } else {
          control = new UntypedFormControl(this.monthDays);
        }
        this.freqForm.addControl("monthDays", control);
        this.initTimingOption();
        break;
      case "y":
        if (this.isMomentRequired) {
          control = new UntypedFormControl(this.months, Validators.required);
        } else {
          control = new UntypedFormControl(this.months);
        }
        this.freqForm.addControl("months", control);
        this.initTimingOption();
        break;
    }
  }

  private setupFrequencyOptionsChange() {
    this.freqForm
      .get("freqOptions")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((option) => {
        this.setupFreqOption(option);
      });
  }

  private setupFreqOption(o?: string) {
    const option = o ? o : (this.freqForm.get("freqOptions").value as string);

    if (option === TimingData.fixedDaysOption) {
      this.frequencyTypeSub$?.unsubscribe();
      this.removeConditionalsControl();
      this.freqForm.removeControl("everyValue");
      this.freqForm.removeControl("everyType");
      if (this.needStartEnd) {
        this.noEndDate = false;
        this.handlenoEndDate();
        this.adaptStartEndWithFixedDates();
        this.freqForm.get("fromDate").disable();
        this.freqForm.get("toDate").disable();
      }
      this.freqForm.addControl("fixedDates", this.fixedDatesFormArray);
      this.initTimingOption();
    } else if (option === TimingData.fixedFreqOption) {
      this.freqForm.removeControl("fixedDates");
      const period = this.freq.period;
      const periodControl = new UntypedFormControl(period, Validators.required);
      const typeControl = new UntypedFormControl(this.type, Validators.required);
      this.freqForm.addControl("everyValue", periodControl);
      this.freqForm.addControl("everyType", typeControl);
      this.setupFrequencyType(this.type);
      this.setupFrequencyTypeChange();
      if (this.needStartEnd && this.authorizedToEdit) {
        this.freqForm.get("fromDate").enable();
        this.freqForm.get("toDate")?.enable();
      }
    } else {
      this.removeConditionalsControl();
      this.freqForm.removeControl("fixedDates");
      this.freqForm.removeControl("everyValue");
      this.freqForm.removeControl("everyType");
      if (this.needStartEnd) {
        this.freqForm.get("fromDate").enable();
        this.freqForm.get("toDate")?.enable();
      }
      if (this.freqForm.contains("timingOptions")) {
        this.freqForm.removeControl("timingOptions");
      }
      this.setupTimingOption();
    }
    if (this.freqForm.contains("freqOptions") && !this.authorizedToEdit) {
      this.freqForm.get("freqOptions")?.disable();
      this.freqForm.get("fixedDates")?.disable();
      this.freqForm.get("everyValue")?.disable();
      this.freqForm.get("everyType")?.disable();
      this.freqForm.get("monthDays")?.disable();
      this.freqForm.get("weekDays")?.disable();
      this.freqForm.get("months")?.disable();
      this.freqForm.get("hourTo")?.disable();
      this.freqForm.get("hourFrom")?.disable();
      this.freqForm.get("timingOptions")?.disable();
      this.freqForm.get("timing")?.disable();
      this.freqForm.get("quantities")?.disable();
      this.freqForm.get("timeOfDay")?.disable();
    }
  }

  private adaptStartEndWithFixedDates(d?: string[]) {
    const dates = d ? Tools.clone(d) : Tools.clone(this.fixedDatesFormArray.value.map((d2) => moment(d2.toString()).format()));
    const nbDates = dates.length;
    if (nbDates > 0) {
      dates.sort();
      const start = dates[0];
      const stop = dates[nbDates - 1];
      this.freqForm.get("fromDate").setValue(start);
      this.freqForm.get("toDate").setValue(stop);
    }
  }

  /**
   * Adapt the form according to the value of the type of frequency (every hours, days, weeks,...)
   */
  private setupFrequencyTypeChange() {
    this.frequencyTypeSub$?.unsubscribe();
    this.frequencyTypeSub$ = this.freqForm
      .get("everyType")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((type) => {
        this.setupFrequencyType(type);
      });
  }

  private setupTimingOptionsChange() {
    this.timingOptionSub$?.unsubscribe();
    this.timingOptionSub$ = this.freqForm
      .get("timingOptions")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((option) => {
        this.setupTimingOption(option);
      });
  }

  private initTimingOption(o?: string) {
    if (!this.freqForm.get("timingOptions")) {
      this.freqForm.addControl("timingOptions", new UntypedFormControl("", Validators.required));
      this.setupTimingOptionsChange();
      this.freqForm.get("timingOptions").patchValue(o ? o : this.defaultTimingOption);
    } else {
      this.setupTimingOption();
    }
  }

  private setupTimingOption(o?: string) {
    if (!this.freqForm.get("timingOptions")) {
      if (this.freqForm.get("timing")) {
        this.freqForm.removeControl("timing");
      }
      if (this.freqForm.get("quantities")) {
        this.freqForm.removeControl("quantities");
      }
      if (this.freqForm.get("timeOfDay")) {
        this.freqForm.removeControl("timeOfDay");
      }
      return;
    }
    const option = o ? o : (this.freqForm.get("timingOptions").value as string);
    if (option === TimingData.momentTimingOption) {
      if (this.freqForm.contains("timeOfDay")) {
        this.freqForm.removeControl("timeOfDay");
      }
      if (!this.freqForm.contains("timing")) {
        this.freqForm.addControl("timing", this.timingFormArray);
      }
      if (this.needQuantities && !this.freqForm.contains("quantities")) {
        this.freqForm.addControl("quantities", this.quantitiesFormArray);
      }
    } else {
      this.freqForm.addControl("timeOfDay", this.timeOfDayFormArray);
      if (this.freqForm.contains("timing")) {
        this.freqForm.removeControl("timing");
      }
      if (this.freqForm.contains("quantities")) {
        this.freqForm.removeControl("quantities");
      }
    }
  }

  /**
   * Remove all fields from the forms that depends on a
   * choice from the user
   */
  private removeConditionalsControl() {
    if (this.freqForm.contains("hourTo")) {
      this.freqForm.removeControl("hourTo");
    }
    if (this.freqForm.contains("hourFrom")) {
      this.freqForm.removeControl("hourFrom");
    }
    if (this.freqForm.contains("weekDays")) {
      this.freqForm.removeControl("weekDays");
    }
    if (this.freqForm.contains("monthDays")) {
      this.freqForm.removeControl("monthDays");
    }
    if (this.freqForm.contains("months")) {
      this.freqForm.removeControl("months");
    }
    if (this.freqForm.contains("quantities")) {
      this.freqForm.removeControl("quantities");
    }
  }

  private hasSchemaChange() {
    if (this.hasCycle) {
      if (!this.freqForm.contains("timingOptions")) {
        this.freqForm.addControl("timingOptions", new UntypedFormControl("", Validators.required));
        this.setupTimingOptionsChange();
        this.freqForm.get("timingOptions").patchValue(this.defaultTimingOption);
      }
      if (this.needStartEnd && this.authorizedToEdit) {
        this.freqForm.get("fromDate").enable();
        this.freqForm.get("toDate")?.enable();
      }
    } else if (this.hasStepwise) {
      if (this.freqForm.contains("timingOptions")) {
        this.freqForm.removeControl("timingOptions");
      }
      if (this.freqForm.contains("timing")) {
        this.freqForm.removeControl("timing");
      }
      if (this.freqForm.contains("quantities")) {
        this.freqForm.removeControl("quantities");
      }
      if (this.freqForm.get("timeOfDay")) {
        this.freqForm.removeControl("timeOfDay");
      }
    } else {
      this.setupFreqOption();
    }
  }

  private get isEndDateValid() {
    return (
      this.noEndDate || (this.freqForm.get("toDate").value && this.freqForm.get("toDate").value >= this.freqForm.get("fromDate").value)
    );
  }

  public addFixedDate(): void {
    this.fixedDatesFormArray.push(new UntypedFormControl(moment().format(), Validators.required));
  }

  public removeFixedDate(): void {
    if (this.fixedDatesFormArray.length === 1) {
      return;
    }
    const last = this.fixedDatesFormArray.length - 1;
    this.fixedDatesFormArray.removeAt(last);
  }

  public addFixedHour(): void {
    this.timeOfDayFormArray.push(new UntypedFormControl(moment().format("HH:mm"), Validators.required));
  }

  public removeFixedHour(): void {
    if (this.timeOfDayFormArray.length === 1) {
      return;
    }
    const last = this.timeOfDayFormArray.length - 1;
    this.timeOfDayFormArray.removeAt(last);
  }

  private drugChanged(_newDrug: IDrugInfo) {
    // Do stuff when drug changed
  }

  public save(): boolean {
    this.hasValidate = true;
    const formIsValid = this.freqForm.valid && this.isEndDateValid;
    if (!formIsValid) {
      this.freqForm.markAllAsTouched();
      return false;
    }

    if (!this.hasStepwise) {
      if (this.hasCycle) {
        this.getTimingFromForm();
      } else {
        const freqOption = this.freqForm.get("freqOptions").value as string;
        if (freqOption === TimingData.fixedFreqOption) {
          this.freq.event = [];
          this.freq.asNecessary = false;
          this.getFixedFreqFromForm();
        } else if (freqOption === TimingData.fixedDaysOption) {
          // fixed dates
          this.freq.when = null;
          const dates = this.freqForm.get("fixedDates").value.map((d) => moment(d.toString()).format());
          this.freq.event = dates;
          this.freq.event.sort();
          this.freq.asNecessary = false;
          this.getTimingFromForm();
        } else {
          // as necessary
          this.freq.when = null;
          this.freq.event = [];
          this.freq.asNecessary = true;
        }
      }
    }
    if (this.needStartEnd) {
      this.freq.boundsPeriod.start = moment(this.freqForm.get("fromDate").value).format();
      if (!this.noEndDate) {
        this.freq.boundsPeriod.end = moment(this.freqForm.get("toDate").value).format();
      } else {
        this.freq.boundsPeriod.end = moment("9999-12-31").format();
      }
    }
    this.freq.endless = this.noEndDate;
    this.frequencyChange.emit(this.freq);
    return true;
  }

  private getFixedFreqFromForm() {
    if (this.type?.value !== "h") {
      // this should absolutely be null by default (not undefined) or everything crashes
      this.freq.when = null;
      this.getTimingFromForm();
      if (this.freqForm.contains("monthDays")) {
        const monthDays = this.freqForm.get("monthDays").value as number[];
        if (monthDays) {
          this.freq.when = JSON.stringify(monthDays.map((w) => String(w)));
        }
      } else if (this.freqForm.contains("weekDays")) {
        const weekDays = (this.freqForm.get("weekDays").value as KeyValue[])?.map((v) => v.value);
        if (weekDays) {
          this.freq.when = JSON.stringify(weekDays.map((w) => String(w)));
        }
      } else if (this.freqForm.contains("months")) {
        const months = this.freqForm.get("months").value as number[];
        if (months) {
          this.freq.when = JSON.stringify(months.map((w) => String(w)));
        }
      }
      if (this.needQuantities) {
        this.freq.quantities = this.formatQuantities(this.freqForm.value.quantities);
      }
    } else {
      const hourTo = this.freqForm.get("hourTo").value;
      const hourFrom = this.freqForm.get("hourFrom").value;
      if (hourFrom) {
        this.freq.when = JSON.stringify([String(hourFrom), String(hourTo)]);
      }
    }
    this.freq.period = this.freqForm.get("everyValue").value as number;
    this.freq.periodUnits = (this.freqForm.get("everyType").value as KeyValue)?.value;
  }

  private getTimingFromForm() {
    const timingOption = this.freqForm.get("timingOptions").value as string;
    if (timingOption === TimingData.momentTimingOption) {
      this.freq.timeOfDay = [];
      // Moment of day
      this.freq.timingCode = this.availableTimings
        .filter((x, i) => this.freqForm.value.timing[i])
        .map((kv: KeyValue) => kv.value)
        .join("");
    } else {
      // Fixed hours
      this.freq.timeOfDay = this.freqForm.get("timeOfDay").value as string[];
      this.freq.timeOfDay.sort();
    }
  }

  private cleanForm(): void {
    if (!this.freqForm) {
      return;
    }
    this.freqForm = undefined;
    this.noEndDate = true;
    this.noEndHour = false;
    this.hasValidate = false;
  }

  public toggleEndHour(): void {
    this.noEndHour = !this.noEndHour;
    this.handleNoEndHour();
  }

  private handleNoEndHour() {
    if (this.type?.value !== "h") {
      return;
    }
    if (!this.noEndHour && !this.freqForm.contains("hourTo")) {
      let hourToControl: UntypedFormControl;
      if (this.isMomentRequired) {
        hourToControl = new UntypedFormControl(this.hourTo, Validators.required);
      } else {
        hourToControl = new UntypedFormControl(this.hourTo);
      }
      this.freqForm.addControl("hourTo", hourToControl);
    } else if (this.noEndHour && this.freqForm.contains("hourTo")) {
      this.freqForm.removeControl("hourTo");
    }
  }

  public toggleEndDate(): void {
    if (this.freqForm.get("fromDate").disabled) {
      return;
    }
    this.noEndDate = !this.noEndDate;
    this.handlenoEndDate();
  }

  private handlenoEndDate() {
    if (this.noEndDate) {
      if (this.freqForm.contains("toDate")) {
        this.freqForm.removeControl("toDate");
      }
    } else {
      if (!this.freqForm.contains("toDate")) {
        const toDateControl = new UntypedFormControl(moment(this.freq.boundsPeriod.end), Validators.required);
        this.freqForm.addControl("toDate", toDateControl);
      }
      // if there is no end date : set start date as default end date
      if (!this.freq.boundsPeriod.end) {
        this.freqForm.get("toDate").setValue(this.freqForm.get("fromDate").value);
      }
    }
  }

  private formatQuantities(quantitiesArray): Quantities {
    if (!this.freqForm.value.timing) {
      return null;
    }
    // if the period of the day is not selected, we don't keep the quantities of this period
    this.freqForm.value.timing.forEach((timing, i) => {
      if (!timing) {
        quantitiesArray[i] = null;
      }
    });
    return {
      rise: quantitiesArray[0],
      morning: quantitiesArray[1],
      noon: quantitiesArray[2],
      evening: quantitiesArray[3],
      bedtime: quantitiesArray[4],
    };
  }

  private atLeastOneCheckboxCheckedValidator(): ValidatorFn {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls.map((control) => control.value).reduce((prev, next) => (next ? prev + next : prev), 0);
      return totalSelected >= 1 ? null : { requiredAtLeastOneChecked: true };
    };
    return validator;
  }

  private atLeastOneValidator(): ValidatorFn {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      if (formArray.controls.length < 1) {
        return { requiredAtLeastOne: true };
      }
      for (const control of formArray.controls) {
        if (!Tools.isDefined(control.value) || control.value === "") {
          return { requiredAllValid: true };
        }
      }
      return null;
    };
    return validator;
  }
}
